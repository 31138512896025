import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// components
import AppCard from 'src/components/AppCard'
import AppDetails from 'src/components/details/schedule-details/AppDetails'

// helpers
import Axios from 'src/helpers/axios'

const Schedules = () => {
  const navigate = useNavigate()

  const [schedules, setSchedules] = useState('')

  useEffect(() => {
    fetchAllSchedules()
  }, []);

  const fetchAllSchedules = () => {
    Axios({
      method: "get",
      url: `/api/booking/v1/bookings/all`,
    })
      .then(({ data }) => {
        setSchedules(data)
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <AppCard
      className='mt-4 mb-4'
      headerTitle={<h4>Schedules</h4>}
      bodyContent={
        <AppDetails
          schedules={schedules}
        />
      }
    />
  )
}

export default Schedules
