import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFormik } from "formik"
import * as Yup from "yup"

// components
import AppCard from 'src/components/AppCard'
import AppForm from 'src/components/form/site-manager-form/update-our-services/AppForm'
import AppModalFilePreview from 'src/components/modal/AppModalFilePreview'
import ActionButtonFormSecond from 'src/components/form/general-input-form/ActionButtonFormSecond'

// helpers
import Axios from 'src/helpers/axios'

const OurServicesSettings = () => {
  const ourServicesSetting = useSelector((state) => state.ourServicesSetting)
  const [openFilePreview, setOpenFilePreview] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const displayName = ourServicesSetting && ourServicesSetting.settings && ourServicesSetting.settings.filter(item => item?.component_name === 'display-name')[0].content_text
  const ourServicesHeadline = ourServicesSetting && ourServicesSetting.settings && ourServicesSetting.settings.filter(item => item?.component_name === 'headline')[0].content_text
  const ourServicesTitle = ourServicesSetting && ourServicesSetting.settings && ourServicesSetting.settings.filter(item => item?.component_name === 'title')[0].content_text
  const actionButtonText = ourServicesSetting && ourServicesSetting.settings && ourServicesSetting.settings.filter(item => item?.component_name === 'action-button-text')[0].content_text
  const detailsButtonText = ourServicesSetting && ourServicesSetting.settings && ourServicesSetting.settings.filter(item => item?.component_name === 'details-button-text')[0].content_text
  const detailsTabTitle1 = ourServicesSetting && ourServicesSetting.settings && ourServicesSetting.settings.filter(item => item?.component_name === 'details-tab-title-1')[0].content_text
  const detailsTabTitle2 = ourServicesSetting && ourServicesSetting.settings && ourServicesSetting.settings.filter(item => item?.component_name === 'details-tab-title-2')[0].content_text
  const detailsImageBanner = ourServicesSetting && ourServicesSetting.settings && ourServicesSetting.settings.filter(item => item?.component_name === 'details-banner-image')[0].content_file_url

  const formik = useFormik({
    initialValues: {
      our_services_display_name: displayName,
      our_services_headline: ourServicesHeadline,
      our_services_title: ourServicesTitle,
      action_button_text: actionButtonText,
      details_button_text: detailsButtonText,
      details_tab_title_1: detailsTabTitle1,
      details_tab_title_2: detailsTabTitle2,
      details_banner_image: detailsImageBanner && detailsImageBanner !== '' ? [detailsImageBanner] : [],
    },
    validationSchema: Yup.object().shape({
      our_services_display_name: Yup.string().required("Please enter display name."),
      our_services_headline: Yup.string().required("Please enter headline."),
      our_services_title: Yup.string().required("Please enter title."),
      action_button_text: Yup.string().required("Please enter action button text."),
      details_button_text: Yup.string().required("Please enter details button text."),
      details_tab_title_1: Yup.string().required("Please enter tab title."),
      details_tab_title_2: Yup.string().required("Please enter tab title."),
    }),
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      handleSave(values)
      setSubmitting(false)
    },
  });

  const handleEdit = async (payload) => {
    try {
      const response = await Axios.post(`/api/booking/v1/site-manager/update/8`, payload) // section_id for Our Services is 5
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const handleSave = async (values) => {
    const formData1 = new FormData()
    const formData2 = new FormData()
    const formData3 = new FormData()
    const formData4 = new FormData()
    const formData5 = new FormData()
    const formData6 = new FormData()
    const formData7 = new FormData()
    const formData8 = new FormData()
    setIsLoading(true)

    await Promise.all([
      (async () => {
        if (values.our_services_display_name !== '') {
          formData1.append('component_name', 'display-name')
          formData1.append('content_text', values.our_services_display_name)
          await handleEdit(formData1)
        }
      })(),
      (async () => {
        if (values.our_services_headline !== '') {
          formData2.append('component_name', 'headline')
          formData2.append('content_text', values.our_services_headline)
          await handleEdit(formData2)
        }
      })(),
      (async () => {
        if (values.our_services_title !== '') {
          formData3.append('component_name', 'title')
          formData3.append('content_text', values.our_services_title)
          await handleEdit(formData3)
        }
      })(),
      (async () => {
        if (values.action_button_text !== '') {
          formData4.append('component_name', 'action-button-text')
          formData4.append('content_text', values.action_button_text)
          await handleEdit(formData4)
        }
      })(),
      (async () => {
        if (values.details_button_text !== '') {
          formData5.append('component_name', 'details-button-text')
          formData5.append('content_text', values.details_button_text)
          await handleEdit(formData5)
        }
      })(),
      (async () => {
        if (values.details_tab_title_1 !== '') {
          formData6.append('component_name', 'details-tab-title-1')
          formData6.append('content_text', values.details_tab_title_1)
          await handleEdit(formData6)
        }
      })(),
      (async () => {
        if (values.details_tab_title_2 !== '') {
          formData7.append('component_name', 'details-tab-title-2')
          formData7.append('content_text', values.details_tab_title_2)
          await handleEdit(formData7)
        }
      })(),
      (async () => {
        if (values.details_banner_image.length > 0 && values.details_banner_image[0] instanceof File) {
          formData8.append('component_name', 'details-banner-image')
          formData8.append('file', values.details_banner_image[0])
          await handleEdit(formData8)
        }
      })(),
    ]);

    setIsLoading(false)
    window.location.reload()
  }

  // handle image for details image banner
  const handleRemoveDetailsBannerImage = (file) => {
    formik.setValues((values) => ({
      ...values,
      details_banner_image: values.details_banner_image.filter(image => image !== file)
    }), false)
  }

  const handleDetailsBannerImagesOnDrop = (files) => {
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        details_banner_image: attachments
      }), false)
    }
  }

  const handleDetailsBannerImageAttachment = (e) => {
    e.preventDefault()
    const files = e.target.files
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        details_banner_image: attachments
      }), false)
    }
  }

  return (
    <>
      {openFilePreview && (
        <AppModalFilePreview
          setOpenFilePreview={setOpenFilePreview}
          file={openFilePreview}
        />
      )}
      <AppCard
        className='mb-2'
        headerTitle={<h4>{displayName && displayName !== '' ? displayName : "Our Services"} Settings</h4>}
        bodyContent={
          <AppForm
            values={formik.values}
            handleChange={formik.handleChange}
            errors={formik.errors}
            setOpenFilePreview={setOpenFilePreview}
            handleRemoveDetailsBannerImage={handleRemoveDetailsBannerImage}
            handleDetailsBannerImagesOnDrop={handleDetailsBannerImagesOnDrop}
            handleDetailsBannerImageAttachment={handleDetailsBannerImageAttachment}
          />
        }
        footerContent={
          <ActionButtonFormSecond
            isLoading={isLoading}
            hiddenButtonCancel
            onSave={formik.handleSubmit}
            saveButtonText='Save and Publish'
          />
        }
      />
    </>
  )
}

export default OurServicesSettings

