import React from 'react'
import moment from 'moment'

// components
import {
  CAvatar,
  CButton,
  CContainer,
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormInput,
  CHeader,
  CHeaderNav,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane
} from '@coreui/react-pro'
import AppChatRoom from './AppChatRoom'

// icons
import CIcon from '@coreui/icons-react'
import {
  cilChatBubble,
  cilGroup,
  cilOptions,
  cilUser
} from '@coreui/icons'

const AppChatDetails = ({
  user,
  activeChatRoom,
  activeChatRoomId,
  sortedChatRooms,
  newMessage,
  endOfMessageRef,
  handleChange,
  handleOnKeyDown,
  handleSubmit,
  handleEndChat,
  handleOpenRoom,
  handleFilter,
  handleSelectFilter,
  activeTabKey,
  setActiveTabKey,
  showFilterModal,
  setShowFilterModal,
  showModal,
  setShowModal,
  filters,
  activeFilter
}) => {
  return (
    <div className='chat_main_container d-flex'>
      <div className='chat_sidebar bg-white border-end'>
        {/* <div className='mb-4 px-4 d-flex justify-content-end'>
          <CButton onClick={() => setShowFilterModal(true)}>Filter</CButton>
        </div> */}
        <CNav variant="tabs" role="tablist">
          <CNavItem>
            <CNavLink
              href="#"
              active={activeTabKey === 1}
              onClick={() => setActiveTabKey(1)}
            >
              Active
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink
              href="#"
              active={activeTabKey === 2}
              onClick={() => setActiveTabKey(2)}
            >
              History
            </CNavLink>
          </CNavItem>
        </CNav>
        <CTabContent className='flex-grow-1 overflow-auto'>
          <CTabPane role="tabpanel" aria-labelledby="home-tab" visible={activeTabKey === 1}>
            {sortedChatRooms?.length ? (
              <AppChatRoom
                sortedChatRooms={sortedChatRooms}
                currentUser={user}
                activeChatRoomId={activeChatRoomId}
                handleOpenRoom={handleOpenRoom}
              />
            ) : (
              <div className='flex-grow-1 overflow-auto d-flex align-items-center justify-content-center py-4'>
                <h5>No Active Messages</h5>
              </div>
            )}
          </CTabPane>
          <CTabPane role="tabpanel" aria-labelledby="profile-tab" visible={activeTabKey === 2}>
            {sortedChatRooms?.length ? (
              <AppChatRoom
                sortedChatRooms={sortedChatRooms}
                currentUser={user}
                activeChatRoomId={activeChatRoomId}
                handleOpenRoom={handleOpenRoom}
              />
            ) : (
              <div className='flex-grow-1 overflow-auto d-flex align-items-center justify-content-center py-4'>
                <h5>No Messages</h5>
              </div>
            )}
          </CTabPane>
        </CTabContent>
      </div>

      {activeChatRoom ? (
        <div className='flex-grow-1 d-flex flex-column'>
          <CHeader>
            <div className='d-flex align-items-center gap-2 px-1'>
              <CAvatar color="primary" textColor="white" size='md'>
                <CIcon icon={cilGroup} />
              </CAvatar>
              <span className='fw-bold chat_room_header_text'>{activeChatRoom?.name}</span>
            </div>
            <CHeaderNav className="">
              <CDropdown variant='nav-item'>
                <CDropdownToggle caret={false}>
                  <CIcon icon={cilOptions} size="lg" />
                </CDropdownToggle>
                <CDropdownMenu>
                  {/* <CDropdownItem href="#">Profile</CDropdownItem> */}
                  {/* <CDropdownDivider /> */}
                  {/* <CDropdownItem href="#">Block</CDropdownItem> */}
                  <CDropdownItem href="#" className='text-danger' onClick={() => setShowModal(true)}>End Chat</CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            </CHeaderNav>
          </CHeader>

          <div className='d-flex flex-column flex-grow-1 border-left gap-3 px-4 py-4 overflow-auto'>
            {activeChatRoom?.ChatMessages?.map((message, i) => {
              if (message?.sender_id?.toString() === user?.id?.toString()) {
                return (
                  <div key={i} className='d-flex gap-2 flex-row-reverse'>
                    <CAvatar size="lg" color="primary" textColor="white">
                      <CIcon icon={cilUser} size="lg" />
                    </CAvatar>
                    <div className='d-flex flex-column gap-1 justify-content-start'>
                      <div className='d-flex flex-column align-items-end'>
                        <span className='fw-bold'>
                          {message.User?.name}
                        </span>
                      </div>
                      <div className='d-flex flex-row-reverse align-items-end gap-2'>
                        <div className='bg-primary text-white rounded-4 px-3 py-2 text-end'>
                          {message?.text}
                        </div>
                        <span className='fw-light'>
                          {moment(message?.createdAt).format("HH:mm")}
                        </span>
                      </div>
                    </div>
                  </div>
                )
              } else {
                return (
                  <div className='d-flex gap-2 flex-row'>
                    <CAvatar size="lg" color={message.User?.role_id === 3 ? "info" : "primary"} textColor="white">
                      <CIcon icon={cilUser} size="lg" />
                    </CAvatar>
                    <div className='d-flex flex-column gap-1 justify-content-start'>
                      <div className='d-flex flex-column'>
                        <span className='fw-bold'>
                          {message.User?.name}
                        </span>
                      </div>
                      <div className='d-flex flex-row align-items-end gap-2'>
                        <div className='bg-secondary text-white rounded-4 px-3 py-2 text-end'>
                          {message?.text}
                        </div>
                        <span className='fw-light'>
                          {moment(message?.createdAt).format("HH:mm")}
                        </span>
                      </div>
                    </div>
                  </div>
                )
              }
            })}
            <div ref={endOfMessageRef} />
          </div>
          <div className='d-flex gap-2 px-4 py-4'>
            <CFormInput
              value={newMessage}
              type="text"
              size="md"
              placeholder="Message"
              aria-label="message input"
              onChange={handleChange}
              onKeyDown={handleOnKeyDown}
              disabled={activeTabKey === 2}
            />
            <CButton onClick={handleSubmit} disabled={activeTabKey === 2}>
              Send
            </CButton>
          </div>
        </div>
      ) : (
        <div className='flex-grow-1 d-flex flex-column align-items-center justify-content-center gap-4'>
          <CIcon icon={cilChatBubble} size="3xl" />
          <h3>No message selected</h3>
        </div>
      )}
      <CModal visible={showModal} onClose={() => setShowModal(false)}>
        <CModalHeader onClose={() => setShowModal(false)}>
          <CModalTitle>End Chat</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p>Are you sure you want to end this chat?</p>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </CButton>
          <CButton color="danger" onClick={handleEndChat}>Sure</CButton>
        </CModalFooter>
      </CModal>

      {/* <CModal visible={showFilterModal} onClose={() => setShowFilterModal(false)}>
        <CModalHeader onClose={() => setShowFilterModal(false)}>
          <CModalTitle>Filter</CModalTitle>
        </CModalHeader>
        <CModalBody>
          {filters?.map((filter, i) => (
            <div className='d-flex flex-column gap-2 mb-4'>
              <h5 className='fw-bold text-primary'>{filter.label}</h5>
              <div className='d-flex flex-wrap gap-2'>
                {filter.items.map((item, i) => (
                  <CButton
                    name={item.name}
                    color="primary"
                    variant={activeFilter?.[filter?.type] === item?.name ? "" : "outline"}
                    onClick={(e) => handleSelectFilter(e, filter.type)}
                  >
                    {item.label}
                  </CButton>
                ))}
              </div>
            </div>
          ))}
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setShowFilterModal(false)}>
            Cancel
          </CButton>
          <CButton color="info" onClick={handleFilter}>Sure</CButton>
        </CModalFooter>
      </CModal> */}
    </div>
  )
}

export default AppChatDetails
