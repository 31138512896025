
import React, { useMemo } from 'react'
import { useDropzone } from 'react-dropzone'

// components
import {
  CCol,
  CFormLabel,
  CFormInput,
  CImage,
  CAlert,
  CButton,
  CFormText
} from '@coreui/react-pro'

// icons
import InformationIcon from '../../../assets/icons/InformationIcon.png'
import UploadImageIcon from '../../../assets/icons/UploadImageIcon.png'

// styling
import { styleButtonVars4 } from 'src/components/custom-styles/buttonStyles'
import {
  baseStyle,
  focusedStyle,
  acceptStyle,
  textUploadDropZoneStyle1,
  textUploadDropZoneStyle2
} from 'src/components/custom-styles/containerStyle'

const FormImageUpload = ({
  formProductImages,
  formMainSiteLogo,
  formMainTabIcon,
  colSize,
  inputLabel,
  className,
  name,
  value,
  handleFileOnDrop,
  handleFileInput,
  feedbackInvalid,
  invalid,
  multiple,
  errors
}) => {
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept
  } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': []
    },
    maxFiles: 12,
    maxSize: 1000000,
    multiple: true,
    onDrop: files => handleFileOnDrop(files)
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {})
  }), [
    isFocused,
    isDragAccept
  ]);

  return (
    <CCol lg={colSize} className={className}>
      <div className='d-grid gap-2 d-md-flex align-items-center'>
        <CFormLabel >
          {inputLabel}
        </CFormLabel>

      </div>
      <div>
        <p
          style={{
            color: '#9E9E9E',
            fontSize: '16px',
            marginBottom: '.5rem'
          }}
        >
          {
            formProductImages ? 'You can upload up to 12 images'
              : formMainSiteLogo ? 'Main site logo will be displayed on navigation bar'
                : formMainTabIcon ? 'Favicon will be displayed on menu tab when user access site through browser.'
                  : ''
          }
        </p>
      </div>
      <div {...getRootProps({ style })}>
        <CImage fluid src={UploadImageIcon} className="mt-5" />
        <p className="my-3" style={textUploadDropZoneStyle2}>Drag &amp; Drop Files Here</p>
        <p className="my-3" style={textUploadDropZoneStyle1}><i>or</i></p>
        <CFormInput
          id="upload_product_images"
          className="position-absolute invisible"
          name={name}
          type="file"
          value={value}
          onChange={(e) => handleFileInput(e)}
          feedbackInvalid={feedbackInvalid}
          invalid={invalid}
          multiple={multiple}
          accept='.jpeg, .png'
          {...getInputProps()}
        />
        <label className="my-3" htmlFor="upload_product_images">
          <CButton style={styleButtonVars4}>Choose File(s)</CButton>
        </label>
        {formProductImages && errors.product_images && (
          <CFormText className='error-text-field'>
            {errors.product_images}
          </CFormText>
        )}
        <p className="my-3" style={textUploadDropZoneStyle1}>Maximum file size 1MB per image</p>
        <p style={textUploadDropZoneStyle1}>Supported file type: .png with transparent background</p>
      </div>
      <CAlert color="light" variant="solid" className="text-high-emphasis">
        <CImage fluid src={InformationIcon} className='me-2' />
        Recommended file size:
        {
          formMainSiteLogo ? ' 360px x 70px'
            : formMainTabIcon ? ' 16px x 16px'
              : ' 800px x 200px'
        }
      </CAlert>
    </CCol>
  )
}

export default FormImageUpload
