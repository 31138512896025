import React, { useEffect, useState } from 'react'
import { useFormik } from "formik"
import * as Yup from "yup"

// components
import AppCard from 'src/components/AppCard'
import AppForm from 'src/components/form/site-manager-form/update-general-settings/update-terms-conditions/AppForm'
import AppModalFilePreview from 'src/components/modal/AppModalFilePreview'
import ActionButtonFormSecond from 'src/components/form/general-input-form/ActionButtonFormSecond'

// helpers
import Axios from 'src/helpers/axios'

const TermsConditions = ({
  termsConditionsSettings
}) => {
  const [openFilePreview, setOpenFilePreview] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const descriptionTermsConditions = termsConditionsSettings && termsConditionsSettings[0] && termsConditionsSettings[0].settings.filter(item => item?.component_name === 'description')[0].content_text

  const formik = useFormik({
    initialValues: {
      store_terms_conditions: descriptionTermsConditions,
    },
    validationSchema: Yup.object().shape({
      store_terms_conditions: Yup.string().required("Please enter terms and conditions."),
    }),
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      handleSave(values)
      setSubmitting(false)
    },
  });

  const handleEdit = async (payload) => {
    try {
      const response = await Axios.post(`/api/booking/v1/site-manager/update/10`, payload) // section_id for Terms and Conditions is 10
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const handleSave = async (values) => {
    const formData1 = new FormData()
    setIsLoading(true)

    await Promise.all([
      (async () => {
        if (values.store_terms_conditions !== '') {
          formData1.append('component_name', 'description')
          formData1.append('content_text', values.store_terms_conditions)
          await handleEdit(formData1)
        }
      })(),
    ]);

    setIsLoading(false)
    window.location.reload()
  }

  return (
    <>
      {openFilePreview && (
        <AppModalFilePreview
          setOpenFilePreview={setOpenFilePreview}
          file={openFilePreview}
        />
      )}
      <AppCard
        className='mb-2'
        // headerTitle={<h4>Main Site</h4>}
        bodyContent={
          <AppForm
            values={formik.values}
            handleChange={formik.handleChange}
            errors={formik.errors}
            setOpenFilePreview={setOpenFilePreview}
          />
        }
        footerContent={
          <ActionButtonFormSecond
            isLoading={isLoading}
            hiddenButtonCancel
            onSave={formik.handleSubmit}
            saveButtonText='Save and Publish'
          />
        }
      />
    </>
  )
}

export default TermsConditions

