export function formUrlQuery(obj) {
  let queryString = '';
  for (let key in obj) {
    if (obj[key].toString() && obj[key].toString() != '') {
      if (queryString.length > 0) {
        queryString += '&';
      }
      queryString += `${key}=${obj[key]}`;
    }
  }
  return queryString;
}