
import React from 'react'

// components
import {
  CCol,
  CFormLabel,
  CFormSelect
} from '@coreui/react-pro'

const FormSelectInput = ({
  id,
  name,
  colSize,
  className,
  inputLabel,
  selectOptions,
  value,
  handleSelectInput,
  feedbackInvalid,
  invalid,
  disabled
}) => {
  return (
    <CCol lg={colSize} className={className}>
      <CFormLabel>
        {inputLabel}
      </CFormLabel>
      <CFormSelect
        id={id}
        name={name}
        multiple={false}
        options={selectOptions}
        onChange={(e) => handleSelectInput(e)}
        value={value}
        feedbackInvalid={feedbackInvalid}
        invalid={invalid}
        disabled={disabled}
      />
    </CCol>
  )
}

export default FormSelectInput
