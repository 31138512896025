import React from 'react'

// components
import { CButton, CSpinner } from '@coreui/react-pro'

// styling
import { styleButtonVars4 } from 'src/components/custom-styles/buttonStyles'

function ActionButtonFormFirst({
  isDraft,
  isLoadingDraft,
  isLoadingPublish,
  disabled,
  onCancel,
  onDraft,
  onPublish
}) {
  return (
    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
      <CButton onClick={onCancel} style={styleButtonVars4}>
        Cancel
      </CButton>
      {/* {isDraft && (
        <CButton onClick={onDraft} style={styleButtonVars4}>
          {isLoadingDraft && (
            <CSpinner className='me-2' component="span" color="light" size="sm" aria-hidden="true" />
          )}
          Save As Draft
        </CButton>
      )} */}
      <CButton disabled={disabled} onClick={onPublish} style={styleButtonVars4}>
        {isLoadingPublish && (
          <CSpinner className='me-2' component="span" color="light" size="sm" aria-hidden="true" />
        )}
        Publish
      </CButton>
    </div>
  )
}

export default ActionButtonFormFirst
