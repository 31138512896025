import moment from 'moment'

export const arrayChartAxisData = (array, property) => {
  let newArray = [];

  array.forEach(element => {
    if (
      property === 'transaction_date' ||
      property === 'createdAt' ||
      property === 'updatedAt'
    ) {
      newArray.push(moment(element[property]).format('DD/MM/YYYY'));
    } else {
      newArray.push(element[property]);
    }
  });

  return newArray;
}

export const getMostPurchaseServiceId = (array) => {
  let bookArray = [];

  array.forEach(element => {
    bookArray.push(element);
  });

  const newBookArray = bookArray.flat(1)

  let arrayServicePackageId = []

  newBookArray.forEach(element => {
    arrayServicePackageId.push(element['service_package_id']);
  });

  // get the  highest occurence
  if (arrayServicePackageId.length == 0) return null;
  let modeMap = {};
  let maxEl = arrayServicePackageId[0], maxCount = 1;
  for (let i = 0; i < arrayServicePackageId.length; i++) {
    let el = arrayServicePackageId[i];
    if (modeMap[el] == null)
      modeMap[el] = 1;
    else
      modeMap[el]++;
    if (modeMap[el] > maxCount) {
      maxEl = el;
      maxCount = modeMap[el];
    }
  }

  return maxEl;
}

export const removeDuplicatesMultiSelect = (array) => {
  const newArray = array.filter((value, index, self) =>
    index === self.findIndex((t) => (
      t.value === value.value && t.text === value.text
    ))
  )
  return newArray
}

export const removeDuplicatesOptionsSelect = (array) => {
  const newArray = array.filter((value, index, self) =>
    index === self.findIndex((t) => (
      t.value === value.value && t.text === value.text
    ))
  )
  return newArray
}

export const arrayFullCalendarData = (data) => {
  let options = []
  if (data && data.length > 0) {
    data.forEach((item) => {
      const id = item && item.id
      const title = item && item.package && item.package.service && item.package.service.title
      const time = item && item.booking_time && item.booking_time.date
      const guest = item && item.guests && item.guests.length > 0 && item.guests[0]
      const guestTotal = item && item.guests && item.guests.length
      options.push({
        id: id.toString(),
        title,
        start: moment(time).format(),
        // start: new Date(time).toISOString(),
        guest: guest ? `${guest.salutation} ${guest.first_name} ${guest.last_name}` : '',
        total_guest: guestTotal
      })
    })
  }
  return options
}

export const arrayScheduleTimeSlotList = (timeSlot) => {
  let morningServiceDateList = []
  let afternoonServiceDateList = []
  let eveningServiceDateList = []

  const morningSchedule = timeSlot && timeSlot?.length > 0 && timeSlot[0].morning
  const afternoonSchedule = timeSlot && timeSlot?.length > 0 && timeSlot[0].afternoon
  const eveningSchedule = timeSlot && timeSlot?.length > 0 && timeSlot[0].evening
  morningSchedule?.forEach(schedule => {
    if (morningServiceDateList.length > 0) {
      const foundScheduleDate = morningServiceDateList.some((list) =>
        moment(list.service_date).format('l') === moment(schedule.date).format('l') &&
        list.service_preferred_time === schedule.preferred_time
      );
      if (!foundScheduleDate) {
        morningServiceDateList.push({
          service_schedule_id: schedule.id,
          is_from_fetch: true,
          service_date: new Date(schedule.date),
          service_preferred_time: schedule.preferred_time,
          service_as_unavailable: !schedule.is_available,
          service_preferred_hours: [{
            label: moment(schedule.date).format('HH:mm'),
            value: moment(schedule.date).format('HH:mm'),
            selected: true,
            hour_has_booked: schedule.has_bookings,
            quota: schedule.quota
          }]
        })
      } else {
        morningServiceDateList.forEach((list) => {
          if (
            moment(list.service_date).format('l') === moment(schedule.date).format('l')
            && list.service_preferred_time === schedule.preferred_time
          ) {
            list.service_preferred_hours.push({
              label: moment(schedule.date).format('HH:mm'),
              value: moment(schedule.date).format('HH:mm'),
              selected: true,
              hour_has_booked: !schedule.is_available,
              quota: schedule.quota
            })
          }
        })
      }
    } else {
      morningServiceDateList.push({
        service_schedule_id: schedule.id,
        is_from_fetch: true,
        service_date: new Date(schedule.date),
        service_preferred_time: schedule.preferred_time,
        service_as_unavailable: !schedule.is_available,
        service_preferred_hours: [{
          label: moment(schedule.date).format('HH:mm'),
          value: moment(schedule.date).format('HH:mm'),
          selected: true,
          hour_has_booked: !schedule.is_available,
          quota: schedule.quota
        }]
      })
    }
  });

  afternoonSchedule?.forEach(schedule => {
    if (afternoonServiceDateList.length > 0) {
      const foundScheduleDate = afternoonServiceDateList.some((list) =>
        moment(list.service_date).format('l') === moment(schedule.date).format('l') &&
        list.service_preferred_time === schedule.preferred_time
      );
      if (!foundScheduleDate) {
        afternoonServiceDateList.push({
          service_schedule_id: schedule.id,
          is_from_fetch: true,
          service_date: new Date(schedule.date),
          service_preferred_time: schedule.preferred_time,
          service_as_unavailable: !schedule.is_available,
          service_preferred_hours: [{
            label: moment(schedule.date).format('HH:mm'),
            value: moment(schedule.date).format('HH:mm'),
            selected: true,
            hour_has_booked: !schedule.is_available,
            quota: schedule.quota
          }]
        })
      } else {
        afternoonServiceDateList.forEach((list) => {
          if (
            moment(list.service_date).format('l') === moment(schedule.date).format('l')
            && list.service_preferred_time === schedule.preferred_time
          ) {
            list.service_preferred_hours.push({
              label: moment(schedule.date).format('HH:mm'),
              value: moment(schedule.date).format('HH:mm'),
              selected: true,
              hour_has_booked: !schedule.is_available,
              quota: schedule.quota
            })
          }
        })
      }
    } else {
      afternoonServiceDateList.push({
        service_schedule_id: schedule.id,
        is_from_fetch: true,
        service_date: new Date(schedule.date),
        service_preferred_time: schedule.preferred_time,
        service_as_unavailable: !schedule.is_available,
        service_preferred_hours: [{
          label: moment(schedule.date).format('HH:mm'),
          value: moment(schedule.date).format('HH:mm'),
          selected: true,
          hour_has_booked: !schedule.is_available,
          quota: schedule.quota
        }]
      })
    }
  });

  eveningSchedule?.forEach(schedule => {
    if (eveningServiceDateList.length > 0) {
      const foundScheduleDate = eveningServiceDateList.some((list) =>
        moment(list.service_date).format('l') === moment(schedule.date).format('l') &&
        list.service_preferred_time === schedule.preferred_time
      );
      if (!foundScheduleDate) {
        eveningServiceDateList.push({
          service_schedule_id: schedule.id,
          is_from_fetch: true,
          service_date: new Date(schedule.date),
          service_preferred_time: schedule.preferred_time,
          service_as_unavailable: !schedule.is_available,
          service_preferred_hours: [{
            label: moment(schedule.date).format('HH:mm'),
            value: moment(schedule.date).format('HH:mm'),
            selected: true,
            hour_has_booked: !schedule.is_available,
            quota: schedule.quota
          }]
        })
      } else {
        eveningServiceDateList.forEach((list) => {
          if (
            moment(list.service_date).format('l') === moment(schedule.date).format('l')
            && list.service_preferred_time === schedule.preferred_time
          ) {
            list.service_preferred_hours.push({
              label: moment(schedule.date).format('HH:mm'),
              value: moment(schedule.date).format('HH:mm'),
              selected: true,
              hour_has_booked: !schedule.is_available,
              quota: schedule.quota
            })
          }
        })
      }
    } else {
      eveningServiceDateList.push({
        service_schedule_id: schedule.id,
        is_from_fetch: true,
        service_date: new Date(schedule.date),
        service_preferred_time: schedule.preferred_time,
        service_as_unavailable: !schedule.is_available,
        service_preferred_hours: [{
          label: moment(schedule.date).format('HH:mm'),
          value: moment(schedule.date).format('HH:mm'),
          selected: true,
          hour_has_booked: !schedule.is_available,
          quota: schedule.quota
        }]
      })
    }
  });

  const finalServiceDateList = [
    ...morningServiceDateList,
    ...afternoonServiceDateList,
    ...eveningServiceDateList
  ]

  return finalServiceDateList
}

export const formatHourQuota = ({ values, selectedServicePackage, serviceId, selectedHourValue }) => {
  const serviceVariants = values && values.service_variants && values.service_variants[0]
  const variantOptions = serviceVariants && serviceVariants.options
  const variantOptionDate = variantOptions && variantOptions.filter((item) => item.option_name == selectedServicePackage)
  const selectedPackage = variantOptionDate && variantOptionDate[0]
  const packageOptionDates = selectedPackage && selectedPackage.option_service_date_list
  const selectedOptionDate = packageOptionDates && packageOptionDates.filter((item) => item.service_schedule_id == serviceId)
  const selectedDate = selectedOptionDate && selectedOptionDate[0]
  const preferredHours = selectedDate && selectedDate.service_preferred_hours
  const preferredHour = preferredHours && preferredHours.filter((item) => item.value == selectedHourValue)
  const selectedHour = preferredHour && preferredHour[0]
  const quota = selectedHour && selectedHour.quota && parseInt(selectedHour.quota)
  return quota
}

export const sortArrObj = (array, key) => {
  function compare(a, b) {
    if (a[key] < b[key]){
      return -1
    }
    if (a[key] > b[key]){
      return 1
    }
    return 0
  }
  return array.sort(compare)
}