import React, { useState } from 'react'
import moment from 'moment'

// components
import {
  CRow,
  CCol,
  CListGroup,
  CListGroupItem,
  CFormLabel,
  CFormText,
} from '@coreui/react-pro'
import AppTextColumnDetails from '../general-details/AppTextColumnDetails'
import AppImageDetail from '../general-details/AppImageDetails'

const AppDetails = ({
  categoryDetails
}) => {

  return (
    <>
      <CRow>
        <CCol>
          <AppTextColumnDetails
            className="mb-3"
            colSize="12"
            title="Category"
            subtitle={categoryDetails?.name}
          />
          <AppTextColumnDetails
            className="mb-3"
            colSize="12"
            title="Description"
            subtitle={categoryDetails?.description}
          />
          <AppTextColumnDetails
            className="mb-3"
            colSize="12"
            title="Under This Category"
            subtitle={`${categoryDetails?.services?.length} Services`}
          />
          {categoryDetails?.services?.length > 0 && (
            <CListGroup>
              {categoryDetails?.services?.map((list, index) => {
                return (
                  <CListGroupItem key={index}>
                    {list?.title}
                  </CListGroupItem>
                )
              })}
            </CListGroup>
          )}
        </CCol>
        <CCol>
          <AppTextColumnDetails
            className="mb-3"
            colSize="12"
            title="Created On"
            subtitle={moment(categoryDetails?.createdAt).format('DD/MM/YYYY HH:mm')}
          />
        </CCol>
      </CRow>
    </>
  )
}

export default AppDetails
