
import React from 'react'

// components
import {
  CCol,
  CFormLabel,
  CFormText
} from '@coreui/react-pro'

const AppTextColumnDetails = ({
  colSize,
  className,
  title,
  subtitle,
}) => {
  return (
    <CCol lg={colSize} className={className}>
      <CFormLabel>
        {title}
      </CFormLabel>
      <CFormText>
        {subtitle}
      </CFormText>
    </CCol>
  )
}

export default AppTextColumnDetails