import React, { useState } from 'react'

// components
import {
  CRow,
} from '@coreui/react-pro'
import FormTextInput from '../../../general-input-form/FormTextInput';

const AppForm = ({
  values,
  errors,
  handleChange,
}) => {

  return (
    <>
      <CRow className="mb-3">
        <FormTextInput
          id='newsletter_headline'
          name='newsletter_headline'
          colSize='4'
          inputLabel='Headline'
          placeholder='Enter newsletter headline...'
          value={values.newsletter_headline}
          handleTextInput={handleChange}
          feedbackInvalid={errors.newsletter_headline}
          invalid={errors.newsletter_headline}
        />
        <FormTextInput
          id='newsletter_input_placeholder'
          name='newsletter_input_placeholder'
          colSize='4'
          inputLabel='Input Placeholder Text'
          placeholder='Enter input placeholder text...'
          value={values.newsletter_input_placeholder}
          handleTextInput={handleChange}
          feedbackInvalid={errors.newsletter_input_placeholder}
          invalid={errors.newsletter_input_placeholder}
        />
        <FormTextInput
          id='newsletter_action_button_text'
          name='newsletter_action_button_text'
          colSize='4'
          inputLabel='Action Button Text'
          placeholder='Enter action button text...'
          value={values.newsletter_action_button_text}
          handleTextInput={handleChange}
          feedbackInvalid={errors.newsletter_action_button_text}
          invalid={errors.newsletter_action_button_text}
        />
      </CRow>
    </>
  )
}

export default AppForm
