function getServerURL() {
  const localhost = 'http://localhost:3124'
  const staging = 'https://booking-api.cloudxier.dev'
  const production = 'https://booking-api.cloudxier.com'

  let host = window.location.host;
  if (host) {
    host = host.toLowerCase();
  }

  let env
  switch (host) {
    case 'booking-adm-stg.cloudxier.dev':
      env = staging
      break;
    case 'https://booking-adm-stg.cloudxier.dev':
      env = staging
      break;
    case 'booking-adm.cloudxier.com':
      env = production
      break;
    case 'https://booking-adm.cloudxier.com':
      env = production
      break;
    default:
      env = localhost
  }
  return env
};

export default getServerURL
