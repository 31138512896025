import React, { useState } from 'react'

// components
import {
  CRow,
  CCol,
  CAccordion,
  CAccordionHeader,
  CAccordionBody,
  CAccordionItem,
} from '@coreui/react-pro'
import FormTextInput from 'src/components/form/general-input-form/FormTextInput'
import FormTextAreaInput from 'src/components/form/general-input-form/FormTextAreaInput'
import FormImageThumbnail from 'src/components/form/general-input-form/FormImageThumbnail';
import FormImageUpload from 'src/components/form/general-input-form/FormImageUpload';

const AppForm = ({
  values,
  errors,
  handleChange,
  handleRemoveMainBannerImage,
  handleMainBannerImagesOnDrop,
  handleMainBannerImageAttachment,
  handleRemoveHighlight1Image,
  handleHighlight1ImagesOnDrop,
  handleHighlight1ImageAttachment,
  handleRemoveHighlight2Image,
  handleHighlight2ImagesOnDrop,
  handleHighlight2ImageAttachment,
  setOpenFilePreview
}) => {

  return (
    <>
      <CRow className="mb-3">
        <FormTextInput
          id='display_name'
          name='display_name'
          className="mb-3"
          colSize='12'
          inputLabel='Display Name'
          placeholder='Enter display name...'
          value={values.display_name}
          handleTextInput={handleChange}
          feedbackInvalid={errors.display_name}
          invalid={errors.display_name}
        />
        {values.home_main_banner.length > 0 ? (
          <FormImageThumbnail
            editPage
            name='home_main_banner'
            inputLabel='Main Banner Image'
            colSize='12'
            classNameRow="ms-2 d-flex justify-content-center align-item-center"
            productImages={values.home_main_banner}
            handleChangeImage={handleMainBannerImageAttachment}
            handleRemoveImage={handleRemoveMainBannerImage}
            setOpenFilePreview={setOpenFilePreview}
          />
        ) : (
          <FormImageUpload
            name='home_main_banner'
            colSize='12'
            inputLabel='Main Banner Image'
            handleFileInput={handleMainBannerImageAttachment}
            handleFileOnDrop={handleMainBannerImagesOnDrop}
            multiple={false}
          />
        )}
      </CRow>
      <CRow className="mb-3">
        <CAccordion>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              <h5>Highlight 1</h5>
            </CAccordionHeader>
            <CAccordionBody>
              <CRow>
                <FormTextInput
                  id='highlight_title_1'
                  name='highlight_title_1'
                  className="mb-3"
                  colSize='6'
                  inputLabel='Title'
                  placeholder='Enter highlight title here...'
                  value={values.highlight_title_1}
                  handleTextInput={handleChange}
                  feedbackInvalid={errors.highlight_title_1}
                  invalid={errors.highlight_title_1}
                />
                <FormTextInput
                  id='highlight_link_1'
                  name='highlight_link_1'
                  className="mb-3"
                  colSize='6'
                  inputLabel='Link'
                  placeholder='Enter highlight link here...'
                  value={values.highlight_link_1}
                  handleTextInput={handleChange}
                  feedbackInvalid={errors.highlight_link_1}
                  invalid={errors.highlight_link_1}
                />
              </CRow>
              <CRow>
                <FormTextAreaInput
                  id='highlight_description_1'
                  name='highlight_description_1'
                  className="mb-3"
                  colSize='6'
                  inputLabel='Description'
                  placeholder='Enter highlight description here...'
                  rows='10'
                  value={values.highlight_description_1}
                  handleTextAreaInput={handleChange}
                  feedbackInvalid={errors.highlight_description_1}
                  invalid={errors.highlight_description_1}
                />
                {values.highlight_banner_1.length > 0 ? (
                  <FormImageThumbnail
                    editPage
                    name='highlight_banner_1'
                    inputLabel='Banner Image'
                    colSize='6'
                    classNameRow="ms-2"
                    productImages={values.highlight_banner_1}
                    handleChangeImage={handleHighlight1ImageAttachment}
                    handleRemoveImage={handleRemoveHighlight1Image}
                    setOpenFilePreview={setOpenFilePreview}
                  />
                ) : (
                  <FormImageUpload
                    name='highlight_banner_1'
                    colSize='6'
                    inputLabel='Banner Image'
                    handleFileInput={handleHighlight1ImageAttachment}
                    handleFileOnDrop={handleHighlight1ImagesOnDrop}
                    multiple={false}
                  />
                )}
              </CRow>
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>
              <h5>Highlight 2</h5>
            </CAccordionHeader>
            <CAccordionBody>
              <CRow>
                <FormTextInput
                  id='highlight_title_2'
                  name='highlight_title_2'
                  className="mb-3"
                  colSize='6'
                  inputLabel='Title'
                  placeholder='Enter highlight title here...'
                  value={values.highlight_title_2}
                  handleTextInput={handleChange}
                  feedbackInvalid={errors.highlight_title_2}
                  invalid={errors.highlight_title_2}
                />
                <FormTextInput
                  id='highlight_link_2'
                  name='highlight_link_2'
                  className="mb-3"
                  colSize='6'
                  inputLabel='Link'
                  placeholder='Enter highlight link here...'
                  value={values.highlight_link_2}
                  handleTextInput={handleChange}
                  feedbackInvalid={errors.highlight_link_2}
                  invalid={errors.highlight_link_2}
                />
              </CRow>
              <CRow>
                <FormTextAreaInput
                  id='highlight_description_2'
                  name='highlight_description_2'
                  className="mb-3"
                  colSize='6'
                  inputLabel='Description'
                  placeholder='Enter highlight description here...'
                  rows='10'
                  value={values.highlight_description_2}
                  handleTextAreaInput={handleChange}
                  feedbackInvalid={errors.highlight_description_2}
                  invalid={errors.highlight_description_2}
                />
                {values.highlight_banner_2.length > 0 ? (
                  <FormImageThumbnail
                    editPage
                    name='highlight_banner_2'
                    inputLabel='Banner Image'
                    colSize='6'
                    classNameRow="ms-2"
                    productImages={values.highlight_banner_2}
                    handleChangeImage={handleHighlight2ImageAttachment}
                    handleRemoveImage={handleRemoveHighlight2Image}
                    setOpenFilePreview={setOpenFilePreview}
                  />
                ) : (
                  <FormImageUpload
                    name='highlight_banner_2'
                    colSize='6'
                    inputLabel='Banner Image'
                    handleFileInput={handleHighlight2ImageAttachment}
                    handleFileOnDrop={handleHighlight2ImagesOnDrop}
                    multiple={false}
                  />
                )}
              </CRow>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
    </>
  )
}

export default AppForm
