import React, { useState } from 'react'

// components
import {
  CRow
} from '@coreui/react-pro'
import FormTextAreaInput from '../../../general-input-form/FormTextAreaInput'

const AppForm = ({
  values,
  errors,
  handleChange,
}) => {

  return (
    <>
      <CRow className="mb-3">
        <FormTextAreaInput
          id='store_terms_conditions'
          name='store_terms_conditions'
          colSize='12'
          inputLabel='Description'
          // textDescription='Maximum description text is 300 characters'
          placeholder='Enter terms and conditions...'
          rows='10'
          value={values.store_terms_conditions}
          handleTextAreaInput={handleChange}
          feedbackInvalid={errors.store_terms_conditions}
          invalid={errors.store_terms_conditions}
        />
      </CRow>
    </>
  )
}

export default AppForm
