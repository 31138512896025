import React, { useState, useEffect } from 'react'

// components
import AppCard from 'src/components/AppCard.js'
import AppTable from 'src/components/AppTable.js'
import AppCardHeader from 'src/components/card/AppCardHeader'

// helpers
import { columnNewsletterSubscription } from 'src/components/table-column/TableColumnList'
import { formUrlQuery } from 'src/helpers/format-queries'
import Axios from 'src/helpers/axios'

const NewsletterSubscription = () => {

  const [newsletterList, setNewsletterList] = useState([]);
  const [newsletterListCount, setNewsletterListCount] = useState(0);
  const [newsletterListPerPage, setNewsletterListPerPage] = useState(10);
  const [activeNewsletterListPage, setActiveNewsletterListPage] = useState(1);
  const [searchNewsletterListFilter, setSearchNewsletterListFilter] = useState("");
  const [columnNewsletterListSorter, setColumnNewsletterListSorter] = useState({ column: "id", state: "desc" });
  const [selectedItem, setSelectedItem] = useState([])

  useEffect(() => {
    let queries = {
      search: searchNewsletterListFilter,
      // limit: newsletterListPerPage,
      offset: newsletterListPerPage * activeNewsletterListPage - newsletterListPerPage,
      sort:
        columnNewsletterListSorter &&
          columnNewsletterListSorter.column &&
          columnNewsletterListSorter.state &&
          columnNewsletterListSorter.column !== "" &&
          columnNewsletterListSorter.state !== "" &&
          columnNewsletterListSorter.state != 0 ?
          `${columnNewsletterListSorter.column}%${columnNewsletterListSorter.state}` : "id%desc",
    };
    queries = formUrlQuery(queries);
    fetchAllNewsletterSubscription(queries);
  }, [
    searchNewsletterListFilter,
    newsletterListPerPage,
    activeNewsletterListPage,
    columnNewsletterListSorter,
  ]);

  const fetchAllNewsletterSubscription = (queries) => {
    Axios({
      method: "get",
      url: `/api/sso/v1/users/newsletter/user/all`,
    })
      .then(({ data }) => {
        const tempNewsletterList = data.map((d) => ({
          ...d
        }));

        setNewsletterList(tempNewsletterList);
        if (activeNewsletterListPage === 1) {
          setNewsletterListCount(tempNewsletterList?.length);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // column filter for created on
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  return (
    <>
      <AppCard
        className='mt-4 mb-4'
        headerTitle={
          <AppCardHeader
            headerTitle={<h4>Newsletter Subscription</h4>}
          />
        }
        bodyContent={
          <AppTable
            newsletterListPage
            selectable
            tableFilter
            // columnFilter
            tableColumns={
              columnNewsletterSubscription(
                startDate,
                endDate,
                setStartDate,
                setEndDate
              )
            }
            tableContent={newsletterList}
            contentPerPage={newsletterListPerPage}
            activeContentPage={activeNewsletterListPage}
            contentCount={newsletterListCount}
            searchContentFilter={searchNewsletterListFilter}
            setActiveContentPage={setActiveNewsletterListPage}
            setSearchContentFilter={setSearchNewsletterListFilter}
            setContentPerPage={setNewsletterListPerPage}
            setColumnContentSorter={setColumnNewsletterListSorter}
            startDateColumnFilter={startDate}
            endDateColumnFilter={endDate}
            handleSelectedItemChange={setSelectedItem}
          />
        }
      />
    </>
  )
}

export default NewsletterSubscription
