
import React from 'react'
import { useSelector } from "react-redux"

// components
import {
  CRow,
  CCol,
  CFormLabel,
  CFormInput,
  CImage,
  CButton,
  CInputGroup,
  CInputGroupText,
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem
} from '@coreui/react-pro'
import FormImageThumbnail from './FormImageThumbnail'

// icons
import AddMoreIconWhite from '../../../assets/icons/AddMoreIconWhite.png'
import AddMoreIconGrey from '../../../assets/icons/AddMoreIconGrey.png'
import TrashIcon from '../../../assets/icons/TrashIcon.png'

// styling
import { styleButtonVars2 } from 'src/components/custom-styles/buttonStyles'
import { styleAddImageVariant } from 'src/components/custom-styles/containerStyle'

const FormProductVariant = ({
  formProduct,
  editPage,
  detailsPage,
  variantType,
  variantIndex,
  handleAddVariantOptions,
  handleRemoveVariantOptions,
  values,
  errors,
  handleTextVariantOptions,
  handlePriceVariantOptions,
  handleSalePriceVariantOptions,
  handleQuotaVariantOptions,
  setShowConfirmDelete,
}) => {
  const currency = useSelector((state) => state.currency)

  return (
    <>
      <CRow>
        <CCol lg={12} >
          <div className='mb-3 d-grid gap-2 d-md-flex justify-content-between align-items-center'>
            <CFormLabel>
              Variant Options
            </CFormLabel>
            <CButton
              onClick={() => handleAddVariantOptions(variantType, variantIndex)}
              style={styleButtonVars2}
              hidden={detailsPage}
            >
              <CImage fluid src={AddMoreIconWhite} className='me-2' />
              {variantType?.options?.length > 0 ? 'Add More' : 'Add'}
            </CButton>
          </div>
        </CCol>
      </CRow>
      {variantType?.options?.length > 0 && (
        <CAccordion>
          {variantType?.options?.map((option, index) => {
            return (
              <CAccordionItem itemKey={index} key={index} >
                <CAccordionHeader>
                  <b>Variant Option {index + 1}</b>
                </CAccordionHeader>
                <CAccordionBody>
                  <CRow>
                    <CCol lg={12}>
                      <CFormLabel>
                        Variant Option Name
                      </CFormLabel>
                      <CInputGroup className='mb-2'>
                        <CFormInput
                          placeholder='Fill in variant option name...'
                          onChange={({ target: { value } }) => handleTextVariantOptions(value, variantIndex, option, index)}
                          value={option.option_name}
                          feedbackInvalid={errors.option_name}
                          invalid={errors.option_name}
                          disabled={detailsPage}
                        />
                        <CInputGroupText
                          onClick={() => {
                            option.option_id ?
                              setShowConfirmDelete({ show: true, item: option, type: 'service-variant-option' })
                              : handleRemoveVariantOptions(variantIndex, option, index)
                          }}
                          hidden={detailsPage}
                        >
                          <CImage fluid src={TrashIcon} />
                        </CInputGroupText>
                      </CInputGroup>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol lg={4}>
                      <CFormLabel>
                        Unit Price
                      </CFormLabel>
                      <CInputGroup>
                        <CInputGroupText >{currency?.code}</CInputGroupText>
                        <CFormInput
                          placeholder='Fill in variant option unit price...'
                          onChange={({ target: { value } }) => handlePriceVariantOptions(value, variantIndex, option, index)}
                          value={option.option_price}
                          feedbackInvalid={errors.option_price}
                          invalid={errors.option_price}
                          disabled={detailsPage}
                        />
                      </CInputGroup>
                    </CCol>
                    <CCol lg={4}>
                      <CFormLabel>
                        Sale Price
                      </CFormLabel>
                      <CInputGroup>
                        <CInputGroupText >{currency?.code}</CInputGroupText>
                        <CFormInput
                          placeholder='Fill in variant option sale price...'
                          onChange={({ target: { value } }) => handleSalePriceVariantOptions(value, variantIndex, option, index)}
                          value={option.option_sale_price}
                          feedbackInvalid={errors.option_sale_price}
                          invalid={errors.option_sale_price}
                          disabled={detailsPage}
                        />
                      </CInputGroup>
                    </CCol>
                    <CCol lg={4}>
                      <CFormLabel>
                        Quota Limit
                      </CFormLabel>
                      <CFormInput
                        placeholder='Fill in variant option quota limit...'
                        onChange={({ target: { value } }) => handleQuotaVariantOptions(value, variantIndex, option, index)}
                        value={option.option_quota}
                        feedbackInvalid={errors.option_quota}
                        invalid={errors.option_quota}
                        disabled={detailsPage}
                      />
                    </CCol>
                  </CRow>
                </CAccordionBody>
              </CAccordionItem>
            )
          })}
        </CAccordion>
      )}
    </>
  )
}

export default FormProductVariant
