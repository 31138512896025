export const productStatus = (status) => {
  switch (status) {
    case 'active':
      return 'success'
    case 'low in stock':
      return 'warning'
    case 'out of stock':
      return 'danger'
    case 'draft':
      return 'secondary'
    default:
      return 'secondary'
  }
}

export const bookingStatus = (status) => {
  switch (status) {
    case 'processed':
      return 'secondary'
    case 'confirmed':
      return 'success'
    case 'cancelled':
      return 'danger'
    default:
      return 'secondary'
  }
}

export const paymentStatus = (status) => {
  switch (status) {
    case 'pending':
      return 'secondary'
    case 'success':
      return 'success'
    case 'failed':
      return 'danger'
    case 'refunded':
      return 'info'
    default:
      return 'secondary'
  }
}
