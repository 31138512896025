import React, { useEffect, useState } from 'react'
import { useFormik } from "formik"
import * as Yup from "yup"

// components
import AppCard from 'src/components/AppCard'
import AppForm from 'src/components/form/site-manager-form/update-general-settings/update-header/AppForm'
import AppModalFilePreview from 'src/components/modal/AppModalFilePreview'
import ActionButtonFormSecond from 'src/components/form/general-input-form/ActionButtonFormSecond'

// helpers
import Axios from 'src/helpers/axios'

const HeaderSection = ({
  headerSettings
}) => {
  const [openFilePreview, setOpenFilePreview] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const purchaseButtonText = headerSettings && headerSettings[0] && headerSettings[0].settings.filter(item => item?.component_name === 'action-button-text')[0].content_text
  const signInButtonText = headerSettings && headerSettings[0] && headerSettings[0].settings.filter(item => item?.component_name === 'sign-in-button-text')[0].content_text

  const formik = useFormik({
    initialValues: {
      purchase_button_text: purchaseButtonText,
      sign_in_button_text: signInButtonText,
    },
    validationSchema: Yup.object().shape({
      purchase_button_text: Yup.string().required("Please enter action button text."),
      sign_in_button_text: Yup.string().required("Please enter sign-in button text.")
    }),
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      handleSave(values)
      setSubmitting(false)
    },
  });

  const handleEdit = async (payload) => {
    try {
      const response = await Axios.post(`/api/booking/v1/site-manager/update/2`, payload) // section_id for Header is 2
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const handleSave = async (values) => {
    const formData1 = new FormData()
    const formData2 = new FormData()
    setIsLoading(true)

    await Promise.all([
      (async () => {
        if (values.purchase_button_text !== '') {
          formData1.append('component_name', 'action-button-text')
          formData1.append('content_text', values.purchase_button_text)
          await handleEdit(formData1)
        }
      })(),
      (async () => {
        if (values.sign_in_button_text !== '') {
          formData2.append('component_name', 'sign-in-button-text')
          formData2.append('content_text', values.sign_in_button_text)
          await handleEdit(formData2)
        }
      })()
    ]);

    setIsLoading(false)
    window.location.reload()
  }

  return (
    <>
      {openFilePreview && (
        <AppModalFilePreview
          setOpenFilePreview={setOpenFilePreview}
          file={openFilePreview}
        />
      )}
      <AppCard
        className='mb-2'
        // headerTitle={<h4>Main Site</h4>}
        bodyContent={
          <AppForm
            values={formik.values}
            handleChange={formik.handleChange}
            errors={formik.errors}
            setOpenFilePreview={setOpenFilePreview}
          />
        }
        footerContent={
          <ActionButtonFormSecond
            isLoading={isLoading}
            hiddenButtonCancel
            onSave={formik.handleSubmit}
            saveButtonText='Save and Publish'
          />
        }
      />
    </>
  )
}

export default HeaderSection

