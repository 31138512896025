import React, { useState } from 'react'

// components
import AppCard from 'src/components/AppCard'
import AppFirstForm from './AppFirstForm'
import AppSecondForm from './AppSecondForm'
import AppThirdForm from './AppThirdForm'

const AppForm = ({
  editPage,
  detailsPage,
  values,
  errors,
  handleChange,
  categoryOptions,
  setOpenFilePreview,
  setShowConfirmDelete,
  openFormServiceVariant,
  isServiceVariantEmpty,
  setOpenFormServiceVariant,
  setInputVariant,
  inputVariant,
  handleInputVariantType,
  handleAddVariantOptions,
  handleRemoveInputVariantType,
  handleRemoveVariantOptions,
  handleTextVariantOptions,
  handlePriceVariantOptions,
  handleSalePriceVariantOptions,
  handleQuotaVariantOptions,
  handleDatePickerVariantOptions,
  handleResetSavedScheduleVariantOptions,
  handleDeleteScheduleVariantOptions,
  handleIsAllTimeVariantOptions,
  handleScheduleOptionVariantOptions,
  handleScheduleWindowVariantOptions,
  handleChangeQuota
}) => {

  return (
    <>
      <AppFirstForm
        editPage={editPage}
        detailsPage={detailsPage}
        categoryOptions={categoryOptions}
        openFormServiceVariant={openFormServiceVariant}
        setOpenFormServiceVariant={setOpenFormServiceVariant}
        setOpenFilePreview={setOpenFilePreview}
        setShowConfirmDelete={setShowConfirmDelete}
        handleChange={handleChange}
        values={values}
        errors={errors}
      />
      {/* {openFormServiceVariant && ( */}
      <AppSecondForm
        editPage={editPage}
        detailsPage={detailsPage}
        values={values}
        errors={errors}
        handleChange={handleChange}
        isServiceVariantEmpty={isServiceVariantEmpty}
        setOpenFilePreview={setOpenFilePreview}
        setShowConfirmDelete={setShowConfirmDelete}
        setInputVariant={setInputVariant}
        inputVariant={inputVariant}
        handleInputVariantType={handleInputVariantType}
        handleAddVariantOptions={handleAddVariantOptions}
        handleRemoveInputVariantType={handleRemoveInputVariantType}
        handleRemoveVariantOptions={handleRemoveVariantOptions}
        handleTextVariantOptions={handleTextVariantOptions}
        handlePriceVariantOptions={handlePriceVariantOptions}
        handleSalePriceVariantOptions={handleSalePriceVariantOptions}
        handleQuotaVariantOptions={handleQuotaVariantOptions}
      />
      {/* )} */}
      <AppThirdForm
        editPage={editPage}
        detailsPage={detailsPage}
        values={values}
        errors={errors}
        handleChange={handleChange}
        setOpenFilePreview={setOpenFilePreview}
        setShowConfirmDelete={setShowConfirmDelete}
        handleDatePickerVariantOptions={handleDatePickerVariantOptions}
        handleResetSavedScheduleVariantOptions={handleResetSavedScheduleVariantOptions}
        handleDeleteScheduleVariantOptions={handleDeleteScheduleVariantOptions}
        handleIsAllTimeVariantOptions={handleIsAllTimeVariantOptions}
        handleScheduleOptionVariantOptions={handleScheduleOptionVariantOptions}
        handleScheduleWindowVariantOptions={handleScheduleWindowVariantOptions}
        handleChangeQuota={handleChangeQuota}
      />
    </>
  )
}

export default AppForm
