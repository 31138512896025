import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFormik } from "formik"
import * as Yup from "yup"

// components
import AppCard from 'src/components/AppCard'
import AppForm from 'src/components/form/site-manager-form/update-home/AppForm'
import AppModalFilePreview from 'src/components/modal/AppModalFilePreview'
import ActionButtonFormSecond from 'src/components/form/general-input-form/ActionButtonFormSecond'

// helpers
import Axios from 'src/helpers/axios'

const HomeSettings = () => {
  const homeSetting = useSelector((state) => state.homeSetting)
  const [openFilePreview, setOpenFilePreview] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const displayName = homeSetting && homeSetting.settings && homeSetting.settings.filter(item => item?.component_name === 'display-name')[0].content_text
  const homeMainBanner = homeSetting && homeSetting.settings && homeSetting.settings.filter(item => item?.component_name === 'main-banner')[0].content_file_url
  const highlightTitle1 = homeSetting && homeSetting.settings && homeSetting.settings.filter(item => item?.component_name === 'highlight-title-1')[0].content_text
  const highlightDesc1 = homeSetting && homeSetting.settings && homeSetting.settings.filter(item => item?.component_name === 'highlight-description-1')[0].content_text
  const highlightLink1 = homeSetting && homeSetting.settings && homeSetting.settings.filter(item => item?.component_name === 'highlight-link-1')[0].content_text
  const highlightBanner1 = homeSetting && homeSetting.settings && homeSetting.settings.filter(item => item?.component_name === 'highlight-banner-1')[0].content_file_url
  const highlightTitle2 = homeSetting && homeSetting.settings && homeSetting.settings.filter(item => item?.component_name === 'highlight-title-2')[0].content_text
  const highlightDesc2 = homeSetting && homeSetting.settings && homeSetting.settings.filter(item => item?.component_name === 'highlight-description-2')[0].content_text
  const highlightLink2 = homeSetting && homeSetting.settings && homeSetting.settings.filter(item => item?.component_name === 'highlight-link-2')[0].content_text
  const highlightBanner2 = homeSetting && homeSetting.settings && homeSetting.settings.filter(item => item?.component_name === 'highlight-banner-2')[0].content_file_url

  const formik = useFormik({
    initialValues: {
      display_name: displayName,
      home_main_banner: homeMainBanner && homeMainBanner !== '' ? [homeMainBanner] : [],
      highlight_title_1: highlightTitle1,
      highlight_description_1: highlightDesc1,
      highlight_link_1: highlightLink1,
      highlight_banner_1: highlightBanner1 && highlightBanner1 !== '' ? [highlightBanner1] : [],
      highlight_title_2: highlightTitle2,
      highlight_description_2: highlightDesc2,
      highlight_link_2: highlightLink2,
      highlight_banner_2: highlightBanner2 && highlightBanner2 !== '' ? [highlightBanner2] : []
    },
    validationSchema: Yup.object().shape({
      display_name: Yup.string().required("Please enter home display name."),
      highlight_title_1: Yup.string().required("Please enter highlight title."),
      highlight_description_1: Yup.string().required("Please enter highlight description."),
      highlight_title_2: Yup.string().required("Please enter highlight title."),
      highlight_description_2: Yup.string().required("Please enter highlight description."),
    }),
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      handleSave(values)
      setSubmitting(false)
    },
  });

  const handleEdit = async (payload) => {
    try {
      const response = await Axios.post(`/api/booking/v1/site-manager/update/5`, payload) // section_id for Home is 5
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const handleSave = async (values) => {
    const formData1 = new FormData()
    const formData2 = new FormData()
    const formData3 = new FormData()
    const formData4 = new FormData()
    const formData5 = new FormData()
    const formData6 = new FormData()
    const formData7 = new FormData()
    const formData8 = new FormData()
    const formData9 = new FormData()
    const formData10 = new FormData()
    setIsLoading(true)

    await Promise.all([
      (async () => {
        if (values.display_name !== '') {
          formData1.append('component_name', 'display-name')
          formData1.append('content_text', values.display_name)
          await handleEdit(formData1)
        }
      })(),
      (async () => {
        if (values.home_main_banner.length > 0 && values.home_main_banner[0] instanceof File) {
          formData2.append('component_name', 'main-banner')
          formData2.append('file', values.home_main_banner[0])
          await handleEdit(formData2)
        }
      })(),
      (async () => {
        if (values.highlight_title_1 !== '') {
          formData3.append('component_name', 'highlight-title-1')
          formData3.append('content_text', values.highlight_title_1)
          await handleEdit(formData3)
        }
      })(),
      (async () => {
        if (values.highlight_description_1 !== '') {
          formData4.append('component_name', 'highlight-description-1')
          formData4.append('content_text', values.highlight_description_1)
          await handleEdit(formData4)
        }
      })(),
      (async () => {
        if (values.highlight_link_1 !== '') {
          formData5.append('component_name', 'highlight-link-1')
          formData5.append('content_text', values.highlight_link_1)
          await handleEdit(formData5)
        }
      })(),
      (async () => {
        if (values.highlight_banner_1.length > 0 && values.highlight_banner_1[0] instanceof File) {
          formData6.append('component_name', 'highlight-banner-1')
          formData6.append('file', values.highlight_banner_1[0])
          await handleEdit(formData6)
        }
      })(),
      (async () => {
        if (values.highlight_title_2 !== '') {
          formData7.append('component_name', 'highlight-title-2')
          formData7.append('content_text', values.highlight_title_2)
          await handleEdit(formData7)
        }
      })(),
      (async () => {
        if (values.highlight_description_2 !== '') {
          formData8.append('component_name', 'highlight-description-2')
          formData8.append('content_text', values.highlight_description_2)
          await handleEdit(formData8)
        }
      })(),
      (async () => {
        if (values.highlight_link_2 !== '') {
          formData9.append('component_name', 'highlight-link-2')
          formData9.append('content_text', values.highlight_link_2)
          await handleEdit(formData9)
        }
      })(),
      (async () => {
        if (values.highlight_banner_2.length > 0 && values.highlight_banner_2[0] instanceof File) {
          formData10.append('component_name', 'highlight-banner-2')
          formData10.append('file', values.highlight_banner_2[0])
          await handleEdit(formData10)
        }
      })(),
    ]);

    setIsLoading(false)
    window.location.reload()
  }

  // handle image for main banner
  const handleRemoveMainBannerImage = (file) => {
    formik.setValues((values) => ({
      ...values,
      home_main_banner: values.home_main_banner.filter(image => image !== file)
    }), false)
  }

  const handleMainBannerImagesOnDrop = (files) => {
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        home_main_banner: attachments
      }), false)
    }
  }

  const handleMainBannerImageAttachment = (e) => {
    e.preventDefault()
    const files = e.target.files
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        home_main_banner: attachments
      }), false)
    }
  }

  // handle image for highlight 1
  const handleRemoveHighlight1Image = (file) => {
    formik.setValues((values) => ({
      ...values,
      highlight_banner_1: values.highlight_banner_1.filter(image => image !== file)
    }), false)
  }

  const handleHighlight1ImagesOnDrop = (files) => {
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        highlight_banner_1: attachments
      }), false)
    }
  }

  const handleHighlight1ImageAttachment = (e) => {
    e.preventDefault()
    const files = e.target.files
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        highlight_banner_1: attachments
      }), false)
    }
  }

  // handle image for highlight 2
  const handleRemoveHighlight2Image = (file) => {
    formik.setValues((values) => ({
      ...values,
      highlight_banner_2: values.highlight_banner_2.filter(image => image !== file)
    }), false)
  }

  const handleHighlight2ImagesOnDrop = (files) => {
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        highlight_banner_2: attachments
      }), false)
    }
  }

  const handleHighlight2ImageAttachment = (e) => {
    e.preventDefault()
    const files = e.target.files
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        highlight_banner_2: attachments
      }), false)
    }
  }

  return (
    <>
      {openFilePreview && (
        <AppModalFilePreview
          setOpenFilePreview={setOpenFilePreview}
          file={openFilePreview}
        />
      )}
      <AppCard
        className='mb-3'
        headerTitle={<h4>{displayName && displayName !== '' ? displayName : "Home"} Settings</h4>}
        bodyContent={
          <AppForm
            values={formik.values}
            handleChange={formik.handleChange}
            errors={formik.errors}
            setOpenFilePreview={setOpenFilePreview}
            handleRemoveMainBannerImage={handleRemoveMainBannerImage}
            handleMainBannerImagesOnDrop={handleMainBannerImagesOnDrop}
            handleMainBannerImageAttachment={handleMainBannerImageAttachment}
            handleRemoveHighlight1Image={handleRemoveHighlight1Image}
            handleHighlight1ImagesOnDrop={handleHighlight1ImagesOnDrop}
            handleHighlight1ImageAttachment={handleHighlight1ImageAttachment}
            handleRemoveHighlight2Image={handleRemoveHighlight2Image}
            handleHighlight2ImagesOnDrop={handleHighlight2ImagesOnDrop}
            handleHighlight2ImageAttachment={handleHighlight2ImageAttachment}
          />
        }
        footerContent={
          <ActionButtonFormSecond
            isLoading={isLoading}
            hiddenButtonCancel
            onSave={formik.handleSubmit}
            saveButtonText='Save and Publish'
          />
        }
      />
    </>
  )
}

export default HomeSettings

