import React, { useState, useEffect } from 'react'
import moment from 'moment';
import momentTimezone from 'moment-timezone'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// components
import {
  CCol,
  CRow,
  CFormLabel,
  CAccordion,
  CAccordionHeader,
  CAccordionBody,
  CAccordionItem,
  CButton,
  CImage,
  CFormText,
  CFormCheck,
  CCard,
  CCardBody,
  CCardHeader,
  CFooter,
  CTimePicker,
  CInputGroup,
  CInputGroupText
} from '@coreui/react-pro'
import FormProductCombination from '../general-input-form/FormProductCombination';
import Select, { components } from 'react-select'
import CIcon from '@coreui/icons-react'
import AppCard from 'src/components/AppCard';
import AppForm from './AppForm';
import FormSelectInput from '../general-input-form/FormSelectInput';
import FormDatePickerRange from '../general-input-form/FormDatePickerRange';
import FormDatePicker from '../general-input-form/FormDatePicker';
import AppModalConfirmation from 'src/components/modal/AppModalConfirmation';

// icons
import { cilArrowRight } from '@coreui/icons'
import AddMoreIconWhite from '../../../assets/icons/AddMoreIconWhite.png'
import TrashIcon from '../../../assets/icons/TrashIcon.png'

// styling
import { styleButtonVars2, styleButtonVars4 } from 'src/components/custom-styles/buttonStyles';

// helpers
import { arrayScheduleBookingDates } from 'src/helpers/date-helper';
import { formatHourQuota, removeDuplicatesOptionsSelect, sortArrObj } from 'src/helpers/format-data';
import { singleOptionsSelect } from 'src/helpers/format-options';
import FormTextInput from '../general-input-form/FormTextInput';

const AppThirdForm = ({
  editPage,
  values,
  errors,
  handleChange,
  detailsPage,
  handleDatePickerVariantOptions,
  handleResetSavedScheduleVariantOptions,
  handleDeleteScheduleVariantOptions,
  handleIsAllTimeVariantOptions,
  handleScheduleOptionVariantOptions,
  handleScheduleWindowVariantOptions,
  handleChangeQuota
}) => {
  const [scheduleOptions, setScheduleOptions] = useState([{
    label: 'Please select...',
    value: '',
  }, {
    label: 'Weekdays Only',
    value: 'weekdays-only'
  }, {
    label: 'Weekend Only',
    value: 'weekend-only'
  }])
  const [scheduleWindowOptions, setScheduleWindowOptions] = useState([{
    label: 'Please select...',
    value: '',
  }, {
    label: '1 Hour',
    value: '1-hour'
  }, {
    label: '45 Minutes',
    value: '45-minutes'
  }, {
    label: '30 Minutes',
    value: '30-minutes'
  }, {
    label: '15 Minutes',
    value: '15-minutes'
  }])
  const [preferredTimeOptions, setPreferredTimeOptions] = useState([{
    label: 'Please select...',
    value: '',
  }, {
    label: 'Morning',
    value: 'morning'
  }, {
    label: 'Afternoon',
    value: 'afternoon'
  }, {
    label: 'Evening',
    value: 'evening'
  }])
  const [preferredMorningHoursOptions, setPreferredMorningHoursOptions] = useState([{
    label: '07:00',
    value: '07:00'
  }, {
    label: '08:00',
    value: '08:00'
  }, {
    label: '09:00',
    value: '09:00'
  }, {
    label: '10:00',
    value: '10:00'
  }, {
    label: '11:00',
    value: '11:00'
  }])
  const [preferredAfternoonHoursOptions, setPreferredAfternoonHoursOptions] = useState([{
    label: '12:00',
    value: '12:00'
  }, {
    label: '13:00',
    value: '13:00'
  }, {
    label: '14:00',
    value: '14:00'
  }, {
    label: '15:00',
    value: '15:00'
  }, {
    label: '16:00',
    value: '16:00'
  }])
  const [preferredEveningHoursOptions, setPreferredEveningHoursOptions] = useState([{
    label: '17:00',
    value: '17:00'
  }, {
    label: '18:00',
    value: '18:00'
  }, {
    label: '19:00',
    value: '19:00'
  }, {
    label: '20:00',
    value: '20:00'
  }, {
    label: '21:00',
    value: '21:00'
  }])

  const [serviceId, setServiceId] = useState('')
  const [serviceFromFetch, setServiceFromFetch] = useState(false)
  const [selectedServicePackage, setSelectedServicePackage] = useState('')
  const [selectedServicePackageDetail, setSelectedServicePackageDetail] = useState({})
  const [selectedServiceDateList, setSelectedServiceDateList] = useState([])
  const [serviceDate, setServiceDate] = useState('')
  const [serviceAllTimePeriod, setServiceAllTimePeriod] = useState(false)
  const [serviceSchedule, setServiceSchedule] = useState('')
  const [serviceScheduleWindow, setServiceScheduleWindow] = useState('')
  const [servicePreferredTime, setServicePreferredTime] = useState('')
  const [servicePreferredHours, setServicePreferredHours] = useState([])
  const [serviceAsUnavailable, setServiceAsUnavailable] = useState(false)
  
  const [openInputCustomHours, setOpenInputCustomHours] = useState(false)
  const [showConfirmSaveModal, setShowConfirmSaveModal] = useState({ show: false, item: {} })
  const [showConfirmResetModal, setShowConfirmResetModal] = useState({ show: false, item: {} })
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState({ show: false, item: {} })
  const [customHours, setCustomHours] = useState('')

  useEffect(() => {
    if (values.service_variants && values.service_variants.length > 0) {
      if (selectedServicePackage && selectedServicePackage !== '') {
        const selectedVariantOption = values.service_variants[0].options.filter(option => {
          return option.option_name === selectedServicePackage
        })
        if (selectedVariantOption && selectedVariantOption.length > 0) {
          setSelectedServicePackageDetail(selectedVariantOption[0])
          setSelectedServiceDateList(selectedVariantOption[0].option_service_date_list)
        }
      }
    }
  }, [values.service_variants, selectedServicePackage, servicePreferredTime]);

  useEffect(() => {
    if (selectedServicePackageDetail && selectedServicePackageDetail.option_name) {
      setServiceAllTimePeriod(selectedServicePackageDetail.option_service_is_all_time)
      setServiceSchedule(selectedServicePackageDetail.option_service_schedule_option)
      setServiceScheduleWindow(selectedServicePackageDetail.option_service_schedule_window)
    }
  }, [selectedServicePackageDetail]);

  useEffect(() => {
    if (servicePreferredTime) {
      let existingSchedule = []
      if (selectedServiceDateList.length > 0) {
        existingSchedule = selectedServiceDateList.filter(schedule => {
          if (schedule && schedule.service_preferred_time == servicePreferredTime) {
            return moment(schedule.service_date).format('l') === moment(serviceDate).format('l')
          }
        })
        if (existingSchedule && existingSchedule.length > 0) {
          setServicePreferredHours(sortArrObj(existingSchedule[0].service_preferred_hours, 'value'))
        } else {
          setServicePreferredHours([])
        }
      } else {
        setServicePreferredHours([])
      }
    }
  }, [servicePreferredTime])

  const handleSelectedDate = (date) => {
    let existingSchedule = []
    if (selectedServiceDateList.length > 0) {
      existingSchedule = selectedServiceDateList.filter(schedule => {
        return moment(schedule.service_date).format('l') === moment(date).format('l')
      })
      if (existingSchedule && existingSchedule.length > 0) {
        setServiceDate(date)
        setServiceId(existingSchedule[0].service_schedule_id)
        setServicePreferredTime(existingSchedule[0].service_preferred_time)
        setServicePreferredHours(sortArrObj(existingSchedule[0].service_preferred_hours, 'value'))
        setServiceFromFetch(existingSchedule[0].is_from_fetch)
      } else {
        setServiceId('')
        setServiceDate(date)
        setServicePreferredTime('')
        setServicePreferredHours([])
        setServiceAsUnavailable(false)
        setServiceFromFetch(false)
      }
    } else {
      setServiceId('')
      setServiceDate(date)
      setServicePreferredTime('')
      setServicePreferredHours([])
      setServiceAsUnavailable(false)
      setServiceFromFetch(false)
    }
  }

  const handleInputCustomHours = () => {
    if (servicePreferredTime && servicePreferredTime !== '') {
      if (servicePreferredTime == 'morning') {
        if (customHours && customHours !== "") {
          setPreferredMorningHoursOptions((prev) => (
            [
              ...prev,
              {
                label: moment(customHours).format("HH:mm"),
                value: moment(customHours).format("HH:mm")
              }
            ]
          ))
        }
      }
      if (servicePreferredTime == 'afternoon') {
        if (customHours && customHours !== "") {
          setPreferredAfternoonHoursOptions((prev) => (
            [
              ...prev,
              {
                label: moment(customHours).format("HH:mm"),
                value: moment(customHours).format("HH:mm")
              }
            ]
          ))
        }
      }
      if (servicePreferredTime == 'evening') {
        if (customHours && customHours !== "") {
          setPreferredEveningHoursOptions((prev) => (
            [
              ...prev,
              {
                label: moment(customHours).format("HH:mm"),
                value: moment(customHours).format("HH:mm")
              }
            ]
          ))
        }
      }
    }
    setCustomHours('')
  }

  const handleSaveSchedule = () => {
    let objSchedule = {}

    objSchedule.service_schedule_id = serviceId && serviceId !== '' ? serviceId : selectedServiceDateList.length + 1
    objSchedule.service_date = serviceDate
    objSchedule.service_preferred_time = servicePreferredTime
    objSchedule.service_preferred_hours = servicePreferredHours
    objSchedule.service_as_unavailable = serviceAsUnavailable

    handleDatePickerVariantOptions(selectedServicePackage, objSchedule)

    setServiceId('')
    setServiceDate('')
    setServicePreferredTime('')
    setServicePreferredHours([])
    setServiceAsUnavailable(false)
    setServiceFromFetch(false)
    setShowConfirmSaveModal({ show: false, item: {} })
  }

  const handleChangeSchedule = (itemDetails) => {
    handleResetSavedScheduleVariantOptions(selectedServicePackage)
    handleScheduleOptionVariantOptions(itemDetails, selectedServicePackage)
    setServiceId('')
    setServiceDate('')
    setServicePreferredTime('')
    setServicePreferredHours([])
    setServiceAsUnavailable(false)
    setServiceFromFetch(false)
    setShowConfirmResetModal({ show: false, item: {} })
  }

  const handleDeleteSelectedSchedule = (itemDetails) => {
    handleDeleteScheduleVariantOptions(itemDetails)
    setServiceId('')
    setServiceDate('')
    setServicePreferredTime('')
    setServicePreferredHours([])
    setServiceAsUnavailable(false)
    setServiceFromFetch(false)
    setShowConfirmDeleteModal({ show: false, item: {} })
  }

  const handleChangeQuotaOnPreferredHours = (quota, preferredHourValue) => {
    const newServicePreferredHours = servicePreferredHours && servicePreferredHours.map((sph) => {
      if (sph.value == preferredHourValue) {
        sph.quota = quota && quota.toString()
      }
      return sph
    })
    setServicePreferredHours(newServicePreferredHours)
  }

  return (
    <>
      {showConfirmSaveModal.show && (
        <AppModalConfirmation
          confirmationType='save-item'
          itemName='schedule'
          itemDetails={showConfirmSaveModal.item}
          showModal={showConfirmSaveModal}
          setShowModal={setShowConfirmSaveModal}
          handleConfirmation={handleSaveSchedule}
        />
      )}
      {showConfirmResetModal.show && (
        <AppModalConfirmation
          confirmationType='reset-item'
          itemName='schedule'
          itemDetails={showConfirmResetModal.item}
          showModal={showConfirmResetModal}
          setShowModal={setShowConfirmResetModal}
          handleConfirmation={handleChangeSchedule}
        />
      )}
      {showConfirmDeleteModal.show && (
        <AppModalConfirmation
          confirmationType='delete-item'
          itemName='schedule'
          itemDetails={showConfirmDeleteModal.item}
          showModal={showConfirmDeleteModal}
          setShowModal={setShowConfirmDeleteModal}
          handleConfirmation={handleDeleteSelectedSchedule}
        />
      )}
      <AppCard
        className='mb-3'
        headerTitle={
          <CFormLabel>
            Schedule Availability
          </CFormLabel>
        }
        bodyContent={
          <CRow>
            <CCol lg={6} >
              <FormSelectInput
                id='selected-service'
                name='selected-service'
                className='mb-3'
                colSize='12'
                inputLabel='Select Service Variant'
                selectOptions={singleOptionsSelect(values.service_variants && values.service_variants.length > 0 && values.service_variants[0].options)}
                value={selectedServicePackage}
                handleSelectInput={({ target: { value } }) => {
                  setSelectedServicePackage(value)
                  setServicePreferredHours([])
                  setCustomHours('')
                }}
              // feedbackInvalid={errors.service_preferred_time}
              // invalid={errors.service_preferred_time}
              />
              {selectedServicePackage && (
                <>
                  {!serviceAllTimePeriod && (
                    <div className="mt-2 mb-3 d-flex justify-content-center align-items-center">
                      <DatePicker
                        selected={serviceDate}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        onChange={(date) => handleSelectedDate(date)}
                        minDate={new Date()}
                        highlightDates={arrayScheduleBookingDates(selectedServiceDateList)}
                        calendarStartDay={1}
                        todayButton="Today"
                        inline
                        filterDate={date =>
                          serviceSchedule === 'weekdays-only' ? date.getDay() !== 6 && date.getDay() !== 0
                            : serviceSchedule === 'weekend-only' ? date.getDay() !== 1 && date.getDay() !== 2 && date.getDay() !== 3 && date.getDay() !== 4 && date.getDay() !== 5
                              : date
                        }
                      />
                    </div>
                  )}
                  {/* <CFormCheck
                    id='service_all_time_period'
                    name='service_all_time_period'
                    className='me-2'
                    label="Available for All Period of Time"
                    onChange={() => {
                      handleIsAllTimeVariantOptions(!serviceAllTimePeriod, selectedServicePackage)
                      setServiceSchedule('')
                    }}
                    checked={serviceAllTimePeriod}
                    disabled={detailsPage}
                  />
                  <CFormText className='mb-2 primary'>
                    <small>
                      The customers will be able to book this service at anytime
                    </small>
                  </CFormText> */}
                  {!serviceAllTimePeriod && (
                    <CRow>
                      <FormSelectInput
                        id='service_schedule'
                        name='service_schedule'
                        className='mb-3'
                        colSize='12'
                        inputLabel='Schedule'
                        selectOptions={scheduleOptions}
                        value={serviceSchedule}
                        handleSelectInput={({ target: { value } }) => setShowConfirmResetModal({ show: true, item: value })}
                        // feedbackInvalid={errors.service_schedule}
                        // invalid={errors.service_schedule}
                        disabled={detailsPage}
                      />
                    </CRow>
                  )}
                  <CRow>
                    <FormSelectInput
                      id='service_schedule_window'
                      name='service_schedule_window'
                      className='mb-3'
                      colSize='12'
                      inputLabel='Schedule Window'
                      selectOptions={scheduleWindowOptions}
                      value={serviceScheduleWindow}
                      handleSelectInput={({ target: { value } }) => { handleScheduleWindowVariantOptions(value, selectedServicePackage) }}
                      // feedbackInvalid={errors.service_schedule_window}
                      // invalid={errors.service_schedule_window}
                      disabled={detailsPage}
                    />
                  </CRow>
                </>
              )}
            </CCol>
            {selectedServicePackage && !serviceAllTimePeriod && (
              <CCol lg={6} >
                <CCard>
                  {!serviceAllTimePeriod && (
                    <CCardHeader className='app-header-title' component='h6'>
                      Selected Date
                    </CCardHeader>
                  )}
                  <CCardBody>
                    {!serviceAllTimePeriod && (
                      <>
                        <CRow className='mb-3'>
                          <CCol lg={12}>
                            <CFormLabel style={{ fontSize: '30px' }}>
                              {serviceDate && serviceDate !== '' ? moment(serviceDate).format('dddd') : moment(new Date()).format('dddd')}
                            </CFormLabel>
                            <CFormText style={{ fontSize: '20px' }}>
                              {serviceDate && serviceDate !== '' ? moment(serviceDate).format('LL') : moment(new Date()).format('LL')}
                            </CFormText>
                          </CCol>
                        </CRow>
                        <hr />
                      </>
                    )}
                    {!serviceAsUnavailable && (
                      <>
                        <CRow>
                          <FormSelectInput
                            id='service_preferred_time'
                            name='service_preferred_time'
                            className='mb-3'
                            colSize='12'
                            inputLabel='Preferred Time'
                            selectOptions={preferredTimeOptions}
                            value={servicePreferredTime}
                            handleSelectInput={({ target: { value } }) => {
                              setServicePreferredTime(value)
                              setServicePreferredHours([])
                              setCustomHours('')
                            }}
                            // feedbackInvalid={errors.service_preferred_time}
                            // invalid={errors.service_preferred_time}
                            disabled={detailsPage}
                          />
                        </CRow>
                        <CRow className='mb-3'>
                          <CFormLabel>
                            Select which hour this service is available:
                          </CFormLabel>
                          <Select
                            isMulti
                            placeholder={`Please select hours for this service available...`}
                            options={
                              servicePreferredTime == 'morning' ? removeDuplicatesOptionsSelect(preferredMorningHoursOptions)
                                : servicePreferredTime == 'afternoon' ? removeDuplicatesOptionsSelect(preferredAfternoonHoursOptions)
                                  : servicePreferredTime == 'evening' ? removeDuplicatesOptionsSelect(preferredEveningHoursOptions)
                                    : []
                            }
                            value={servicePreferredHours}
                            onChange={(value) => setServicePreferredHours(value)}
                            components={{
                              MultiValueRemove: (props) => {
                                if (props && props.data && props.data.hour_has_booked) {
                                  return null
                                }
                                return <components.MultiValueRemove {...props} />
                              }
                            }}
                            isDisabled={detailsPage}
                          />
                          <CFormText className='primary'>
                            <small>
                              {`All hours in ${momentTimezone.tz.guess()} time format (${momentTimezone().tz(momentTimezone.tz.guess()).format('z')})`}
                            </small>
                          </CFormText>
                        </CRow>
                        {
                          servicePreferredHours && servicePreferredHours.map((item) => {
                            return (
                              <CRow className='mb-3'>
                                <FormTextInput
                                  id={'quota-' + item && item.value}
                                  name={'quota-' + item && item.value}
                                  colSize='9'
                                  inputLabel={item && item.label + ' quota'}
                                  placeholder='Please fill in service name...'
                                  disabled={detailsPage}
                                  value={formatHourQuota({ 
                                    values, 
                                    selectedServicePackage,
                                    serviceId,
                                    selectedHourValue: item.value 
                                  })}
                                  handleTextInput={({ target: { value } }) => {
                                    handleChangeQuota({
                                      selectedOption: selectedServicePackage,
                                      selectedServiceSchedule: serviceId,
                                      selectedPreferredHour: item.value,
                                      quotaValue: value
                                    })
                                    handleChangeQuotaOnPreferredHours(value, item.value)
                                  }}
                                  // feedbackInvalid={errors.service_name}
                                  // invalid={errors.service_name}
                                  // disabled={detailsPage}
                                  type='number'
                                />
                              </CRow>
                            )
                          })
                        }
                        <CCol>
                          <div className="mt-2 mb-3 d-flex align-items-center">
                            {openInputCustomHours ? (
                              <>
                                <DatePicker
                                  selected={customHours}
                                  className='input-custom-time-picker me-3'
                                  onChange={(date) => setCustomHours(date)}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeFormat="HH:mm"
                                  dateFormat="HH:mm"
                                  timeIntervals={5}
                                  timeCaption="Time"
                                  placeholderText='Please input custom hour...'
                                  isDisabled={detailsPage}
                                  minTime={
                                    servicePreferredTime == 'morning' ? new Date().setHours(7, 0)
                                      : servicePreferredTime == 'afternoon' ? new Date().setHours(12, 1)
                                        : servicePreferredTime == 'evening' ? new Date().setHours(17, 1)
                                          : new Date().setHours(23, 58)
                                  }
                                  maxTime={
                                    servicePreferredTime == 'morning' ? new Date().setHours(11, 59)
                                      : servicePreferredTime == 'afternoon' ? new Date().setHours(16, 59)
                                        : servicePreferredTime == 'evening' ? new Date().setHours(21, 0)
                                          : new Date().setHours(23, 59)
                                  }
                                  autoComplete="off"
                                />
                                <CButton
                                  onClick={() => handleInputCustomHours()}
                                  style={styleButtonVars2}
                                  className='me-2'
                                  disabled={detailsPage}
                                >
                                  <CImage fluid src={AddMoreIconWhite} className='me-2' />
                                  Add
                                </CButton>
                                <CButton
                                  onClick={() => setOpenInputCustomHours((prev) => !prev)}
                                  style={styleButtonVars2}
                                  disabled={detailsPage}
                                >
                                  Cancel
                                </CButton>
                              </>
                            ) : (
                              <CButton
                                onClick={() => setOpenInputCustomHours((prev) => !prev)}
                                style={styleButtonVars2}
                                // className='mb-3'
                                hidden={detailsPage}
                              >
                                <CImage fluid src={AddMoreIconWhite} className='me-2' />
                                Add Custom Hours
                              </CButton>
                            )}
                          </div>
                        </CCol>
                      </>
                    )}
                    {/* <CFormCheck
                      id='service_set_as_unavailable'
                      name='service_set_as_unavailable'
                      className='me-2'
                      label="Set as Unavailable"
                      onChange={() => {
                        setServiceAsUnavailable((prev) => !prev)
                        setServicePreferredHours([])
                        setServicePreferredTime('')
                      }}
                      checked={serviceAsUnavailable}
                      disabled={detailsPage}
                    /> */}
                  </CCardBody>
                </CCard>
              </CCol>
            )}
          </CRow >
        }
        footerContent={
          !detailsPage && (
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              {serviceId && (
                <CButton
                  onClick={() => setShowConfirmDeleteModal({
                    show: true,
                    item: {
                      serviceScheduleId: serviceId,
                      selectedServicePackage,
                      isFromFetch: serviceFromFetch,
                      selectedDate: serviceDate,
                      servicePackageId: selectedServicePackageDetail.option_id
                    }
                  })}
                  style={styleButtonVars4}
                  hidden={detailsPage}
                >
                  Delete Schedule
                </CButton>
              )}
              <CButton
                disabled={
                  servicePreferredHours.length == 0 ||
                  servicePreferredHours.filter((item) => !item.quota || item.quota == 0 || item.quota == '')[0]
                }
                hidden={serviceAllTimePeriod || detailsPage}
                onClick={() => setShowConfirmSaveModal({ show: true, item: {} })}
                style={styleButtonVars4}
              >
                Save Schedule
              </CButton>
            </div >
          )
        }
      />
    </>
  )
}

export default AppThirdForm
