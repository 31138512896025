import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useFormik } from "formik"
import * as Yup from "yup"

// components
import AppCard from 'src/components/AppCard'
import AppFirstDetails from 'src/components/details/booking-details/AppFirstDetails'
import AppSecondDetails from 'src/components/details/booking-details/AppSecondDetails'
import AppModalConfirmation from 'src/components/modal/AppModalConfirmation'

// styling
import { styleButtonVars3 } from 'src/components/custom-styles/buttonStyles'

// helpers
import Axios from 'src/helpers/axios'
import FormTextAreaInput from 'src/components/form/general-input-form/FormTextAreaInput'
import { CButton } from '@coreui/react-pro'

const BookingDetails = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { id } = params

  const [bookingDetails, setBookingDetails] = useState('')
  const [adminNotesBooking, setAdminNotesBooking] = useState('')
  const [showConfirmBookingStatus, setShowConfirmBookingStatus] = useState({
    show: false,
    status: '',
  })

  const formik = useFormik({
    initialValues: {
      shipping_number: '',
    },
    validationSchema: Yup.object().shape({
      shipping_number: Yup.string().required("Please enter booking shipping number.")
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      handleSubmit(values)
      setSubmitting(false)
    },
  });

  useEffect(() => {
    fetchBookingDetails(id)
  }, [id]);

  const fetchBookingDetails = (id) => {
    Axios({
      method: "get",
      url: `/api/booking/v1/bookings/id/${id}`,
    })
      .then(({ data }) => {
        setBookingDetails(data)
        setAdminNotesBooking(data?.admin_notes)
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleUpdateBookingStatus = (payload) => {
    Axios({
      method: "post",
      url: `/api/booking/v1/transactions/shippingnumber/update/${id}`,
      data: payload
    })
      .then(({ data }) => {
        setShowConfirmBookingStatus({ show: false, status: '' })
        window.location.reload()
      })
      .catch((err) => {
        console.error(err);
      })
  }

  const handleSubmitAdminNotes = () => {
    Axios({
      method: "post",
      url: `/api/booking/v1/bookings/update/admin-notes/${id}`,
      data: {
        admin_notes: adminNotesBooking
      }
    })
      .then(({ data }) => {
        window.location.reload()
      })
      .catch((err) => {
        console.error(err);
      })
  }

  const handleInputBookingStatus = (values) => {
    let objPayload = {}

    objPayload.shipping_number = values.shipping_number

    return objPayload;
  }

  const handleSubmit = (values) => {
    const inputPayload = handleInputBookingStatus(values)
    handleUpdateBookingStatus(inputPayload)
  }

  return (
    <>
      {showConfirmBookingStatus.show && showConfirmBookingStatus.status === 'shipped' && (
        <AppModalConfirmation
          confirmationType='shipping-booking'
          itemName='booking'
          showModal={showConfirmBookingStatus}
          setShowModal={setShowConfirmBookingStatus}
          handleConfirmation={() => formik.handleSubmit()}
          values={formik.values}
          errors={formik.errors}
          handleChange={formik.handleChange}
        />
      )}
      <AppCard
        className='mb-4'
        headerTitle={<h4>Booking Details</h4>}
        bodyContent={
          <AppFirstDetails
            bookingDetails={bookingDetails}
            setShowConfirmBookingStatus={setShowConfirmBookingStatus}
          />
        }
        lowerBodyContent={
          <>
            <AppSecondDetails
              bookingDetails={bookingDetails}
            />
            <FormTextAreaInput
              colSize='12'
              className="mt-3 mb-2"
              inputLabel='Admin Notes'
              placeholder='Please input admin additional notes here...'
              rows='3'
              value={adminNotesBooking}
              handleTextAreaInput={({ target: { value } }) => setAdminNotesBooking(value)}
            />
            <div className='mb-3 d-flex flex-row-reverse'>
              <CButton
                style={styleButtonVars3}
                onClick={handleSubmitAdminNotes}
              >
                Save
              </CButton>
            </div>
          </>
        }
      />
    </>
  )
}

export default BookingDetails
