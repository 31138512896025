import React, { useEffect, useState } from 'react'
import { useFormik } from "formik"
import * as Yup from "yup"
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

// components
import AppCard from 'src/components/AppCard'
import AppForm from 'src/components/form/add-service/AppForm'
import AppModalFilePreview from 'src/components/modal/AppModalFilePreview'
import ActionButtonFormFirst from 'src/components/form/general-input-form/ActionButtonFormFirst'

// helpers
import Axios from 'src/helpers/axios'
import { singleOptionsSelect } from 'src/helpers/format-options'
import { capitalizeEachWord } from 'src/helpers/text-format'
import { getArrayCombinationVariantID } from 'src/helpers/format-api-fetch-data'

const AddService = () => {
  const navigate = useNavigate()

  const [categoryOptions, setCategoryOptions] = useState([])
  const [openFilePreview, setOpenFilePreview] = useState(false)
  const [isLoadingDraft, setIsLoadingDraft] = useState(false)
  const [isLoadingPublish, setIsLoadingPublish] = useState(false)
  const [openFormServiceVariant, setOpenFormServiceVariant] = useState(false)
  const [inputVariant, setInputVariant] = useState('')

  // state for validation empty field
  const [isServiceVariantEmpty, setIsServiceVariantEmpty] = useState(false)

  const formik = useFormik({
    initialValues: {
      service_name: '',
      category_id: '',
      service_desc: '',
      service_summary: '',
      service_variants: [],
    },
    validationSchema: Yup.object().shape({
      service_name: Yup.string()
        .required("Please enter service name."),
      category_id: Yup.string()
        .required("Please select category option."),
      service_desc: Yup.string()
        // .max(100, "Max 100 characters allowed.")
        .required("Please enter service description."),
      service_summary: Yup.string()
        // .max(100, "Max 100 characters allowed.")
        .required("Please enter service description."),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      const isAllInputValid = handleInputValidation(values);
      if (isAllInputValid) {
        await handlePublish(values, false)
        setSubmitting(false)
        navigate('/services/list')
        window.location.reload()
      }
    },
  });

  useEffect(() => {
    fetchAllCategories()
  }, []);

  // API handler function
  const fetchAllCategories = () => {
    Axios({
      method: "get",
      url: "/api/booking/v1/categories/all",
    })
      .then(({ data }) => {
        setCategoryOptions(singleOptionsSelect(data))
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const createService = async (payload) => {
    try {
      const response = await Axios.post(`/api/booking/v1/services/create`, payload)
      const { data } = response
      return data;
    } catch (error) {
      console.error(error)
    }
  }

  const createServiceVariantOptions = async (serviceId, payload) => {
    try {
      const response = await Axios.post(`/api/booking/v1/services/package/create/${serviceId}`, payload)
      const { data } = response
      return data;
    } catch (error) {
      console.error(error)
    }
  }

  const createBookingTimeVariantOptions = async (payload) => {
    try {
      const response = await Axios.post(`/api/booking/v1/services/timeslot/add`, payload)
      const { data } = response
      return data;
    } catch (error) {
      console.error(error)
    }
  }

  // additional input validation
  const handleInputValidation = (values) => {
    // empty field validation
    let isServiceVariantFieldEmpty = false;

    // check field service variant
    if (values.service_variants.length > 0) {
      for (let i = 0; i < values.service_variants.length; i++) {
        if (!values.service_variants[i].name || values.service_variants[i].name == '') {
          isServiceVariantFieldEmpty = true
        }
        for (let j = 0; j < values.service_variants[i].options.length; j++) {
          if (!values.service_variants[i].options[j].option_name || values.service_variants[i].options[j].option_name == '') {
            isServiceVariantFieldEmpty = true
          }
          if (!values.service_variants[i].options[j].option_price || values.service_variants[i].options[j].option_price == '') {
            isServiceVariantFieldEmpty = true
          }
        }
      }
    }

    setIsServiceVariantEmpty(isServiceVariantFieldEmpty);

    if (
      isServiceVariantFieldEmpty !== true
    ) {
      return true;
    } else {
      return false;
    }
  };

  // handler function for input value
  const handleInputService = (values, isDraft) => {
    const objectPayload = {}

    objectPayload.title = values.service_name
    objectPayload.description = values.service_desc
    objectPayload.summary = values.service_summary
    objectPayload.is_active = isDraft ? false : true
    objectPayload.category_id = parseInt(values.category_id)
    objectPayload.is_draft = isDraft
    objectPayload.quota = values.service_quota_limit !== '' ? parseInt(values.service_quota_limit) : null
    objectPayload.price = values.service_unit_price !== '' ? parseInt(values.service_unit_price) : null
    objectPayload.sale_price = values.service_sale_price !== '' ? parseInt(values.service_sale_price) : null

    return objectPayload
  }

  const handleInputServiceVariantOption = (values, isDraft) => {
    const objectPayload = {}

    objectPayload.title = `${formik.values.service_variants[0].name} - ${values.option_name}`
    objectPayload.is_active = isDraft ? false : true
    objectPayload.quota = values.option_quota !== '' ? parseInt(values.option_quota) : null
    objectPayload.price = values.option_price !== '' ? parseInt(values.option_price) : null
    objectPayload.sale_price = values.option_sale_price !== '' ? parseInt(values.option_sale_price) : null
    objectPayload.is_available_all_time = values.option_service_is_all_time !== '' ? values.option_service_is_all_time : false
    objectPayload.schedule_option = values.option_service_schedule_option !== '' ? values.option_service_schedule_option : null
    objectPayload.schedule_window = values.option_service_schedule_window !== '' ? values.option_service_schedule_window : null

    return objectPayload
  }

  const handleInputBookingTime = (serviceId, packageId, values) => {
    const arrayPayload = []

    for (let i = 0; i < values.length; i++) {
      const serviceDate = moment(values[i].service_date).format('L')
      if (values[i].service_as_unavailable) {
        arrayPayload.push({
          service_id: serviceId,
          package_id: packageId,
          date: moment(`${serviceDate}`).format(),
          is_available: false,
          preferred_time: values[i].service_preferred_time,
          quota: values[i].service_preferred_hours[j].quota
        })
      } else {
        for (let j = 0; j < values[i].service_preferred_hours.length; j++) {
          arrayPayload.push({
            service_id: serviceId,
            package_id: packageId,
            date: moment(`${serviceDate} ${values[i].service_preferred_hours[j].value}`).format(),
            is_available: true,
            preferred_time: values[i].service_preferred_time,
            quota: values[i].service_preferred_hours[j].quota
          })
        }
      }
    }

    return arrayPayload
  }

  const handlePublish = async (values, isDraft) => {
    if (isDraft) {
      setIsLoadingDraft(true)
    } else {
      setIsLoadingPublish(true)
    }

    // input service
    const servicePayload = handleInputService(values, isDraft)

    // create service
    const service = await createService(servicePayload)

    // create service variant
    if (values.service_variants.length > 0) {
      for (let i = 0; i < values.service_variants.length; i++) {
        // create variant options
        if (values.service_variants[i].options.length > 0) {
          for (let j = 0; j < values.service_variants[i].options.length; j++) {
            // input service variant options
            const serviceVariantOptionsPayload = handleInputServiceVariantOption(values.service_variants[i].options[j], isDraft)
            // create service variant options
            const servicePackage = await createServiceVariantOptions(service.id, serviceVariantOptionsPayload)
            // input service booking time
            const serviceBookingTimePayload = handleInputBookingTime(service.id, servicePackage.id, values.service_variants[i].options[j].option_service_date_list)
            // create service booking time
            await createBookingTimeVariantOptions(serviceBookingTimePayload)
          }
        }
      }
    }

    if (isDraft) {
      setIsLoadingDraft(false)
      navigate('/services/list')
      window.location.reload()
    } else {
      setIsLoadingPublish(false)
    }
  }

  // handler function service variant
  const handleInputVariantType = (e) => {
    if (e.key === 'Enter' && inputVariant !== '') {
      formik.setValues((values) => ({
        ...values,
        service_variants: [
          ...values.service_variants,
          ...[{
            name: capitalizeEachWord(inputVariant),
            options: [{
              option_name: '',
              option_price: '',
              option_sale_price: '',
              option_quota: '',
              option_service_is_all_time: false,
              option_service_schedule_option: '',
              option_service_schedule_window: '',
              option_service_date_list: []
            }]
          }]
        ]
      }), false)
      setInputVariant('')
    }
    if (e.key === 'Escape') {
      setInputVariant('')
    }
  }

  const handleRemoveInputVariantType = (variant) => {
    formik.setValues((values) => ({
      ...values,
      service_variants: values.service_variants.filter((item) => item !== variant)
    }), false)
  }

  const handleAddVariantOptions = (variant, variantIndex) => {
    formik.setValues((values) => {
      const newArrayVariantOptions = values.service_variants.map((item, itemIndex) => {
        if (item.name == variant.name && itemIndex == variantIndex) {
          return {
            ...item,
            options: [
              ...item.options,
              ...[{
                option_name: '',
                option_price: '',
                option_sale_price: '',
                option_quota: '',
                option_service_is_all_time: false,
                option_service_schedule_option: '',
                option_service_schedule_window: '',
                option_service_date_list: []
              }]
            ]
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        service_variants: [
          ...newArrayVariantOptions,
        ]
      }
    }, false);
  }

  const handleRemoveVariantOptions = (variantIndex, option, optionIndex) => {
    formik.setValues((values) => {
      const newArrayVariantOptions = values.service_variants.map((item, itemIndex) => {
        if (itemIndex == variantIndex) {
          return {
            ...item,
            options: item.options.filter((element, elementIndex) => elementIndex !== optionIndex)
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        service_variants: [
          ...newArrayVariantOptions,
        ]
      }
    }, false);
  }

  const handleTextVariantOptions = (name, variantIndex, option, optionIndex) => {
    formik.setValues((values) => {
      const newArrayVariantOptions = values.service_variants.map((item, itemIndex) => {
        if (itemIndex == variantIndex) {
          return {
            ...item,
            options: item.options.map((element, elementIndex) => {
              if (elementIndex == optionIndex) {
                return {
                  ...element,
                  option_name: name
                }
              } else {
                return element
              }
            })
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        service_variants: [
          ...newArrayVariantOptions,
        ]
      }
    }, false);
  }

  const handlePriceVariantOptions = (price, variantIndex, option, optionIndex) => {
    formik.setValues((values) => {
      const newArrayVariantOptions = values.service_variants.map((item, itemIndex) => {
        if (itemIndex == variantIndex) {
          return {
            ...item,
            options: item.options.map((element, elementIndex) => {
              if (elementIndex == optionIndex) {
                return {
                  ...element,
                  option_price: price
                }
              } else {
                return element
              }
            })
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        service_variants: [
          ...newArrayVariantOptions,
        ]
      }
    }, false);
  }

  const handleSalePriceVariantOptions = (price, variantIndex, option, optionIndex) => {
    formik.setValues((values) => {
      const newArrayVariantOptions = values.service_variants.map((item, itemIndex) => {
        if (itemIndex == variantIndex) {
          return {
            ...item,
            options: item.options.map((element, elementIndex) => {
              if (elementIndex == optionIndex) {
                return {
                  ...element,
                  option_sale_price: price
                }
              } else {
                return element
              }
            })
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        service_variants: [
          ...newArrayVariantOptions,
        ]
      }
    }, false);
  }

  const handleQuotaVariantOptions = (quota, variantIndex, option, optionIndex) => {
    formik.setValues((values) => {
      const newArrayVariantOptions = values.service_variants.map((item, itemIndex) => {
        if (itemIndex == variantIndex) {
          return {
            ...item,
            options: item.options.map((element, elementIndex) => {
              if (elementIndex == optionIndex) {
                return {
                  ...element,
                  option_quota: quota
                }
              } else {
                return element
              }
            })
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        service_variants: [
          ...newArrayVariantOptions,
        ]
      }
    }, false);
  }

  const handleIsAllTimeVariantOptions = (allTime, selectedService,) => {
    formik.setValues((values) => {
      const newArrayVariantOptions = values.service_variants.map((item, itemIndex) => {
        if (itemIndex == 0) {
          return {
            ...item,
            options: item.options.map((element, elementIndex) => {
              if (element.option_name == selectedService) {
                return {
                  ...element,
                  option_service_is_all_time: allTime
                }
              } else {
                return element
              }
            })
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        service_variants: [
          ...newArrayVariantOptions,
        ]
      }
    }, false);
  }

  const handleScheduleOptionVariantOptions = (scheduleOption, selectedService) => {
    formik.setValues((values) => {
      const newArrayVariantOptions = values.service_variants.map((item, itemIndex) => {
        if (itemIndex == 0) {
          return {
            ...item,
            options: item.options.map((element, elementIndex) => {
              if (element.option_name == selectedService) {
                return {
                  ...element,
                  option_service_schedule_option: scheduleOption
                }
              } else {
                return element
              }
            })
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        service_variants: [
          ...newArrayVariantOptions,
        ]
      }
    }, false);
  }

  const handleScheduleWindowVariantOptions = (scheduleWindow, selectedService,) => {
    formik.setValues((values) => {
      const newArrayVariantOptions = values.service_variants.map((item, itemIndex) => {
        if (itemIndex == 0) {
          return {
            ...item,
            options: item.options.map((element, elementIndex) => {
              if (element.option_name == selectedService) {
                return {
                  ...element,
                  option_service_schedule_window: scheduleWindow
                }
              } else {
                return element
              }
            })
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        service_variants: [
          ...newArrayVariantOptions,
        ]
      }
    }, false);
  }

  const handleDatePickerVariantOptions = (selectedService, schedule) => {
    const existingServiceOption = formik.values.service_variants[0].options.filter(option => {
      return option.option_name == selectedService
    })
    let existingSchedule = []
    if (
      existingServiceOption &&
      existingServiceOption.length > 0 &&
      existingServiceOption[0].option_service_date_list &&
      existingServiceOption[0].option_service_date_list.length > 0
    ) {
      existingSchedule = existingServiceOption[0].option_service_date_list.filter(option => {
        return option.service_schedule_id == schedule.service_schedule_id
      })
    }
    if (existingSchedule && existingSchedule.length > 0) {
      formik.setValues((values) => {
        const newArrayVariantOptions = values.service_variants.map((item, itemIndex) => {
          if (itemIndex == 0) {
            return {
              ...item,
              options: item.options.map((element, elementIndex) => {
                if (element.option_name == selectedService) {
                  return {
                    ...element,
                    option_service_date_list: element.option_service_date_list.map((list) => {
                      if (list.service_schedule_id == schedule.service_schedule_id) {
                        return schedule
                      } else {
                        return list
                      }
                    })
                  }
                } else {
                  return element
                }
              })
            };
          } else {
            return item;
          }
        });

        return {
          ...values,
          service_variants: [
            ...newArrayVariantOptions,
          ]
        }
      }, false);
    } else {
      formik.setValues((values) => {
        const newArrayVariantOptions = values.service_variants.map((item, itemIndex) => {
          if (itemIndex == 0) {
            return {
              ...item,
              options: item.options.map((element, elementIndex) => {
                if (element.option_name == selectedService) {
                  return {
                    ...element,
                    option_service_date_list: [
                      ...element.option_service_date_list,
                      schedule
                    ]
                  }
                } else {
                  return element
                }
              })
            };
          } else {
            return item;
          }
        });

        return {
          ...values,
          service_variants: [
            ...newArrayVariantOptions,
          ]
        }
      }, false);
    }
    formik.handleSubmit()
  }

  const handleResetSavedScheduleVariantOptions = (selectedService) => {
    formik.setValues((values) => {
      const newArrayVariantOptions = values.service_variants.map((item, itemIndex) => {
        if (itemIndex == 0) {
          return {
            ...item,
            options: item.options.map((element, elementIndex) => {
              if (element.option_name == selectedService) {
                return {
                  ...element,
                  option_service_date_list: []
                }
              } else {
                return element
              }
            })
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        service_variants: [
          ...newArrayVariantOptions,
        ]
      }
    }, false);
  }

  const handleChangeQuota = ({ selectedOption, selectedServiceSchedule, selectedPreferredHour, quotaValue }) => {
    formik.setValues((values) => {
      const newArrayVariantOptions = values.service_variants.map((item, itemIndex) => {
        if (itemIndex == 0) {
          return {
            ...item,
            options: item.options.map((option) => {
              if (option.option_name == selectedOption) {
                return {
                  ...option,
                  option_service_date_list: option.option_service_date_list.map((serviceDateList) => {
                    if (serviceDateList.service_schedule_id == selectedServiceSchedule) {
                      return {
                        ...serviceDateList,
                        service_preferred_hours: serviceDateList.service_preferred_hours.map((servicePreferredHour) => {
                          if (servicePreferredHour.value == selectedPreferredHour) {
                            return {
                              ...servicePreferredHour,
                              quota: quotaValue
                            }
                          } else {
                            return servicePreferredHour
                          }
                        })
                      }
                    } else {
                      return serviceDateList
                    }
                  })
                }
              } else {
                return option
              }
            })
          }
        } else {
          return item
        }
      })
      return {
        ...values,
        service_variants: [
          ...newArrayVariantOptions,
        ]
      }
    }, false)
  }

  const handleDeleteScheduleVariantOptions = (selectedService) => {
    formik.setValues((values) => {
      const newArrayVariantOptions = values.service_variants.map((item, itemIndex) => {
        if (itemIndex == 0) {
          return {
            ...item,
            options: item.options.map((element, elementIndex) => {
              if (element.option_name == selectedService.selectedServicePackage) {
                return {
                  ...element,
                  option_service_date_list: element.option_service_date_list.filter((list) =>
                    list.service_schedule_id != selectedService.serviceScheduleId
                  )
                }
              } else {
                return element
              }
            })
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        service_variants: [
          ...newArrayVariantOptions,
        ]
      }
    }, false);
  }

  return (
    <>
      {openFilePreview && (
        <AppModalFilePreview
          setOpenFilePreview={setOpenFilePreview}
          file={openFilePreview}
        />
      )}
      <AppCard
        className='mb-4'
        headerTitle={<h4>Add Service</h4>}
        bodyContent={
          <AppForm
            values={formik.values}
            handleChange={formik.handleChange}
            errors={formik.errors}
            categoryOptions={categoryOptions}
            isServiceVariantEmpty={isServiceVariantEmpty}
            setOpenFilePreview={setOpenFilePreview}
            openFormServiceVariant={openFormServiceVariant}
            setOpenFormServiceVariant={setOpenFormServiceVariant}
            setInputVariant={setInputVariant}
            inputVariant={inputVariant}
            handleInputVariantType={handleInputVariantType}
            handleAddVariantOptions={handleAddVariantOptions}
            handleRemoveInputVariantType={handleRemoveInputVariantType}
            handleRemoveVariantOptions={handleRemoveVariantOptions}
            handlePriceVariantOptions={handlePriceVariantOptions}
            handleSalePriceVariantOptions={handleSalePriceVariantOptions}
            handleQuotaVariantOptions={handleQuotaVariantOptions}
            handleTextVariantOptions={handleTextVariantOptions}
            handleDatePickerVariantOptions={handleDatePickerVariantOptions}
            handleResetSavedScheduleVariantOptions={handleResetSavedScheduleVariantOptions}
            handleDeleteScheduleVariantOptions={handleDeleteScheduleVariantOptions}
            handleIsAllTimeVariantOptions={handleIsAllTimeVariantOptions}
            handleScheduleOptionVariantOptions={handleScheduleOptionVariantOptions}
            handleScheduleWindowVariantOptions={handleScheduleWindowVariantOptions}
            handleChangeQuota={handleChangeQuota}
          />
        }
        footerContent={
          <ActionButtonFormFirst
            isDraft={true}
            isLoadingDraft={isLoadingDraft}
            isLoadingPublish={isLoadingPublish}
            disabled={formik.isSubmitting}
            onCancel={() => navigate('/services/list')}
            onDraft={() => handlePublish(formik.values, true)}
            onPublish={() => formik.handleSubmit()}
          />
        }
      />
    </>
  )
}

export default AddService
