import React, { useState } from 'react'

// components
import {
  CRow,
  CCol,
  CAccordion,
  CAccordionHeader,
  CAccordionBody,
  CAccordionItem,
} from '@coreui/react-pro'
import FormTextInput from 'src/components/form/general-input-form/FormTextInput'
import FormTextAreaInput from 'src/components/form/general-input-form/FormTextAreaInput'
import FormImageThumbnail from 'src/components/form/general-input-form/FormImageThumbnail';
import FormImageUpload from 'src/components/form/general-input-form/FormImageUpload';

const AppForm = ({
  values,
  errors,
  handleChange,
  handleRemoveMainBannerImage,
  handleMainBannerImagesOnDrop,
  handleMainBannerImageAttachment,
  handleRemoveCard1Image,
  handleCard1ImagesOnDrop,
  handleCard1ImageAttachment,
  handleRemoveCard2Image,
  handleCard2ImagesOnDrop,
  handleCard2ImageAttachment,
  handleRemoveCard3Image,
  handleCard3ImagesOnDrop,
  handleCard3ImageAttachment,
  handleRemoveBottomHighlightImage,
  handleBottomHighlightImagesOnDrop,
  handleBottomHighlightImageAttachment,
  setOpenFilePreview
}) => {

  return (
    <>
      <CRow className="mb-3">
        <FormTextInput
          id='display_name'
          name='display_name'
          className="mb-3"
          colSize='12'
          inputLabel='Display Name'
          placeholder='Enter display name...'
          value={values.display_name}
          handleTextInput={handleChange}
          feedbackInvalid={errors.display_name}
          invalid={errors.display_name}
        />
        {values.about_us_main_banner.length > 0 ? (
          <FormImageThumbnail
            // editPage={editPage}
            name='about_us_main_banner'
            inputLabel='Main Banner Image'
            colSize='12'
            classNameRow="ms-2 d-flex justify-content-center align-item-center"
            productImages={values.about_us_main_banner}
            handleChangeImage={handleMainBannerImageAttachment}
            handleRemoveImage={handleRemoveMainBannerImage}
            setOpenFilePreview={setOpenFilePreview}
          />
        ) : (
          <FormImageUpload
            name='about_us_main_banner'
            colSize='12'
            inputLabel='Main Banner Image'
            handleFileInput={handleMainBannerImageAttachment}
            handleFileOnDrop={handleMainBannerImagesOnDrop}
            multiple={false}
          />
        )}

      </CRow>
      <CRow className="mb-3">
        <CAccordion >
          <CAccordionItem itemKey={1}>
            <CAccordionHeader >
              <h5>Top Highlight Summary</h5>
            </CAccordionHeader>
            <CAccordionBody>
              <FormTextInput
                id='top_highlight_headline'
                name='top_highlight_headline'
                className="mb-3"
                colSize='12'
                inputLabel='Title'
                placeholder='Enter highlight headline here...'
                value={values.top_highlight_headline}
                handleTextInput={handleChange}
                feedbackInvalid={errors.top_highlight_headline}
                invalid={errors.top_highlight_headline}
              />
              <FormTextInput
                id='top_highlight_title'
                name='top_highlight_title'
                className="mb-3"
                colSize='12'
                inputLabel='Link'
                placeholder='Enter highlight title here...'
                value={values.top_highlight_title}
                handleTextInput={handleChange}
                feedbackInvalid={errors.top_highlight_title}
                invalid={errors.top_highlight_title}
              />
              <FormTextAreaInput
                id='top_highlight_description'
                name='top_highlight_description'
                className="mb-3"
                colSize='12'
                inputLabel='Description'
                placeholder='Enter highlight description here...'
                rows='10'
                value={values.top_highlight_description}
                handleTextAreaInput={handleChange}
                feedbackInvalid={errors.top_highlight_description}
                invalid={errors.top_highlight_description}
              />
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader >
              <h5>Summary Cards</h5>
            </CAccordionHeader>
            <CAccordionBody>
              <CAccordion >
                <CAccordionItem itemKey={1}>
                  <CAccordionHeader >
                    <h5>Card 1</h5>
                  </CAccordionHeader>
                  <CAccordionBody>
                    <CRow>
                      <CCol lg={6}>
                        <FormTextInput
                          id='card_headline_1'
                          name='card_headline_1'
                          className="mb-3"
                          colSize='12'
                          inputLabel='Headline'
                          placeholder='Enter headline here...'
                          value={values.card_headline_1}
                          handleTextInput={handleChange}
                          feedbackInvalid={errors.card_headline_1}
                          invalid={errors.card_headline_1}
                        />
                        <FormTextAreaInput
                          id='card_description_1'
                          name='card_description_1'
                          className="mb-3"
                          colSize='12'
                          inputLabel='Description'
                          placeholder='Enter highlight description here...'
                          rows='10'
                          value={values.card_description_1}
                          handleTextAreaInput={handleChange}
                          feedbackInvalid={errors.card_description_1}
                          invalid={errors.card_description_1}
                        />
                      </CCol>
                      <CCol lg={6}>
                        {values.card_image_1.length > 0 ? (
                          <FormImageThumbnail
                            // editPage={editPage}
                            name='card_image_1'
                            inputLabel='Headline Image'
                            colSize='12'
                            classNameRow="ms-2"
                            productImages={values.card_image_1}
                            handleChangeImage={handleCard1ImageAttachment}
                            handleRemoveImage={handleRemoveCard1Image}
                            setOpenFilePreview={setOpenFilePreview}
                          />
                        ) : (
                          <FormImageUpload
                            name='card_image_1'
                            colSize='12'
                            inputLabel='Headline Image'
                            handleFileInput={handleCard1ImageAttachment}
                            handleFileOnDrop={handleCard1ImagesOnDrop}
                            multiple={false}
                          />
                        )}
                      </CCol>
                    </CRow>
                  </CAccordionBody>
                </CAccordionItem>
                <CAccordionItem itemKey={2}>
                  <CAccordionHeader >
                    <h5>Card 2</h5>
                  </CAccordionHeader>
                  <CAccordionBody>
                    <CRow>
                      <CCol lg={6}>
                        <FormTextInput
                          id='card_headline_2'
                          name='card_headline_2'
                          className="mb-3"
                          colSize='12'
                          inputLabel='Headline'
                          placeholder='Enter headline here...'
                          value={values.card_headline_2}
                          handleTextInput={handleChange}
                          feedbackInvalid={errors.card_headline_2}
                          invalid={errors.card_headline_2}
                        />
                        <FormTextAreaInput
                          id='card_description_2'
                          name='card_description_2'
                          className="mb-3"
                          colSize='12'
                          inputLabel='Description'
                          placeholder='Enter highlight description here...'
                          rows='10'
                          value={values.card_description_2}
                          handleTextAreaInput={handleChange}
                          feedbackInvalid={errors.card_description_2}
                          invalid={errors.card_description_2}
                        />
                      </CCol>
                      <CCol lg={6}>
                        {values.card_image_2.length > 0 ? (
                          <FormImageThumbnail
                            // editPage={editPage}
                            name='card_image_2'
                            inputLabel='Headline Image'
                            colSize='12'
                            classNameRow="ms-2"
                            productImages={values.card_image_2}
                            handleChangeImage={handleCard2ImageAttachment}
                            handleRemoveImage={handleRemoveCard2Image}
                            setOpenFilePreview={setOpenFilePreview}
                          />
                        ) : (
                          <FormImageUpload
                            name='card_image_2'
                            colSize='12'
                            inputLabel='Headline Image'
                            handleFileInput={handleCard2ImageAttachment}
                            handleFileOnDrop={handleCard2ImagesOnDrop}
                            multiple={false}
                          />
                        )}
                      </CCol>
                    </CRow>
                  </CAccordionBody>
                </CAccordionItem>
                <CAccordionItem itemKey={3}>
                  <CAccordionHeader>
                    <h5>Card 3</h5>
                  </CAccordionHeader>
                  <CAccordionBody>
                    <CRow>
                      <CCol lg={6}>
                        <FormTextInput
                          id='card_headline_3'
                          name='card_headline_3'
                          className="mb-3"
                          colSize='12'
                          inputLabel='Headline'
                          placeholder='Enter headline here...'
                          value={values.card_headline_3}
                          handleTextInput={handleChange}
                          feedbackInvalid={errors.card_headline_3}
                          invalid={errors.card_headline_3}
                        />
                        <FormTextAreaInput
                          id='card_description_3'
                          name='card_description_3'
                          className="mb-3"
                          colSize='12'
                          inputLabel='Description'
                          placeholder='Enter highlight description here...'
                          rows='10'
                          value={values.card_description_3}
                          handleTextAreaInput={handleChange}
                          feedbackInvalid={errors.card_description_3}
                          invalid={errors.card_description_3}
                        />
                      </CCol>
                      <CCol lg={6}>
                        {values.card_image_3.length > 0 ? (
                          <FormImageThumbnail
                            // editPage={editPage}
                            name='card_image_3'
                            inputLabel='Headline Image'
                            colSize='12'
                            classNameRow="ms-2"
                            productImages={values.card_image_3}
                            handleChangeImage={handleCard3ImageAttachment}
                            handleRemoveImage={handleRemoveCard3Image}
                            setOpenFilePreview={setOpenFilePreview}
                          />
                        ) : (
                          <FormImageUpload
                            name='card_image_3'
                            colSize='12'
                            inputLabel='Headline Image'
                            handleFileInput={handleCard3ImageAttachment}
                            handleFileOnDrop={handleCard3ImagesOnDrop}
                            multiple={false}
                          />
                        )}
                      </CCol>
                    </CRow>
                  </CAccordionBody>
                </CAccordionItem>
              </CAccordion>
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={3}>
            <CAccordionHeader>
              <h5>Bottom Highlight Summary</h5>
            </CAccordionHeader>
            <CAccordionBody>
              <CRow>
                <FormTextAreaInput
                  id='bottom_highlight_description'
                  name='bottom_highlight_description'
                  className="mb-3"
                  colSize='6'
                  inputLabel='Description'
                  placeholder='Enter highlight description here...'
                  rows='10'
                  value={values.bottom_highlight_description}
                  handleTextAreaInput={handleChange}
                  feedbackInvalid={errors.bottom_highlight_description}
                  invalid={errors.bottom_highlight_description}
                />
                {values.bottom_highlight_banner.length > 0 ? (
                  <FormImageThumbnail
                    // editPage={editPage}
                    name='bottom_highlight_banner'
                    inputLabel='Banner Image'
                    colSize='6'
                    classNameRow="ms-2"
                    productImages={values.bottom_highlight_banner}
                    handleChangeImage={handleBottomHighlightImageAttachment}
                    handleRemoveImage={handleRemoveBottomHighlightImage}
                    setOpenFilePreview={setOpenFilePreview}
                  />
                ) : (
                  <FormImageUpload
                    name='bottom_highlight_banner'
                    colSize='6'
                    inputLabel='Banner Image'
                    handleFileInput={handleBottomHighlightImageAttachment}
                    handleFileOnDrop={handleBottomHighlightImagesOnDrop}
                    multiple={false}
                  />
                )}
              </CRow>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
    </>
  )
}

export default AppForm
