import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFormik } from "formik"
import * as Yup from "yup"

// components
import AppCard from 'src/components/AppCard'
import AppForm from 'src/components/form/site-manager-form/update-faq/AppForm'
import AppModalFilePreview from 'src/components/modal/AppModalFilePreview'
import AppModalConfirmation from 'src/components/modal/AppModalConfirmation'
import ActionButtonFormSecond from 'src/components/form/general-input-form/ActionButtonFormSecond'

// helpers
import Axios from 'src/helpers/axios'
import { capitalizeEachWord } from 'src/helpers/text-format'

const FAQSettings = () => {
  const FAQSetting = useSelector((state) => state.FAQSetting)
  const [FAQList, setFAQList] = useState([])
  const [inputFAQCategory, setInputFAQCategory] = useState('')
  const [openFilePreview, setOpenFilePreview] = useState(false)
  const [isLoadingMainSetting, setIsLoadingMainSetting] = useState(false)
  const [isLoadingFAQList, setIsLoadingFAQList] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState({ show: false, item: {}, type: '' })

  const displayName = FAQSetting && FAQSetting.settings && FAQSetting.settings.filter(item => item?.component_name === 'display-name')[0].content_text
  const faqHeadline = FAQSetting && FAQSetting.settings && FAQSetting.settings.filter(item => item?.component_name === 'headline')[0].content_text
  const faqMainBanner = FAQSetting && FAQSetting.settings && FAQSetting.settings.filter(item => item?.component_name === 'main-banner')[0].content_file_url

  useEffect(() => {
    fetchFAQList()
  }, []);

  const fetchFAQList = () => {
    Axios({
      method: "get",
      url: `/api/booking/v1/site-manager/faq/all`,
    })
      .then(({ data }) => {
        setFAQList(data)
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const formik1 = useFormik({
    initialValues: {
      display_name: displayName,
      headline: faqHeadline,
      faq_main_banner: faqMainBanner && faqMainBanner !== '' ? [faqMainBanner] : [],
    },
    validationSchema: Yup.object().shape({
      display_name: Yup.string().required("Please enter display name."),
      headline: Yup.string().required("Please enter headline.")
    }),
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      handleSaveMainSetting(values)
      setSubmitting(false)
    },
  });

  const updateMainSetting = async (payload) => {
    try {
      const response = await Axios.post(`/api/booking/v1/site-manager/update/9`, payload) // section_id for FAQ is 9
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const handleSaveMainSetting = async (values) => {
    const formData1 = new FormData()
    const formData2 = new FormData()
    const formData3 = new FormData()
    setIsLoadingMainSetting(true)

    if (values.display_name !== '') {
      formData1.append('component_name', 'display-name')
      formData1.append('content_text', values.display_name)
      await updateMainSetting(formData1)
    }

    if (values.headline !== '') {
      formData2.append('component_name', 'headline')
      formData2.append('content_text', values.headline)
      await handleEditMainSetting(formData2)
    }

    if (values.faq_main_banner.length > 0 && values.faq_main_banner[0] instanceof File) {
      formData3.append('component_name', 'main-banner')
      formData3.append('file', values.faq_main_banner[0])
      await handleEditMainSetting(formData3)
    }

    setIsLoadingMainSetting(false)
    window.location.reload()
  }

  const formik2 = useFormik({
    initialValues: {
      faq_category_list: FAQList?.map((category) => {
        return {
          id: category?.id,
          name: capitalizeEachWord(category?.name),
          qna_list: category?.question_list?.map((list) => {
            return {
              id: list?.id,
              question: list?.question,
              answer: list?.answer,
            }
          })
        }
      })
    },
    validationSchema: Yup.object().shape({
      faq_category_list: Yup.array().min(1, "Please enter question at least 1."),
    }),
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      handleSaveFAQList(values)
      setSubmitting(false)
    },
  });

  const createFAQCategory = async (payload) => {
    try {
      const response = await Axios.post(`/api/booking/v1/site-manager/faq/category/create`, payload)
      const { data } = response
      return data;
    } catch (error) {
      console.error(error)
    }
  }

  const createFAQList = async (payload) => {
    try {
      const response = await Axios.post(`/api/booking/v1/site-manager/faq/list/create`, payload)
      const { data } = response
      return data;
    } catch (error) {
      console.error(error)
    }
  }

  const updateFAQCategory = async (id, payload) => {
    try {
      const response = await Axios.post(`/api/booking/v1/site-manager/faq/category/update/${id}`, payload)
      const { data } = response
      return data;
    } catch (error) {
      console.error(error)
    }
  }

  const updateFAQList = async (id, payload) => {
    try {
      const response = await Axios.post(`/api/booking/v1/site-manager/faq/list/update/${id}`, payload)
      const { data } = response
      return data;
    } catch (error) {
      console.error(error)
    }
  }

  const deleteFAQCategory = async (id) => {
    try {
      const response = await Axios.delete(`/api/booking/v1/site-manager/faq/category/delete/${id}`)
      const { data } = response
      return data;
    } catch (error) {
      console.error(error)
    }
  }

  const deleteFAQList = async (id) => {
    try {
      const response = await Axios.delete(`/api/booking/v1/site-manager/faq/list/delete/${id}`)
      const { data } = response
      return data;
    } catch (error) {
      console.error(error)
    }
  }

  const handleInputFAQCategoryObject = (values) => {
    const objectPayload = {}

    objectPayload.name = values.name
    objectPayload.is_active = true

    return objectPayload
  }

  const handleInputFAQListObject = (faqCategoryId, values) => {
    const objectPayload = {}

    objectPayload.faq_category_id = faqCategoryId
    objectPayload.question = values.question
    objectPayload.answer = values.answer

    return objectPayload
  }

  const handleSaveFAQList = async (values) => {
    setIsLoadingFAQList(true)

    // update FAQ Category
    if (values.faq_category_list.length > 0) {
      for (let i = 0; i < values.faq_category_list.length; i++) {
        // input FAQ Category
        const faqCategoryPayload = handleInputFAQCategoryObject(values.faq_category_list[i])
        // check if FAQ Category has id
        let newFAQCategory;
        let updatedFAQCategory;
        if (values.faq_category_list[i].id) {
          // update FAQ Category
          updatedFAQCategory = await updateFAQCategory(values.faq_category_list[i].id, faqCategoryPayload)
        } else {
          // create FAQ Category
          newFAQCategory = await createFAQCategory(faqCategoryPayload)
        }

        // update FAQ List
        if (values.faq_category_list[i].qna_list.length > 0) {
          for (let j = 0; j < values.faq_category_list[i].qna_list.length; j++) {
            // check if FAQ List has id
            if (values.faq_category_list[i].qna_list[j].id) {
              // input product FAQ List
              const faqListPayload = handleInputFAQListObject((updatedFAQCategory && updatedFAQCategory.id), values.faq_category_list[i].qna_list[j])
              // update product FAQ List
              await updateFAQList(values.faq_category_list[i].qna_list[j].id, faqListPayload)
            } else {
              // input product FAQ List
              const faqListPayload = handleInputFAQListObject((newFAQCategory && newFAQCategory.id || updatedFAQCategory && updatedFAQCategory.id), values.faq_category_list[i].qna_list[j])
              // create product FAQ List
              await createFAQList(faqListPayload)
            }
          }
        }
      }
    }

    setIsLoadingFAQList(false)
    window.location.reload()
  }

  const handleDeleteConfirmation = async (item, type) => {
    if (type == 'faq-category') {
      await deleteFAQCategory(item.id)
    }
    if (type == 'faq-list') {
      await deleteFAQList(item.id)
    }
    window.location.reload()
  }

  // handle image for main banner
  const handleRemoveMainBannerImage = (file) => {
    formik1.setValues((values) => ({
      ...values,
      faq_main_banner: values.faq_main_banner.filter(image => image !== file)
    }), false)
  }

  const handleMainBannerImagesOnDrop = (files) => {
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik1.setValues((values) => ({
        ...values,
        faq_main_banner: attachments
      }), false)
    }
  }

  const handleMainBannerImageAttachment = (e) => {
    e.preventDefault()
    const files = e.target.files
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik1.setValues((values) => ({
        ...values,
        faq_main_banner: attachments
      }), false)
    }
  }

  // handler function faq category
  const handleInputFAQCategory = (e) => {
    if (e.key === 'Enter' && inputFAQCategory !== '') {
      formik2.setValues((values) => ({
        ...values,
        faq_category_list: [
          ...values.faq_category_list,
          ...[{
            name: capitalizeEachWord(inputFAQCategory),
            qna_list: [{
              question: '',
              answer: ''
            }]
          }]
        ]
      }), false)
      setInputFAQCategory('')
    }
    if (e.key === 'Escape') {
      setInputFAQCategory('')
    }
  }

  const handleRemoveInputFAQCategory = (category) => {
    formik2.setValues((values) => ({
      ...values,
      faq_category_list: values.faq_category_list.filter((item) => item !== category)
    }), false)
  }

  const handleAddQuestionAnswer = (category, categoryIndex) => {
    formik2.setValues((values) => {
      const newArrayFAQCategoryList = values.faq_category_list.map((item, itemIndex) => {
        if (item.name == category.name && itemIndex == categoryIndex) {
          return {
            ...item,
            qna_list: [
              ...item.qna_list,
              ...[{
                question: '',
                answer: ''
              }]
            ]
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        faq_category_list: [
          ...newArrayFAQCategoryList,
        ]
      }
    }, false);
  }

  const handleRemoveQuestionAnswer = (categoryIndex, qnaListIndex) => {
    formik2.setValues((values) => {
      const newArrayQuestionAnswers = values.faq_category_list.map((item, itemIndex) => {
        if (itemIndex == categoryIndex) {
          return {
            ...item,
            qna_list: item.qna_list.filter((element, elementIndex) => elementIndex !== qnaListIndex)
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        faq_category_list: [
          ...newArrayQuestionAnswers,
        ]
      }
    }, false);
  }

  const handleQuestionFAQCategory = (question, categoryIndex, qnaListIndex) => {
    formik2.setValues((values) => {
      const newArrayQuestionList = values.faq_category_list.map((item, itemIndex) => {
        if (itemIndex == categoryIndex) {
          return {
            ...item,
            qna_list: item.qna_list.map((element, elementIndex) => {
              if (elementIndex == qnaListIndex) {
                return {
                  ...element,
                  question: question
                }
              } else {
                return element
              }
            })
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        faq_category_list: [
          ...newArrayQuestionList,
        ]
      }
    }, false);
  }

  const handleAnswerFAQCategory = (answer, categoryIndex, qnaListIndex) => {
    formik2.setValues((values) => {
      const newArrayQuestionList = values.faq_category_list.map((item, itemIndex) => {
        if (itemIndex == categoryIndex) {
          return {
            ...item,
            qna_list: item.qna_list.map((element, elementIndex) => {
              if (elementIndex == qnaListIndex) {
                return {
                  ...element,
                  answer: answer
                }
              } else {
                return element
              }
            })
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        faq_category_list: [
          ...newArrayQuestionList,
        ]
      }
    }, false);
  }

  return (
    <>
      {openFilePreview && (
        <AppModalFilePreview
          setOpenFilePreview={setOpenFilePreview}
          file={openFilePreview}
        />
      )}
      {showConfirmDelete.show && (
        <AppModalConfirmation
          confirmationType='delete-item'
          itemName={
            showConfirmDelete.type == 'faq-category' ? 'FAQ category'
              : showConfirmDelete.type == 'faq-list' ? 'FAQ list'
                : ''
          }
          itemDetails={showConfirmDelete.item}
          itemType={showConfirmDelete.type}
          showModal={showConfirmDelete.show}
          setShowModal={setShowConfirmDelete}
          handleConfirmation={async () => await handleDeleteConfirmation(showConfirmDelete.item, showConfirmDelete.type)}
        />
      )}
      <AppCard
        className='mb-3'
        headerTitle={<h4>{displayName && displayName !== '' ? displayName : "FAQ"} Settings</h4>}
        bodyContent={
          <AppForm
            valuesFormik1={formik1.values}
            handleChangeFormik1={formik1.handleChange}
            errorsFormik1={formik1.errors}
            valuesFormik2={formik2.values}
            handleChangeFormik2={formik2.handleChange}
            errorsFormik2={formik2.errors}
            handleSubmitMainSettings={formik1.handleSubmit}
            handleSubmitFAQList={formik2.handleSubmit}
            isLoadingMainSetting={isLoadingMainSetting}
            isLoadingFAQList={isLoadingFAQList}
            displayName={displayName}
            inputFAQCategory={inputFAQCategory}
            setShowConfirmDelete={setShowConfirmDelete}
            setInputFAQCategory={setInputFAQCategory}
            setOpenFilePreview={setOpenFilePreview}
            handleRemoveMainBannerImage={handleRemoveMainBannerImage}
            handleMainBannerImagesOnDrop={handleMainBannerImagesOnDrop}
            handleMainBannerImageAttachment={handleMainBannerImageAttachment}
            handleInputFAQCategory={handleInputFAQCategory}
            handleRemoveInputFAQCategory={handleRemoveInputFAQCategory}
            handleAddQuestionAnswer={handleAddQuestionAnswer}
            handleRemoveQuestionAnswer={handleRemoveQuestionAnswer}
            handleQuestionFAQCategory={handleQuestionFAQCategory}
            handleAnswerFAQCategory={handleAnswerFAQCategory}
          />
        }
      />
    </>
  )
}

export default FAQSettings

