
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import { useNavigate } from 'react-router-dom'

// components
import {
  CRow,
  CBadge,
  CCol,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem
} from '@coreui/react-pro'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { useSelector } from "react-redux"

// helpers
import { bookingStatus } from 'src/helpers/status-badge'
import { getFullName } from 'src/helpers/text-format'
import { arrayFullCalendarData } from 'src/helpers/format-data'

const AppDetails = ({
  schedules
}) => {
  const navigate = useNavigate()

  const [arrayScheduleCalendar, setArrayScheduleCalendar] = useState([])

  useEffect(() => {
    if (schedules && schedules.length > 0) {
      const arraySchedule = arrayFullCalendarData(schedules)
      setArrayScheduleCalendar(arraySchedule)
    }
  }, [schedules]);

  const handleScheduleEvent = (id) => {
    navigate(`/bookings/details/${id}`)
    window.location.reload();
  }

  const renderEventContent = (eventInfo) => {
    return (
      <div
        className='calendar-event-slot'
        onClick={() => handleScheduleEvent(eventInfo?.event?._def?.publicId)}
      >
        <div>
          <b>{moment(eventInfo?.event?._instance?.range?.start).utc().format('HH:mm')}</b>
          &nbsp;
          <b><i>{eventInfo?.event?.title}</i></b>
        </div>
        <div>
          {`${eventInfo?.event?._def?.extendedProps?.guest}, ${eventInfo?.event?._def?.extendedProps?.total_guest} ${eventInfo?.event?._def?.extendedProps?.total_guest == 1 ? 'Guest' : 'Guests'}`}
        </div>
      </div>
    )
  }

  return (
    <FullCalendar
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      headerToolbar={{
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay',
      }}
      eventTextColor='black'
      initialView="dayGridMonth"
      editable={true}
      selectable={true}
      selectMirror={true}
      dayMaxEvents={true}
      weekends={true}
      events={arrayScheduleCalendar}
      eventContent={renderEventContent}
    // timeZone={momentTimezone.tz.guess()}
    />
  )
}

export default AppDetails
