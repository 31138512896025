import * as icon from "@coreui/icons";

export const singleOptionsSelect = (data) => {
  let options = []
  if (data && data.length > 0) {
    data.forEach((item) => {
      const id = item && item.id
      const name = item && (item.name || item.option_name)
      options.push({
        label: name,
        ...id ? { value: id } : { value: name }
      })
    })
    options = [
      {
        label: 'Please select...',
        value: ''
      },
      ...options,
    ]
  }
  return options
}

export const singleCurrencyOptionsSelect = (data) => {
  let options = []
  if (data && data.length > 0) {
    data.forEach((item) => {
      const id = item && item.id
      const code = item && item.code
      const name = item && item.name
      options.push({
        label: `${code} (${name})`,
        ...id ? { value: id } : { value: name }
      })
    })
    options = [
      {
        label: 'Please select...',
        value: ''
      },
      ...options,
    ]
  }
  return options
}

export const singleProvinceOptionsSelect = (data) => {
  let options = []
  if (data && data.length > 0) {
    data.forEach((item) => {
      const id = item && item.province_id
      const name = item && item.province
      options.push({
        label: name,
        ...id ? { value: id } : { value: name }
      })
    })
    options = [
      {
        label: 'Please select...',
        value: ''
      },
      ...options,
    ]
  }
  return options
}

export const singleCityOptionsSelect = (data) => {
  let options = []
  if (data && data.length > 0) {
    data.forEach((item) => {
      const id = item && item.city_id
      const name = item && item.city_name
      options.push({
        label: name,
        ...id ? { value: id } : { value: name }
      })
    })
    options = [
      {
        label: 'Please select...',
        value: ''
      },
      ...options,
    ]
  }
  return options
}

export const singleSubDistrictOptionsSelect = (data) => {
  let options = []
  if (data && data.length > 0) {
    data.forEach((item) => {
      const id = item && item.subdistrict_id
      const name = item && item.subdistrict_name
      options.push({
        label: name,
        ...id ? { value: id } : { value: name }
      })
    })
    options = [
      {
        label: 'Please select...',
        value: ''
      },
      ...options,
    ]
  }
  return options
}

export const singleCountriesOptionsSelect = (data) => {
  let options = []
  if (data && data.length > 0) {
    data.forEach((item) => {
      const id = item && item.id
      const name = item && item.name
      const phone_code = item && item.phone_code
      const country_code = item && item.country_code
      options.push({
        label: name,
        value: JSON.stringify({
          id: id,
          name: name,
          icon: icon[`cif${country_code}`],
          phone_code: phone_code,
        })
      })
    })
    options = [
      {
        label: 'Please select country...',
        value: {}
      },
      ...options,
    ]
  }
  return options
};

export const optionsMultiSelect = (data, selectedData) => {
  let options = []
  if (data && data.length > 0) {
    data.forEach((item) => {
      const id = item && item.id
      const name = item && (item.name || item.option_name)
      options.push({
        text: name,
        ...id ? { value: id } : { value: name },
        ...selectedData && selectedData == name ? { selected: true } : undefined
      })
    })
  }
  return options
}

export const optionsLabelMultiSelect = (data, selectedData) => {
  let options = []
  if (data && data.length > 0) {
    data.forEach((item) => {
      let labelOptions = {
        label: '',
        options: []
      }

      const id = item && item.id
      const name = item && item.name

      labelOptions.label = name

      item.options.forEach(element => {
        labelOptions.options.push({
          value: element.option_name,
          text: element.option_name,
          ...selectedData == element.option_name ? { selected: true } : undefined
        })
      });

      options.push(labelOptions)
    })
  }
  return options
}
