import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFormik } from "formik"
import * as Yup from "yup"

// components
import AppCard from 'src/components/AppCard'
import AppForm from 'src/components/form/site-manager-form/update-about-us/AppForm'
import AppModalFilePreview from 'src/components/modal/AppModalFilePreview'
import ActionButtonFormSecond from 'src/components/form/general-input-form/ActionButtonFormSecond'

// helpers
import Axios from 'src/helpers/axios'

const AboutUsSettings = () => {
  const aboutUsSetting = useSelector((state) => state.aboutUsSetting)
  const [openFilePreview, setOpenFilePreview] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const displayName = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'display-name')[0].content_text
  const aboutUsMainBanner = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'main-banner')[0].content_file_url
  const topHighlightHeadline = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'top-highlight-headline')[0].content_text
  const topHighlightTitle = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'top-highlight-title')[0].content_text
  const topHighlightDesc = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'top-highlight-description')[0].content_text
  const bottomHighlightDesc = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'bottom-highlight-description')[0].content_text
  const bottomHighlightBanner = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'bottom-highlight-banner')[0].content_file_url
  const cardHeadline1 = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'card-headline-1')[0].content_text
  const cardDesc1 = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'card-description-1')[0].content_text
  const cardImage1 = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'card-image-1')[0].content_file_url
  const cardHeadline2 = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'card-headline-2')[0].content_text
  const cardDesc2 = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'card-description-2')[0].content_text
  const cardImage2 = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'card-image-2')[0].content_file_url
  const cardHeadline3 = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'card-headline-3')[0].content_text
  const cardDesc3 = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'card-description-3')[0].content_text
  const cardImage3 = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'card-image-3')[0].content_file_url

  const formik = useFormik({
    initialValues: {
      display_name: displayName,
      about_us_main_banner: aboutUsMainBanner && aboutUsMainBanner !== '' ? [aboutUsMainBanner] : [],
      top_highlight_headline: topHighlightHeadline,
      top_highlight_title: topHighlightTitle,
      top_highlight_description: topHighlightDesc,
      bottom_highlight_description: bottomHighlightDesc,
      bottom_highlight_banner: bottomHighlightBanner && bottomHighlightBanner !== '' ? [bottomHighlightBanner] : [],
      card_headline_1: cardHeadline1,
      card_description_1: cardDesc1,
      card_image_1: cardImage1 && cardImage1 !== '' ? [cardImage1] : [],
      card_headline_2: cardHeadline2,
      card_description_2: cardDesc2,
      card_image_2: cardImage2 && cardImage2 !== '' ? [cardImage2] : [],
      card_headline_3: cardHeadline3,
      card_description_3: cardDesc3,
      card_image_3: cardImage3 && cardImage3 !== '' ? [cardImage3] : []
    },
    validationSchema: Yup.object().shape({
      display_name: Yup.string().required("Please enter home display name."),
      top_highlight_headline: Yup.string().required("Please enter highlight headline."),
      top_highlight_title: Yup.string().required("Please enter highlight title."),
      top_highlight_description: Yup.string().required("Please enter highlight description."),
      bottom_highlight_description: Yup.string().required("Please enter highlight description."),
      card_headline_1: Yup.string().required("Please enter card headline."),
      card_description_1: Yup.string().required("Please enter card description."),
      card_headline_2: Yup.string().required("Please enter card headline."),
      card_description_2: Yup.string().required("Please enter card description."),
      card_headline_3: Yup.string().required("Please enter card headline."),
      card_description_3: Yup.string().required("Please enter card description."),
    }),
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      handleSave(values)
      setSubmitting(false)
    },
  });

  const handleEdit = async (payload) => {
    try {
      const response = await Axios.post(`/api/booking/v1/site-manager/update/6`, payload) // section_id for About Us is 6
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const handleSave = async (values) => {
    const formData1 = new FormData()
    const formData2 = new FormData()
    const formData3 = new FormData()
    const formData4 = new FormData()
    const formData5 = new FormData()
    const formData6 = new FormData()
    const formData7 = new FormData()
    const formData8 = new FormData()
    const formData9 = new FormData()
    const formData10 = new FormData()
    const formData11 = new FormData()
    const formData12 = new FormData()
    const formData13 = new FormData()
    const formData14 = new FormData()
    const formData15 = new FormData()
    const formData16 = new FormData()
    setIsLoading(true)

    await Promise.all([
      (async () => {
        if (values.display_name !== '') {
          formData1.append('component_name', 'display-name')
          formData1.append('content_text', values.display_name)
          await handleEdit(formData1)
        }
      })(),
      (async () => {
        if (values.about_us_main_banner.length > 0 && values.about_us_main_banner[0] instanceof File) {
          formData2.append('component_name', 'main-banner')
          formData2.append('file', values.about_us_main_banner[0])
          await handleEdit(formData2)
        }
      })(),
      (async () => {
        if (values.top_highlight_headline !== '') {
          formData3.append('component_name', 'top-highlight-headline')
          formData3.append('content_text', values.top_highlight_headline)
          await handleEdit(formData3)
        }
      })(),
      (async () => {
        if (values.top_highlight_title !== '') {
          formData4.append('component_name', 'top-highlight-title')
          formData4.append('content_text', values.top_highlight_title)
          await handleEdit(formData4)
        }

      })(),
      (async () => {
        if (values.top_highlight_description !== '') {
          formData5.append('component_name', 'top-highlight-description')
          formData5.append('content_text', values.top_highlight_description)
          await handleEdit(formData5)
        }
      })(),
      (async () => {
        if (values.bottom_highlight_description !== '') {
          formData6.append('component_name', 'bottom-highlight-description')
          formData6.append('content_text', values.bottom_highlight_description)
          await handleEdit(formData6)
        }
      })(),
      (async () => {
        if (values.bottom_highlight_banner.length > 0 && values.bottom_highlight_banner[0] instanceof File) {
          formData7.append('component_name', 'bottom-highlight-banner')
          formData7.append('file', values.bottom_highlight_banner[0])
          await handleEdit(formData7)
        }
      })(),
      (async () => {
        if (values.card_headline_1 !== '') {
          formData8.append('component_name', 'card-headline-1')
          formData8.append('content_text', values.card_headline_1)
          await handleEdit(formData8)
        }
      })(),
      (async () => {
        if (values.card_description_1 !== '') {
          formData9.append('component_name', 'card-description-1')
          formData9.append('content_text', values.card_description_1)
          await handleEdit(formData9)
        }
      })(),
      (async () => {
        if (values.card_image_1.length > 0 && values.card_image_1[0] instanceof File) {
          formData10.append('component_name', 'card-image-1')
          formData10.append('file', values.card_image_1[0])
          await handleEdit(formData10)
        }
      })(),
      (async () => {
        if (values.card_headline_2 !== '') {
          formData11.append('component_name', 'card-headline-2')
          formData11.append('content_text', values.card_headline_2)
          await handleEdit(formData11)
        }
      })(),
      (async () => {
        if (values.card_description_2 !== '') {
          formData12.append('component_name', 'card-description-2')
          formData12.append('content_text', values.card_description_2)
          await handleEdit(formData12)
        }
      })(),
      (async () => {
        if (values.card_image_2.length > 0 && values.card_image_2[0] instanceof File) {
          formData13.append('component_name', 'card-image-2')
          formData13.append('file', values.card_image_2[0])
          await handleEdit(formData13)
        }
      })(),
      (async () => {
        if (values.card_headline_3 !== '') {
          formData14.append('component_name', 'card-headline-3')
          formData14.append('content_text', values.card_headline_3)
          await handleEdit(formData14)
        }
      })(),
      (async () => {
        if (values.card_description_3 !== '') {
          formData15.append('component_name', 'card-description-3')
          formData15.append('content_text', values.card_description_3)
          await handleEdit(formData15)
        }
      })(),
      (async () => {
        if (values.card_image_3.length > 0 && values.card_image_3[0] instanceof File) {
          formData16.append('component_name', 'card-image-3')
          formData16.append('file', values.card_image_3[0])
          await handleEdit(formData16)
        }
      })(),
    ]);

    setIsLoading(false)
    window.location.reload()
  }

  // handle image for main banner
  const handleRemoveMainBannerImage = (file) => {
    formik.setValues((values) => ({
      ...values,
      about_us_main_banner: values.about_us_main_banner.filter(image => image !== file)
    }), false)
  }

  const handleMainBannerImagesOnDrop = (files) => {
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        about_us_main_banner: attachments
      }), false)
    }
  }

  const handleMainBannerImageAttachment = (e) => {
    e.preventDefault()
    const files = e.target.files
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        about_us_main_banner: attachments
      }), false)
    }
  }

  // handle image for card 1
  const handleRemoveCard1Image = (file) => {
    formik.setValues((values) => ({
      ...values,
      card_image_1: values.card_image_1.filter(image => image !== file)
    }), false)
  }

  const handleCard1ImagesOnDrop = (files) => {
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        card_image_1: attachments
      }), false)
    }
  }

  const handleCard1ImageAttachment = (e) => {
    e.preventDefault()
    const files = e.target.files
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        card_image_1: attachments
      }), false)
    }
  }

  // handle image for card 2
  const handleRemoveCard2Image = (file) => {
    formik.setValues((values) => ({
      ...values,
      card_image_2: values.card_image_2.filter(image => image !== file)
    }), false)
  }

  const handleCard2ImagesOnDrop = (files) => {
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        card_image_2: attachments
      }), false)
    }
  }

  const handleCard2ImageAttachment = (e) => {
    e.preventDefault()
    const files = e.target.files
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        card_image_2: attachments
      }), false)
    }
  }

  // handle image for card 3
  const handleRemoveCard3Image = (file) => {
    formik.setValues((values) => ({
      ...values,
      card_image_3: values.card_image_3.filter(image => image !== file)
    }), false)
  }

  const handleCard3ImagesOnDrop = (files) => {
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        card_image_3: attachments
      }), false)
    }
  }

  const handleCard3ImageAttachment = (e) => {
    e.preventDefault()
    const files = e.target.files
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        card_image_3: attachments
      }), false)
    }
  }

  // handle image for bottom highlight
  const handleRemoveBottomHighlightImage = (file) => {
    formik.setValues((values) => ({
      ...values,
      bottom_highlight_banner: values.bottom_highlight_banner.filter(image => image !== file)
    }), false)
  }

  const handleBottomHighlightImagesOnDrop = (files) => {
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        bottom_highlight_banner: attachments
      }), false)
    }
  }

  const handleBottomHighlightImageAttachment = (e) => {
    e.preventDefault()
    const files = e.target.files
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        bottom_highlight_banner: attachments
      }), false)
    }
  }

  return (
    <>
      {openFilePreview && (
        <AppModalFilePreview
          setOpenFilePreview={setOpenFilePreview}
          file={openFilePreview}
        />
      )}
      <AppCard
        className='mb-3'
        headerTitle={<h4>{displayName && displayName !== '' ? displayName : "About Us"} Settings</h4>}
        bodyContent={
          <AppForm
            values={formik.values}
            handleChange={formik.handleChange}
            errors={formik.errors}
            setOpenFilePreview={setOpenFilePreview}
            handleRemoveMainBannerImage={handleRemoveMainBannerImage}
            handleMainBannerImagesOnDrop={handleMainBannerImagesOnDrop}
            handleMainBannerImageAttachment={handleMainBannerImageAttachment}
            handleRemoveCard1Image={handleRemoveCard1Image}
            handleCard1ImagesOnDrop={handleCard1ImagesOnDrop}
            handleCard1ImageAttachment={handleCard1ImageAttachment}
            handleRemoveCard2Image={handleRemoveCard2Image}
            handleCard2ImagesOnDrop={handleCard2ImagesOnDrop}
            handleCard2ImageAttachment={handleCard2ImageAttachment}
            handleRemoveCard3Image={handleRemoveCard3Image}
            handleCard3ImagesOnDrop={handleCard3ImagesOnDrop}
            handleCard3ImageAttachment={handleCard3ImageAttachment}
            handleRemoveBottomHighlightImage={handleRemoveBottomHighlightImage}
            handleBottomHighlightImagesOnDrop={handleBottomHighlightImagesOnDrop}
            handleBottomHighlightImageAttachment={handleBottomHighlightImageAttachment}
          />
        }
        footerContent={
          <ActionButtonFormSecond
            isLoading={isLoading}
            hiddenButtonCancel
            onSave={formik.handleSubmit}
            saveButtonText='Save and Publish'
          />
        }
      />
    </>
  )
}

export default AboutUsSettings

