import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// components
// components
import {
  CAccordion,
  CAccordionHeader,
  CAccordionBody,
  CAccordionItem,
} from '@coreui/react-pro'
import AppCard from 'src/components/AppCard'
import BusinessProfile from './business-profile/BusinessProfile'
import MainSite from './main/MainSite'
import TermsConditions from './terms-conditions/TermsConditions'
import HeaderSection from './header/HeaderSection'
import FooterSection from './footer/FooterSection'
import NewsletterSection from './newsletter/NewsletterSection'

// helpers
import Axios from 'src/helpers/axios'

const GeneralSettings = () => {

  const [mainSiteSettings, setMainSiteSettings] = useState({})
  const [headerSettings, setHeaderSettings] = useState({})
  const [footerSettings, setFooterSettings] = useState({})
  const [newsLetterSettings, setNewsLetterSettings] = useState({})
  const [termsConditionsSettings, setTermsConditionsSettings] = useState({})

  useEffect(() => {
    fetchSiteManagerSettings()
  }, []);

  const fetchSiteManagerSettings = () => {
    Axios({
      method: "get",
      url: `/api/booking/v1/site-manager/`,
    })
      .then(({ data }) => {
        setMainSiteSettings(data?.filter(item => item?.section_name === 'main-site'))
        setHeaderSettings(data?.filter(item => item?.section_name === 'header'))
        setFooterSettings(data?.filter(item => item?.section_name === 'footer'))
        setNewsLetterSettings(data?.filter(item => item?.section_name === 'newsletter'))
        setTermsConditionsSettings(data?.filter(item => item?.section_name === 'terms-and-conditions'))
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <AppCard
      className='mt-4 mb-4'
      headerTitle={<h4>General Settings</h4>}
      bodyContent={
        <>
          <CAccordion >
            <CAccordionItem itemKey={1}>
              <CAccordionHeader>
                <h5>Main Site</h5>
              </CAccordionHeader>
              <CAccordionBody>
                <MainSite
                  mainSiteSettings={mainSiteSettings}
                />
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={2}>
              <CAccordionHeader>
                <h5>Business Profile</h5>
              </CAccordionHeader>
              <CAccordionBody>
                <BusinessProfile />
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={3}>
              <CAccordionHeader>
                <h5>Terms & Conditions</h5>
              </CAccordionHeader>
              <CAccordionBody>
                <TermsConditions
                  termsConditionsSettings={termsConditionsSettings}
                />
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={4}>
              <CAccordionHeader>
                <h5>Header Section</h5>
              </CAccordionHeader>
              <CAccordionBody>
                <HeaderSection
                  headerSettings={headerSettings}
                />
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={5}>
              <CAccordionHeader>
                <h5>Footer Section</h5>
              </CAccordionHeader>
              <CAccordionBody>
                <FooterSection
                  footerSettings={footerSettings}
                />
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={6}>
              <CAccordionHeader>
                <h5>Newsletter Section</h5>
              </CAccordionHeader>
              <CAccordionBody>
                <NewsletterSection
                  newsLetterSettings={newsLetterSettings}
                />
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion>
        </>
      }
    />
  )
}

export default GeneralSettings
