import {
  CDateRangePicker,
  CMultiSelect,
  CFormSelect
} from "@coreui/react-pro";
import { removeDuplicates } from "src/helpers/remove-duplicates";

const priceOptions = [{
  value: 'Select...',
  label: 'Select...',
}, {
  value: '0 - 100000',
  label: 'IDR 0 - 100.000'
}, {
  value: '100000 - 250000',
  label: 'IDR 100.000 - 250.000'
}, {
  value: '250000 - 500000',
  label: 'IDR 250.000 - 500.000'
}, {
  value: '500000 - 1000000',
  label: 'IDR 500.000 - 1.000.000'
}, {
  value: '1000000 - 2500000',
  label: 'IDR 1.000.000 - 2.500.000'
}, {
  value: '1000000 - 2500000',
  label: 'IDR 1.000.000 - 2.500.000'
}, {
  value: '2500000 - 5000000',
  label: 'IDR 2.500.000 - 5.000.000'
}, {
  value: '5000000 - 10000000',
  label: 'IDR 5.000.000 - 10.000.000'
}, {
  value: '10000000',
  label: '> IDR 10.000.000'
}]

export const columnServiceList = (
  filterServiceName,
  filterServiceCategory,
  filterServicePrice,
  setFilterServiceName,
  setFilterServiceCategory,
  setFilterServicePrice
) => {
  return [
    {
      key: "service_uuid",
      label: "Code",
      _style: { width: '10%' },
      filter: false,
      sorter: false
    },
    {
      key: "title",
      label: "Service Name",
      _style: { width: '20%' },
      sorter: false,
      filter: (values, onChange) => {
        const unique = [...new Set(values)].sort()
        const options = unique.map((element) => {
          return {
            value: element.toLowerCase(),
            text: element,
          }
        })
        return (
          <CMultiSelect
            size="sm"
            options={
              filterServiceName && filterServiceName.length > 0 ?
                removeDuplicates(filterServiceName.concat(options)) : options
            }
            onChange={(selected) => {
              setFilterServiceName(selected)
              const _selected = selected.map((element) => {
                return element.value
              })
              onChange((item) => {
                return Array.isArray(_selected) && _selected.length
                  ? _selected.includes(item.toLowerCase())
                  : true
              })
            }}
          />
        )
      },
    },
    {
      key: "category_name",
      label: "Category",
      _style: { width: '20%' },
      sorter: false,
      filter: (values, onChange) => {
        const unique = [...new Set(values)].sort()
        const options = unique.map((element) => {
          return {
            value: element.toLowerCase(),
            text: element,
          }
        })
        return (
          <CMultiSelect
            size="sm"
            options={
              filterServiceCategory && filterServiceCategory.length > 0 ?
                removeDuplicates(filterServiceCategory.concat(options)) : options
            }
            onChange={(selected) => {
              setFilterServiceCategory(selected)
              const _selected = selected.map((element) => {
                return element.value
              })
              onChange((item) => {
                return Array.isArray(_selected) && _selected.length
                  ? _selected.includes(item.toLowerCase())
                  : true
              })
            }}
          />
        )
      },
    },
    {
      key: "price",
      label: "Price",
      _style: { width: '20%' },
      sorter: false,
      filter: (values, onChange) => {
        const unique = [...new Set(values)].sort()
        const options = unique.map((element) => {
          return {
            value: element,
            text: element,
          }
        })
        return (
          // <CMultiSelect
          //   size="sm"
          //   options={
          //     filterServicePrice && filterServicePrice.length > 0 ?
          //       removeDuplicates(filterServicePrice.concat(options)) : options
          //   }
          //   onChange={(selected) => {
          //     setFilterServicePrice(selected)
          //     const _selected = selected.map((element) => {
          //       return element.value
          //     })
          //     onChange((item) => {
          //       return Array.isArray(_selected) && _selected.length
          //         ? _selected.includes(item.toLowerCase())
          //         : true
          //     })
          //   }}
          // />
          <CFormSelect
            size="sm"
            options={priceOptions}
            value={filterServicePrice}
            onChange={({ target: { value } }) => {
              setFilterServicePrice(value)
              onChange((item) => {
                if (value !== 'Select...') {
                  const itemPrice = parseInt(item)
                  const itemValue = value.split(" - ")
                  if (itemValue[1]) {
                    return itemPrice >= parseInt(itemValue[0]) && itemPrice <= parseInt(itemValue[1])
                  } else {
                    return itemPrice > parseInt(itemValue[0])
                  }
                } else {
                  return true
                }
              })
            }}
          />
        )
      },
    },
    {
      key: "createdAt",
      label: "Created On",
      _style: { width: '10%' },
      filter: false,
      sorter: true
    },
    {
      key: "action",
      label: "",
      _style: { width: '15%' },
      filter: false,
      sorter: false
    },
  ];
}

export const columnCategoryList = (
  startDate,
  endDate,
  setStartDate,
  setEndDate
) => {
  return [
    {
      key: "name",
      label: "Category",
      _style: { width: '15%' },
      filter: false,
      sorter: false
    },
    {
      key: "description",
      label: "Description",
      _style: { width: '30%' },
      filter: false,
      sorter: false
    },
    {
      key: "services_count",
      label: "Under This Category",
      _style: { width: '15%' },
      filter: false,
      sorter: false,
    },
    {
      key: "createdAt",
      label: "Created On",
      _style: { width: '20%' },
      sorter: true,
      filter: (values, onChange) => {
        return (
          <CDateRangePicker
            size="sm"
            startDate={startDate}
            endDate={endDate}
            // maxDate={new Date()}
            onStartDateChange={(date) => {
              setStartDate(date)
              onChange((item) => {
                if (date) {
                  const itemDate = new Date(Date.parse(item))
                  return endDate ? itemDate >= date && itemDate <= endDate : itemDate >= date
                }
                return true
              })
            }}
            onEndDateChange={(date) => {
              setEndDate(date)
              onChange((item) => {
                if (date) {
                  const itemDate = new Date(Date.parse(item))
                  return startDate ? itemDate <= date && itemDate >= startDate : itemDate <= date
                }
                return true
              })
            }}
          />
        )
      },
    },
    {
      key: "action",
      label: "",
      _style: { width: '13%' },
      filter: false,
      sorter: false,
    },
  ];
}

export const columnBookingList = (
  startDate,
  endDate,
  startBookingDate,
  endBookingDate,
  filterBookingStatus,
  filterBookingAmount,
  filterPaymentMethod,
  filterServicePackage,
  setStartDate,
  setEndDate,
  setStartBookingDate,
  setEndBookingDate,
  setFilterBookingStatus,
  setFilterBookingAmount,
  setFilterPaymentMethod,
  setFilterServicePackage
) => {
  return [
    {
      key: "booking_uuid",
      label: "Booking No.",
      _style: { width: '7%' },
      filter: false,
      sorter: false,
    },
    {
      key: "customer_name",
      label: "Customer Name",
      _style: { width: '10%' },
      filter: false,
      sorter: false
    },
    {
      key: "service_variant",
      label: "Service Package",
      _style: { width: '13%' },
      sorter: false,
      filter: (values, onChange) => {
        const unique = [...new Set(values)].sort()
        const options = unique.map((element) => {
          return {
            value: element.toLowerCase(),
            text: element,
          }
        })
        return (
          <CMultiSelect
            size="sm"
            options={
              filterServicePackage && filterServicePackage.length > 0 ?
                removeDuplicates(filterServicePackage.concat(options)) : options
            }
            onChange={(selected) => {
              setFilterServicePackage(selected)
              const _selected = selected.map((element) => {
                return element.value
              })
              onChange((item) => {
                return Array.isArray(_selected) && _selected.length
                  ? _selected.includes(item.toLowerCase())
                  : true
              })
            }}
          />
        )
      },
    },
    {
      key: "payment_method",
      label: "Payment Method",
      _style: { width: '13%' },
      sorter: false,
      filter: (values, onChange) => {
        const unique = [...new Set(values)].sort()
        const options = unique.map((element) => {
          return {
            value: element.toLowerCase(),
            text: element,
          }
        })
        return (
          <CMultiSelect
            size="sm"
            options={
              filterPaymentMethod && filterPaymentMethod.length > 0 ?
                removeDuplicates(filterPaymentMethod.concat(options)) : options
            }
            onChange={(selected) => {
              setFilterPaymentMethod(selected)
              const _selected = selected.map((element) => {
                return element.value
              })
              onChange((item) => {
                return Array.isArray(_selected) && _selected.length
                  ? _selected.includes(item.toLowerCase())
                  : true
              })
            }}
          />
        )
      },
    },
    {
      key: "booking_amount",
      label: "Total Amount",
      _style: { width: '13%' },
      sorter: false,
      filter: (values, onChange) => {
        const unique = [...new Set(values)].sort()
        const options = unique.map((element) => {
          return {
            value: element,
            text: element,
          }
        })
        return (
          // <CMultiSelect
          //   size="sm"
          //   options={
          //     filterBookingAmount && filterBookingAmount.length > 0 ?
          //       removeDuplicates(filterBookingAmount.concat(options)) : options
          //   }
          //   onChange={(selected) => {
          //     setFilterBookingAmount(selected)
          //     const _selected = selected.map((element) => {
          //       return element.value
          //     })
          //     onChange((item) => {
          //       return Array.isArray(_selected) && _selected.length
          //         ? _selected.includes(item)
          //         : true
          //     })
          //   }}
          // />
          <CFormSelect
            size="sm"
            options={priceOptions}
            value={filterBookingAmount}
            onChange={({ target: { value } }) => {
              setFilterBookingAmount(value)
              onChange((item) => {
                if (value !== 'Select...') {
                  const itemPrice = parseInt(item)
                  const itemValue = value.split(" - ")
                  if (itemValue[1]) {
                    return itemPrice >= parseInt(itemValue[0]) && itemPrice <= parseInt(itemValue[1])
                  } else {
                    return itemPrice > parseInt(itemValue[0])
                  }
                } else {
                  return true
                }
              })
            }}
          />
        )
      },
    },
    {
      key: "booking_date",
      label: "Booking Date",
      _style: { width: '13%' },
      sorter: false,
      filter: (values, onChange) => {
        return (
          <CDateRangePicker
            size="sm"
            startDate={startBookingDate}
            endDate={endBookingDate}
            // maxDate={new Date()}
            onStartDateChange={(date) => {
              setStartBookingDate(date)
              onChange((item) => {
                if (date) {
                  const itemDate = new Date(Date.parse(item))
                  return endBookingDate ? itemDate >= date && itemDate <= endBookingDate : itemDate >= date
                }
                return true
              })
            }}
            onEndDateChange={(date) => {
              setEndBookingDate(date)
              onChange((item) => {
                if (date) {
                  const itemDate = new Date(Date.parse(item))
                  return startBookingDate ? itemDate <= date && itemDate >= startBookingDate : itemDate <= date
                }
                return true
              })
            }}
          />
        )
      },
    },
    {
      key: "createdAt",
      label: "Purchase On",
      _style: { width: '13%' },
      sorter: true,
      filter: (values, onChange) => {
        return (
          <CDateRangePicker
            size="sm"
            startDate={startDate}
            endDate={endDate}
            // maxDate={new Date()}
            onStartDateChange={(date) => {
              setStartDate(date)
              onChange((item) => {
                if (date) {
                  const itemDate = new Date(Date.parse(item))
                  return endDate ? itemDate >= date && itemDate <= endDate : itemDate >= date
                }
                return true
              })
            }}
            onEndDateChange={(date) => {
              setEndDate(date)
              onChange((item) => {
                if (date) {
                  const itemDate = new Date(Date.parse(item))
                  return startDate ? itemDate <= date && itemDate >= startDate : itemDate <= date
                }
                return true
              })
            }}
          />
        )
      },
    },
    {
      key: "status",
      label: "Status",
      _style: { width: '10%' },
      sorter: false,
      filter: (values, onChange) => {
        const unique = [...new Set(values)].sort()
        const options = unique.map((element) => {
          return {
            value: element,
            text: element,
          }
        })
        return (
          <CMultiSelect
            size="sm"
            options={
              filterBookingStatus && filterBookingStatus.length > 0 ?
                removeDuplicates(filterBookingStatus.concat(options)) : options
            }
            onChange={(selected) => {
              setFilterBookingStatus(selected)
              const _selected = selected.map((element) => {
                return element.value
              })
              onChange((item) => {
                return Array.isArray(_selected) && _selected.length
                  ? _selected.includes(item)
                  : true
              })
            }}
          />
        )
      },
    },
    {
      key: "action",
      label: "",
      _style: { width: '12%' },
      filter: false,
      sorter: false
    },
  ]
};

export const columnCustomerList = () => {
  return [
    {
      key: "customer_id",
      label: "Customer ID",
      filter: false,
      sorter: false,
    },
    {
      key: "first_name",
      label: "First Name",
      filter: false,
      sorter: true
    },
    {
      key: "last_name",
      label: "Last Name",
      filter: false,
      sorter: true
    },
    {
      key: "email",
      label: "Email Address",
      filter: false,
      sorter: false
    },
    {
      key: "first_booking_on",
      label: "First Booking On",
      filter: false,
      sorter: true
    },
    {
      key: "action",
      label: "",
      filter: false,
      sorter: false
    },
  ]
};

export const columnNewsletterSubscription = () => {
  return [
    {
      key: "email",
      label: "Email",
      filter: false,
      sorter: false
    },
    {
      key: "createdAt",
      label: "Created On",
      sorter: false,
      filter: false
      // filter: (values, onChange) => {
      //   return (
      //     <CDateRangePicker
      //       size="sm"
      //       startDate={startDate}
      //       endDate={endDate}
      //       // maxDate={new Date()}
      //       onStartDateChange={(date) => {
      //         setStartDate(date)
      //         onChange((item) => {
      //           if (date) {
      //             const itemDate = new Date(Date.parse(item))
      //             return endDate ? itemDate >= date && itemDate <= endDate : itemDate >= date
      //           }
      //           return true
      //         })
      //       }}
      //       onEndDateChange={(date) => {
      //         setEndDate(date)
      //         onChange((item) => {
      //           if (date) {
      //             const itemDate = new Date(Date.parse(item))
      //             return startDate ? itemDate <= date && itemDate >= startDate : itemDate <= date
      //           }
      //           return true
      //         })
      //       }}
      //     />
      //   )
      // },
    },
  ];
}
