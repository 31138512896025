import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFormik } from "formik"
import * as Yup from "yup"
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { useSelector } from "react-redux"

// components
import AppCard from 'src/components/AppCard'
import AppForm from 'src/components/form/site-manager-form/update-general-settings/update-business-profile/AppForm'
import AppModalFilePreview from 'src/components/modal/AppModalFilePreview'
import ActionButtonFormSecond from 'src/components/form/general-input-form/ActionButtonFormSecond'

// helpers
import Axios from 'src/helpers/axios'
import {
  singleOptionsSelect,
  singleCountriesOptionsSelect,
  singleCurrencyOptionsSelect,
  singleProvinceOptionsSelect,
  singleCityOptionsSelect,
  singleSubDistrictOptionsSelect
} from 'src/helpers/format-options'

const BusinessProfile = () => {
  const generalSettingDetails = useSelector((state) => state.generalSettings)

  const [countryOptions, setCountryOptions] = useState([])
  const [provinceOptions, setProvinceOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const [subDistrictOptions, setSubDistrictOptions] = useState([])
  const [currencyOptions, setCurrencyOptions] = useState([])
  const [industryOptions, setIndustryOptions] = useState([])
  const [phoneCountry, setPhoneCountry] = useState({})
  const [openFilePreview, setOpenFilePreview] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      store_name: generalSettingDetails?.shop_name,
      store_established_date: moment(generalSettingDetails?.established_date).format('l'),
      store_category_id: generalSettingDetails?.shop_industry_id,
      store_person_name: generalSettingDetails?.contact_person_name,
      store_person_number: generalSettingDetails?.contact_person_number,
      store_person_email: generalSettingDetails?.contact_person_email,
      store_address: generalSettingDetails?.address_details,
      store_postal_code: generalSettingDetails?.postal_code,
      store_currency_id: generalSettingDetails?.shop_currency_id,
      store_country_id: generalSettingDetails?.country_id,
      store_province_id: generalSettingDetails?.province_id,
      store_city_id: generalSettingDetails?.city_id,
      store_subdistrict_id: generalSettingDetails?.subdistrict_id,
    },
    validationSchema: Yup.object().shape({
      store_name: Yup.string().required("Please enter business name."),
      store_established_date: Yup.mixed().required("Please business established date."),
      store_category_id: Yup.mixed().required("Please enter business category."),
      store_person_name: Yup.string().required("Please enter business person name."),
      store_person_number: Yup.mixed().required("Please enter business person number."),
      store_person_email: Yup.string().required("Please enter business email.").email("Invalid email format"),
      store_address: Yup.string().required("Please enter business address."),
      store_postal_code: Yup.string().required("Please enter business postal code."),
      store_currency_id: Yup.mixed().required("Please enter business currency."),
      store_country_id: Yup.mixed().required("Please enter business country."),
      store_province_id: Yup.mixed().required("Please enter business province."),
      store_city_id: Yup.mixed().required("Please enter business city."),
      store_subdistrict_id: Yup.mixed().required("Please enter business subdistrict."),
    }),
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      handleSave(values)
      setSubmitting(false)
    },
  });

  useEffect(() => {
    if (countryOptions.length === 0) {
      fetchAllCountries()
    }
    if (provinceOptions.length === 0) {
      fetchAllProvinces()
    }
    if (currencyOptions.length === 0) {
      fetchAllCurrencies()
    }
    if (industryOptions.length === 0) {
      fetchAllIndustries()
    }
  }, [
    countryOptions,
    provinceOptions,
    currencyOptions,
    industryOptions
  ]);

  useEffect(() => {
    if (formik.values.store_province_id) {
      fetchAllCities(formik.values.store_province_id)
    }
    if (formik.values.store_city_id) {
      fetchAllSubDistricts(formik.values.store_city_id)
    }
    if (formik.values.store_country_id) {
      fetchCountryDetails(formik.values.store_country_id)
    }
  }, [
    formik.values.store_province_id,
    formik.values.store_city_id,
    formik.values.store_country_id
  ]);

  const fetchAllCurrencies = () => {
    Axios({
      method: "get",
      url: `/api/booking/v1/currencies`,
    })
      .then(({ data }) => {
        setCurrencyOptions(singleCurrencyOptionsSelect(data))
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const fetchAllCountries = () => {
    Axios({
      method: "get",
      url: "/api/booking/v1/countries",
    })
      .then(({ data }) => {
        setCountryOptions(singleOptionsSelect(data))
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchCountryDetails = (id) => {
    Axios({
      method: "get",
      url: `/api/booking/v1/countries`,
    })
      .then(({ data }) => {
        const selectedCountry = data?.filter((country) => { return country?.id == id })
        setPhoneCountry(selectedCountry[0])
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const fetchAllProvinces = () => {
    Axios({
      method: "get",
      url: "/api/booking/v1/countries/provinces",
    })
      .then(({ data }) => {
        setProvinceOptions(singleProvinceOptionsSelect(data))
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchAllCities = (provinceId) => {
    Axios({
      method: "get",
      url: `/api/booking/v1/countries/cities/${provinceId}`,
    })
      .then(({ data }) => {
        setCityOptions(singleCityOptionsSelect(data))
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchAllSubDistricts = (cityId) => {
    Axios({
      method: "get",
      url: `/api/booking/v1/countries/subdistricts/${cityId}`,
    })
      .then(({ data }) => {
        setSubDistrictOptions(singleSubDistrictOptionsSelect(data))
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchAllIndustries = () => {
    Axios({
      method: "get",
      url: "/api/booking/v1/industries/all",
    })
      .then(({ data }) => {
        setIndustryOptions(singleOptionsSelect(data))
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleEdit = (payload) => {
    setIsLoading(true)
    Axios({
      method: "post",
      url: `/api/booking/v1/settings/update`,
      data: payload
    })
      .then(({ data }) => {
        window.location.reload()
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoading(false))
  }

  const handleInputValue = (values) => {
    const objectPayload = {}

    objectPayload.shop_name = values.store_name
    objectPayload.established_date = values.store_established_date
    objectPayload.shop_industry_id = values.store_category_id
    objectPayload.contact_person_name = values.store_person_name
    objectPayload.contact_person_number = values.store_person_number
    objectPayload.contact_person_email = values.store_person_email
    objectPayload.address_details = values.store_address
    objectPayload.postal_code = values.store_postal_code
    objectPayload.shop_currency_id = parseInt(values.store_currency_id)
    objectPayload.country_id = parseInt(values.store_country_id)
    objectPayload.province_id = parseInt(values.store_province_id)
    objectPayload.city_id = parseInt(values.store_city_id)
    objectPayload.subdistrict_id = parseInt(values.store_subdistrict_id)

    return objectPayload
  }

  const handleSave = (values) => {
    const inputPayload = handleInputValue(values)
    handleEdit(inputPayload)
  }

  const handleSelectedDate = (date) => {
    formik.setValues((values) => ({
      ...values,
      store_established_date: date
    }), false)
  }

  return (
    <>
      {openFilePreview && (
        <AppModalFilePreview
          setOpenFilePreview={setOpenFilePreview}
          file={openFilePreview}
        />
      )}
      <AppCard
        className='mb-2'
        // headerTitle={<h4>Business Profile</h4>}
        bodyContent={
          <AppForm
            values={formik.values}
            handleChange={formik.handleChange}
            errors={formik.errors}
            handleSelectedDate={handleSelectedDate}
            currencyOptions={currencyOptions}
            countryOptions={countryOptions}
            provinceOptions={provinceOptions}
            cityOptions={cityOptions}
            subDistrictOptions={subDistrictOptions}
            industryOptions={industryOptions}
            phoneCountry={phoneCountry}
            setOpenFilePreview={setOpenFilePreview}
          />
        }
        footerContent={
          <ActionButtonFormSecond
            isLoading={isLoading}
            hiddenButtonCancel
            onSave={formik.handleSubmit}
            saveButtonText='Save and Publish'
          />
        }
      />
    </>
  )
}

export default BusinessProfile

