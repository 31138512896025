import React from 'react'
import moment from 'moment'
import { useSelector } from "react-redux"

// components
import {
  CSmartTable,
  CBadge,
  CButtonGroup,
  CButton,
  CImage,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CTooltip,
  CSmartPagination,
} from '@coreui/react-pro'

// icons
import ThreeDotsIconMenu from '../assets/icons/ThreeDotsOptionsIcon.png'

// styling
import { styleButtonVars5 } from './custom-styles/buttonStyles'

// helpers
import { productStatus } from 'src/helpers/status-badge'
import { bookingStatus } from 'src/helpers/status-badge'
import formatCurrency from 'src/helpers/format-currency'

const AppTable = ({
  serviceListPage,
  categoryListPage,
  bookingListPage,
  customerListPage,
  setShowConfirmDelete,
  setShowConfirmCancel,
  selectable,
  tableColumns,
  tableContent,
  contentPerPage,
  activeContentPage,
  contentCount,
  setActiveContentPage,
  tableFilter,
  columnFilter,
  searchContentFilter,
  setSearchContentFilter,
  setContentPerPage,
  columnContentSorter,
  setColumnContentSorter,
  startDateColumnFilter,
  endDateColumnFilter,
  startBookingDateColumnFilter,
  endBookingDateColumnFilter,
  handleSelectedItemChange,
}) => {
  const currency = useSelector((state) => state.currency)

  return (
    <>
      <CSmartTable
        columns={tableColumns}
        columnSorter={{
          external: true,
          resetable: true
        }}
        sorterValue={columnContentSorter}
        onSorterChange={(sorter) => setColumnContentSorter(sorter)}
        columnFilter={columnFilter}
        // columnFilterValue={{
        //   createdAt: (date) => (
        //     new Date(Date.parse(date)) >= startDateColumnFilter && new Date(Date.parse(date)) <= endDateColumnFilter
        //   ),
        //   booking_date: (date) => (
        //     new Date(Date.parse(date)) >= startBookingDateColumnFilter && new Date(Date.parse(date)) <= endBookingDateColumnFilter
        //   )
        // }}
        items={tableContent}
        itemsPerPage={contentPerPage}
        itemsPerPageSelect
        onItemsPerPageChange={(itemsPerPage) => setContentPerPage(itemsPerPage)}
        // pagination={{
        //   external: true,
        // }}
        // paginationProps={{
        //   align: 'center',
        //   activePage: activeContentPage,
        //   pages: Math.ceil(contentCount / contentPerPage) || 1,
        // }}
        // onActivePageChange={(activePage) => setActiveContentPage(activePage)}
        tableProps={{
          className: 'app-table-content-list',
          hover: true,
          striped: true,
          responsive: true,
          style: {
            cursor: 'pointer',
            width: bookingListPage ? '2000px' : null
          }
        }}
        tableHeadProps={{
          className: 'app-table-header'
        }}
        selectable={selectable}
        onSelectedItemsChange={(item) => handleSelectedItemChange(item)}
        // clickableRows
        onRowClick={(item) => handleOnRowClick(item)}
        tableFilter={tableFilter}
        tableFilterValue={searchContentFilter}
        tableFilterPlaceholder='Search...'
        tableFilterLabel='Search:'
        onTableFilterChange={(filter) => setSearchContentFilter(filter)}
        scopedColumns={{
          product_main_image: (item) => (
            <td>
              {item?.product_main_image ? <CImage fluid rounded src={item?.product_main_image} height={130} width={80} /> : 'Not Available'}
            </td>
          ),
          status: (item) => (
            <td>
              <CBadge className='table-badge-status-content' color={bookingStatus(item?.status)} shape="rounded-pill">
                {item?.status?.toString().toUpperCase()}
              </CBadge>
            </td>
          ),
          payment_status: (item) => (
            <td>
              <CBadge className='table-badge-status-content' color={bookingStatus(item?.payment_status)} shape="rounded-pill">
                {item?.payment_status?.toString().toUpperCase()}
              </CBadge>
            </td>
          ),
          createdAt: (item) => (
            <td>
              {moment(item?.createdAt).format('DD/MM/YYYY')}
            </td>
          ),
          transaction_date: (item) => (
            <td>
              {moment(item?.createdAt).format('DD/MM/YYYY HH:mm')}
            </td>
          ),
          description: (item) => (
            <td>
              {item?.description?.length > 70 ? `${item?.description?.slice(0, 70)}...` : item?.description}
            </td>
          ),
          booking_date: (item) => (
            <td>
              {item?.booking_date ? `${moment(item?.booking_date).format('l')} - ${moment(item?.booking_date).format('LT')}` : '-'}
            </td>
          ),
          booking_amount: (item) => (
            <td>
              {item?.booking_amount && item?.booking_amount !== '-' ? `${formatCurrency(item?.booking_amount)}` : '-'}
            </td>
          ),
          price: (item) => (
            <td>
              {item?.price && item?.price !== '-' ? `${formatCurrency(item?.price)}` : '-'}
            </td>
          ),
          first_booking_on: (item) => (
            <td>
              {moment(item?.first_booking_on).format('l')}
            </td>
          ),
          action: (item) => (
            <td>
              <CDropdown>
                <CButton
                  style={styleButtonVars5}
                  href={
                    serviceListPage ? `/services/details/${item.id}`
                      : categoryListPage ? `/category/details/${item.id}`
                        : bookingListPage ? `/bookings/details/${item.id}`
                          : customerListPage ? `/customers/details/${item.id}`
                            : '#'
                  }
                >
                  View
                </CButton>
                <CDropdownToggle
                  color="white"
                  caret={false}
                  style={{
                    border: "none",
                    boxShadow: "none",
                    background: "#FFF",
                    // borderRadius: ".5rem",
                    borderBottomRightRadius: ".3rem",
                    borderTopRightRadius: ".3rem",
                  }}
                  split
                  hidden={customerListPage}
                >
                  <CImage fluid src={ThreeDotsIconMenu} />
                </CDropdownToggle>
                <CDropdownMenu>
                  {(serviceListPage || categoryListPage) && (
                    <CDropdownItem
                      href={
                        serviceListPage ? `/services/edit/${item.id}`
                          : categoryListPage ? `/category/edit/${item.id}`
                            : '#'
                      }
                    >
                      Edit {
                        serviceListPage ? 'Service'
                          : categoryListPage ? 'Category'
                            : ''
                      }
                    </CDropdownItem>
                  )}
                  {(serviceListPage || categoryListPage) && (
                    <CDropdownItem
                      onClick={() => setShowConfirmDelete({ show: true, item: item })}
                    >
                      Delete {
                        serviceListPage ? 'Service'
                          : categoryListPage ? 'Category'
                            : ''
                      }
                    </CDropdownItem>
                  )}
                  {/* {serviceListPage && (
                    <CDropdownItem
                      onClick={() => console.log('Duplicate')}
                    >
                      Duplicate Service
                    </CDropdownItem>
                  )} */}
                  {bookingListPage && (
                    <>
                      {
                        item?.transaction_details &&
                        item?.transaction_details?.length > 0 &&
                        item?.transaction_details[0]?.invoice &&
                        item?.transaction_details[0]?.invoice?.file_url &&
                        item?.transaction_details[0]?.invoice?.file_url !== '' && (
                          <CDropdownItem
                            href={item?.transaction_details[0]?.invoice?.file_url}
                            download={`INVOICE ${item?.transaction_details[0]?.transaction_id}`}
                          >
                            Download Receipt
                          </CDropdownItem>
                        )
                      }
                      {item?.status === "confirmed" && item?.transaction_details && item?.transaction_details?.length > 0 && item?.transaction_details[0]?.payment_status === "success" && (
                        <CDropdownItem
                          onClick={() => setShowConfirmCancel({ show: true, item: item })}
                        >
                          Cancel Booking
                        </CDropdownItem>
                      )}
                    </>
                  )}
                </CDropdownMenu>
              </CDropdown>
            </td>
          )
        }}
      />
      <CSmartPagination
        align="center"
        activePage={activeContentPage}
        pages={Math.ceil(contentCount / contentPerPage) || 1}
        onActivePageChange={(activePage) => setActiveContentPage(activePage)}
        p
      />
    </>
  )
}

export default AppTable
