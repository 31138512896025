export const capitalizeFirstLetter = (string) => {
  return string
    .charAt(0)
    .toUpperCase() + string
      .slice(1)
      .toLowerCase();
}

export const capitalizeEachWord = (phrase) => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const getFullName = (profile) => {
  return (profile?.first_name ? profile?.first_name : "") + " " + (profile?.last_name ? profile?.last_name : "");
}

export const random = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const couponTypeText = (status) => {
  switch (status) {
    case 'fixed-amount':
      return 'Fixed Amount'
    case 'percentage':
      return 'Percentage'
    case 'free-shipping':
      return 'Free Shipping'
    case 'buy-one-get-one':
      return 'Buy 1 Get 1'
    default:
      return ''
  }
}
