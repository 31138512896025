import React, { useState } from 'react'

// components
import {
  CCol,
  CRow,
  CFormLabel,
  CFormInput,
  CFormCheck,
  CFormText,
  CAccordion,
  CAccordionHeader,
  CAccordionBody,
  CAccordionItem,
  CButton,
  CInputGroup,
  CInputGroupText,
  CImage
} from '@coreui/react-pro'
import FormProductVariant from '../general-input-form/FormProductVariant';

// icons
import TrashIcon from '../../../assets/icons/TrashIcon.png'

// styling
import { styleButtonVars3 } from 'src/components/custom-styles/buttonStyles';
import { styleInputVariantInfo1 } from 'src/components/custom-styles/inputStyles';

// helpers
import { capitalizeEachWord } from 'src/helpers/text-format';

const AppSecondForm = ({
  editPage,
  detailsPage,
  values,
  errors,
  isServiceVariantEmpty,
  setInputVariant,
  setShowConfirmDelete,
  inputVariant,
  handleInputVariantType,
  handleRemoveInputVariantType,
  handleAddVariantOptions,
  handleRemoveVariantOptions,
  handleTextVariantOptions,
  handlePriceVariantOptions,
  handleSalePriceVariantOptions,
  handleQuotaVariantOptions,
  setOpenFilePreview,
}) => {

  return (
    <>
      <CRow className="mb-1">
        <CCol lg='12'>
          <CFormLabel>
            Service Variant
          </CFormLabel>
          {values.service_variants.length === 0 && (
            <>
              <CInputGroup>
                <CFormInput
                  placeholder='Fill in service variant...'
                  value={inputVariant}
                  onChange={({ target: { value } }) => setInputVariant(value)}
                  onKeyDown={(e) => handleInputVariantType(e)}
                  disabled={detailsPage}
                />
                <CInputGroupText>
                  <CImage fluid src={TrashIcon} onClick={() => setInputVariant('')} />
                </CInputGroupText>
              </CInputGroup>
              <p className='mt-2' style={styleInputVariantInfo1}>
                Click <b>'Enter'</b> to Add or <b>'Esc'</b> to Cancel
              </p>
            </>
          )}
        </CCol>
        {isServiceVariantEmpty && (
          <CFormText className='error-text-field'>
            Please fill out all of the fields for option name and option price.
          </CFormText>
        )}
      </CRow>
      <CRow className="mb-4">
        <CCol lg='12'>
          {values.service_variants.length > 0 && (
            <CAccordion>
              {values.service_variants.map((variant, index) => {
                return (
                  <CAccordionItem itemKey={index} key={index} >
                    <CAccordionHeader>
                      <b>{variant.name}</b>
                      <CButton
                        className='ms-3'
                        style={styleButtonVars3}
                        onClick={() => {
                          variant.variant_id ?
                            setShowConfirmDelete({ show: true, item: variant, type: 'product-variant-type' })
                            : handleRemoveInputVariantType(variant)
                        }}
                        hidden={detailsPage || editPage}
                      >
                        <CImage
                          fluid
                          src={TrashIcon}
                          className='me-2 mb-1'
                        />
                        Remove
                      </CButton>
                    </CAccordionHeader>
                    <CAccordionBody>
                      <FormProductVariant
                        formProduct
                        editPage={editPage}
                        detailsPage={detailsPage}
                        variantType={variant}
                        variantIndex={index}
                        values={values}
                        errors={errors}
                        inputLabel='Variant Options'
                        placeholder='Fill in variant option...'
                        setOpenFilePreview={setOpenFilePreview}
                        setShowConfirmDelete={setShowConfirmDelete}
                        handleAddVariantOptions={handleAddVariantOptions}
                        handleRemoveVariantOptions={handleRemoveVariantOptions}
                        handleTextVariantOptions={handleTextVariantOptions}
                        handlePriceVariantOptions={handlePriceVariantOptions}
                        handleSalePriceVariantOptions={handleSalePriceVariantOptions}
                        handleQuotaVariantOptions={handleQuotaVariantOptions}
                      />
                    </CAccordionBody>
                  </CAccordionItem>
                )
              })}
            </CAccordion>
          )}
        </CCol>
      </CRow>
    </>
  )
}

export default AppSecondForm
