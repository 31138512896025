import React, { useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik"
import * as Yup from "yup"
import { useNavigate } from 'react-router-dom'

// components
import { Link } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSpinner,
  CImage
} from '@coreui/react-pro'

// icons
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import CloudxierLogo from 'src/assets/icons/CloudxierLogoInWhite.png'

// helpers
import Axios from 'src/helpers/axios'

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const user = useSelector((state) => state.user)

  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Please enter your email address."),
      password: Yup.string().required("Please enter your password.")
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      handleSubmit(values)
      setSubmitting(false)
    },
  });

  const handleInputValue = (values) => {
    const payload = {}

    payload.email = values.email
    payload.password = values.password

    return payload
  }

  const handleLogin = (payload) => {
    setIsLoading(true)
    Axios({
      method: "post",
      url: '/api/sso/v1/users/login',
      data: payload
    })
      .then(({ data }) => {
        if (data) {
          dispatch({ type: "SET_USER", user: data });
          navigate('/')
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoading(false))
  }

  const handleSubmit = (values) => {
    const inputPayload = handleInputValue(values)
    handleLogin(inputPayload)
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">Sign In To Your Account</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        type='email'
                        id='email'
                        name='email'
                        placeholder="Email Address"
                        value={formik.values.email}
                        onChange={(e) => formik.handleChange(e)}
                        invalid={formik.errors.email}
                        feedbackInvalid={formik.errors.email}
                        autoComplete='off'
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        id='password'
                        name='password'
                        value={formik.values.password}
                        onChange={(e) => formik.handleChange(e)}
                        invalid={formik.errors.password}
                        feedbackInvalid={formik.errors.password}
                        autoComplete='off'
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <CButton
                          color="dark"
                          className="px-4"
                          onClick={() => formik.handleSubmit()}
                        >
                          {isLoading && (
                            <CSpinner
                              className='me-2'
                              component="span"
                              color="light"
                              size="sm"
                              aria-hidden="true"
                            />
                          )}
                          Login
                        </CButton>
                      </CCol>
                      {/* <CCol xs={6} className="text-right">
                        <CButton color="link" className="px-0">
                          Forgot password?
                        </CButton>
                      </CCol> */}
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard className="text-white bg-dark py-5" style={{ width: '44%' }}>
                <CCardBody className="text-center">
                  <div>
                    <CImage width={300} className='mb-5' fluid src={CloudxierLogo} />
                    <p>
                      Don't have an account?
                    </p>
                    <Link to="/register">
                      <CButton color="dark" active tabIndex={-1}>
                        Register Now!
                      </CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
