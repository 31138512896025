import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux"

// components
import { CButton, CImage } from '@coreui/react-pro'
import AppCard from 'src/components/AppCard.js'
import AppTable from 'src/components/AppTable.js'
import AppModalConfirmation from 'src/components/modal/AppModalConfirmation'
import AppCardHeader from 'src/components/card/AppCardHeader'

// icons
import AddMoreIconWhite from '../../assets/icons/AddMoreIconWhite.png'
import Shirt1 from '../../assets/images/Shirt1.png'

// styling
import { styleButtonVars2 } from 'src/components/custom-styles/buttonStyles'

// helpers
import { columnServiceList } from 'src/components/table-column/TableColumnList'
import { formUrlQuery } from 'src/helpers/format-queries'
import Axios from 'src/helpers/axios'

const ServiceList = () => {
  const currency = useSelector((state) => state.currency)

  const [serviceList, setServiceList] = useState([]);
  const [serviceListCount, setServiceListCount] = useState(0);
  const [serviceListPerPage, setServiceListPerPage] = useState(10);
  const [activeServiceListPage, setActiveServiceListPage] = useState(1);
  const [searchServiceListFilter, setSearchServiceListFilter] = useState("");
  const [columnServiceListSorter, setColumnServiceListSorter] = useState({ column: "updatedAt", state: "desc" });
  const [selectedItem, setSelectedItem] = useState([])

  const [showConfirmDelete, setShowConfirmDelete] = useState({ show: false, item: {} })

  useEffect(() => {
    let queries = {
      search: searchServiceListFilter,
      // limit: serviceListPerPage,
      offset: serviceListPerPage * activeServiceListPage - serviceListPerPage,
      sort:
        columnServiceListSorter &&
          columnServiceListSorter.column &&
          columnServiceListSorter.state &&
          columnServiceListSorter.column !== "" &&
          columnServiceListSorter.state !== "" &&
          columnServiceListSorter.state != 0 ?
          `${columnServiceListSorter.column}%${columnServiceListSorter.state}` : "updatedAt%desc",
    };
    queries = formUrlQuery(queries);
    fetchAllServices(queries);
  }, [
    searchServiceListFilter,
    serviceListPerPage,
    activeServiceListPage,
    columnServiceListSorter,
  ]);

  const fetchAllServices = (queries) => {
    Axios({
      method: "get",
      url: `/api/booking/v1/services/all?${queries}`,
    })
      .then(({ data }) => {
        const tempServiceList = data.map((d) => ({
          ...d,
          category_name: d?.category?.name || 'Not Available',
          price: d?.packages && d?.packages?.length > 0 ? d?.packages[0]?.price : '-'
        }));

        setServiceList(tempServiceList);
        if (activeServiceListPage === 1) {
          setServiceListCount(tempServiceList?.length);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDeleteConfirmation = (item) => {
    Axios({
      method: "delete",
      url: `/api/booking/v1/services/delete/${item.id}`,
    })
      .then(({ data }) => {
        setShowConfirmDelete({ show: false, item: {} })
        window.location.reload()
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleMultipleDelete = async (items) => {
    try {
      for (let k = 0; k < items.length; k++) {
        await Axios.delete(`/api/booking/v1/services/delete/${items[k].id}`)
      }
      setShowConfirmDelete({ show: false, item: {} })
      setSelectedItem([])
      window.location.reload()
    } catch (error) {
      console.error(error)
    }
  }

  // column filter for created on
  const [startDate, setStartDate] = useState(new Date('2022-01-01'))
  const [endDate, setEndDate] = useState(new Date())

  // column filter for service name
  const [filterServiceName, setFilterServiceName] = useState([])

  // column filter for category
  const [filterServiceCategory, setFilterServiceCategory] = useState([])

  // column filter for price
  const [filterServicePrice, setFilterServicePrice] = useState([])

  return (
    <>
      {showConfirmDelete.show && (
        <AppModalConfirmation
          confirmationType='delete-item'
          itemName='service'
          itemDetails={showConfirmDelete.item}
          showModal={showConfirmDelete}
          setShowModal={setShowConfirmDelete}
          handleConfirmation={
            showConfirmDelete.item &&
              Array.isArray(showConfirmDelete.item) &&
              showConfirmDelete.item.length > 0 ? handleMultipleDelete
              : handleDeleteConfirmation
          }
        />
      )}
      <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
        <CButton href='/services/add' style={styleButtonVars2}>
          <CImage fluid src={AddMoreIconWhite} className='me-2' />
          Create New Service
        </CButton>
      </div>
      <AppCard
        className='mt-4 mb-4'
        headerTitle={
          <AppCardHeader
            headerTitle={<h4>Service List</h4>}
            headerButton="Delete All"
            handleCardHeaderButton={() => setShowConfirmDelete({ show: true, item: selectedItem })}
            hidden={selectedItem && selectedItem.length === 0}
          />
        }
        bodyContent={
          <AppTable
            serviceListPage
            selectable
            tableFilter
            columnFilter
            setShowConfirmDelete={setShowConfirmDelete}
            tableColumns={
              columnServiceList(
                filterServiceName,
                filterServiceCategory,
                filterServicePrice,
                setFilterServiceName,
                setFilterServiceCategory,
                setFilterServicePrice
              )
            }
            tableContent={serviceList}
            contentPerPage={serviceListPerPage}
            activeContentPage={activeServiceListPage}
            contentCount={serviceListCount}
            searchContentFilter={searchServiceListFilter}
            setActiveContentPage={setActiveServiceListPage}
            setSearchContentFilter={setSearchServiceListFilter}
            setContentPerPage={setServiceListPerPage}
            setColumnContentSorter={setColumnServiceListSorter}
            startDateColumnFilter={startDate}
            endDateColumnFilter={endDate}
            handleSelectedItemChange={setSelectedItem}
          />
        }
      />
    </>
  )
}

export default ServiceList
