import React, { useCallback, useEffect } from 'react'
import moment from 'moment'

// components
import {
  CAvatar,
  CBadge
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilGroup } from '@coreui/icons'

const AppChatRoom = ({
  sortedChatRooms,
  currentUser,
  activeChatRoomId,
  handleOpenRoom,
}) => {
  return (
    <div className='flex-grow-1 overflow-auto'>
      {sortedChatRooms?.map((chat, i) => {
        const messagesFromOthers = chat.ChatMessages.filter(message => message.sender_id?.toString() !== currentUser?.id?.toString())
        const unreadMessages = messagesFromOthers.filter(message => !message.ReadBy?.map(item => item.user_id?.toString()).includes(currentUser?.id?.toString()))

        return (
          <div key={i} className={`chat_room border-bottom py-3 px-4 d-flex align-items-center gap-3 ${activeChatRoomId === chat?.id ? "chat_room_active" : ""}`} onClick={() => handleOpenRoom(chat)}>
            <div className=''>
              <CAvatar color="primary" textColor="white" size='md'>
                <CIcon icon={cilGroup} />
              </CAvatar>
            </div>
            <div className="chat_room_info">
              <div className='d-flex align-items-center justify-content-between gap-3'>
                <span className={`chat_room_name fw-bold ${unreadMessages.length > 0 ? "text-primary" : ""}`}>{chat.name}</span>
                {unreadMessages?.length > 0 && (
                  <CBadge color="primary">
                    {unreadMessages.length}
                  </CBadge>
                )}
              </div>
              <div className='d-flex align-items-center justify-content-between gap-3'>
                <span className='chat_room_content_preview'>
                  {chat.ChatMessages[chat.ChatMessages.length - 1]?.text}
                </span>
                <span>
                  {chat.ChatMessages[chat.ChatMessages.length - 1]?.createdAt ?
                    moment(chat.ChatMessages[chat.ChatMessages.length - 1]?.createdAt).toDate() >= moment().startOf('day').toDate()
                      ? moment(chat.ChatMessages[chat.ChatMessages.length - 1]?.createdAt).format("HH:mm")
                      : moment(chat.ChatMessages[chat.ChatMessages.length - 1]?.createdAt).format("DD/MM")
                    : null}
                </span>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default AppChatRoom
