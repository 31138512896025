import React, { useEffect, useState } from 'react'
import { useFormik } from "formik"
import * as Yup from "yup"
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

// components
import AppCard from 'src/components/AppCard'
import AppForm from 'src/components/form/add-service/AppForm'
import AppCardHeader from 'src/components/card/AppCardHeader'
import AppDetails from 'src/components/details/service-details/AppDetails'
import AppVariantDetails from 'src/components/details/service-details/AppVariantDetails'
import AppCombinationDetails from 'src/components/details/service-details/AppCombinationDetails'

// helpers
import Axios from 'src/helpers/axios'
import { singleOptionsSelect } from 'src/helpers/format-options'
import { capitalizeEachWord } from 'src/helpers/text-format'
import { arrayScheduleTimeSlotList } from 'src/helpers/format-data'

const ServiceDetails = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { id } = params

  const [serviceDetails, setServiceDetails] = useState({})
  const [categoryOptions, setCategoryOptions] = useState([])

  const formik = useFormik({
    initialValues: {
      service_name: serviceDetails?.title,
      category_id: serviceDetails?.category_id,
      service_desc: serviceDetails?.description,
      service_summary: serviceDetails?.summary,
      service_variants: serviceDetails?.packages && serviceDetails?.packages?.length > 0 ?
        [{
          name: capitalizeEachWord(serviceDetails?.packages && serviceDetails?.packages[0] && serviceDetails?.packages[0].title.split(" - ")[1] ? serviceDetails?.packages[0].title.split(" - ")[0] : 'package'),
          options: serviceDetails?.packages?.map((item) => {
            return {
              option_name: item?.title && item?.title?.split(" - ")[1] ? item?.title?.split(" - ")[1] : item?.title?.split(" - ")[0],
              option_price: item?.price ? item?.price : '',
              option_sale_price: item?.sale_price ? item?.sale_price : '',
              option_quota: item?.quota ? item?.quota : '',
              option_service_is_all_time: item?.is_available_all_time ? item?.is_available_all_time : false,
              option_service_schedule_option: item?.schedule_option ? item?.schedule_option : '',
              option_service_schedule_window: item?.schedule_window ? item?.schedule_window : '',
              option_service_date_list: item?.timeslots && item?.timeslots?.length > 0 ? arrayScheduleTimeSlotList(item?.timeslots) : []
            }
          })
        }] : [],
    },
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
  });

  const handleCardHeaderButton = () => {
    navigate(`/services/edit/${id}`)
    window.location.reload();
  }

  useEffect(() => {
    fetchAllCategories()
    fetchServiceDetails()
  }, [id]);

  const fetchServiceDetails = () => {
    Axios({
      method: "get",
      url: `/api/booking/v1/services/id/${id}`,
    })
      .then(({ data }) => {
        setServiceDetails(data)
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const fetchAllCategories = () => {
    Axios({
      method: "get",
      url: "/api/booking/v1/categories/all",
    })
      .then(({ data }) => {
        setCategoryOptions(singleOptionsSelect(data))
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <AppCard
      className='mt-4 mb-4'
      headerTitle={
        <AppCardHeader
          headerTitle={<h4>Service Details</h4>}
          headerButton="Edit"
          handleCardHeaderButton={handleCardHeaderButton}
        />
      }
      bodyContent={
        <AppForm
          detailsPage
          categoryOptions={categoryOptions}
          values={formik.values}
          handleChange={formik.handleChange}
          errors={formik.errors}
        />
      }
    />
  )
}

export default ServiceDetails
