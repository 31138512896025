import React, { useState } from 'react'

// components
import {
  CRow
} from '@coreui/react-pro'
import FormTextInput from '../../general-input-form/FormTextInput'
import FormImageThumbnail from 'src/components/form/general-input-form/FormImageThumbnail';
import FormImageUpload from 'src/components/form/general-input-form/FormImageUpload';

const AppForm = ({
  values,
  errors,
  handleChange,
  setOpenFilePreview,
  handleRemoveDetailsBannerImage,
  handleDetailsBannerImagesOnDrop,
  handleDetailsBannerImageAttachment,
}) => {

  return (
    <>
      <CRow className="mb-3">
        <FormTextInput
          id='our_services_display_name'
          name='our_services_display_name'
          colSize='6'
          inputLabel='Display Name'
          placeholder='Enter display name here...'
          value={values.our_services_display_name}
          handleTextInput={handleChange}
          feedbackInvalid={errors.our_services_display_name}
          invalid={errors.our_services_display_name}
        />
        <FormTextInput
          id='our_services_headline'
          name='our_services_headline'
          colSize='6'
          inputLabel='Headline'
          placeholder='Enter headline here...'
          value={values.our_services_headline}
          handleTextInput={handleChange}
          feedbackInvalid={errors.our_services_headline}
          invalid={errors.our_services_headline}
        />
      </CRow>
      <CRow className="mb-3">
        <FormTextInput
          id='our_services_title'
          name='our_services_title'
          colSize='6'
          inputLabel='Title'
          placeholder='Enter title here...'
          value={values.our_services_title}
          handleTextInput={handleChange}
          feedbackInvalid={errors.our_services_title}
          invalid={errors.our_services_title}
        />
        <FormTextInput
          id='action_button_text'
          name='action_button_text'
          colSize='6'
          inputLabel='Action Button Text'
          placeholder='Enter action button text here...'
          value={values.action_button_text}
          handleTextInput={handleChange}
          feedbackInvalid={errors.action_button_text}
          invalid={errors.action_button_text}
        />
      </CRow>
      <CRow className="mb-3">
        <FormTextInput
          id='details_tab_title_1'
          name='details_tab_title_1'
          colSize='6'
          inputLabel='Details Tab Title 1'
          placeholder='Enter tab title here...'
          value={values.details_tab_title_1}
          handleTextInput={handleChange}
          feedbackInvalid={errors.details_tab_title_1}
          invalid={errors.details_tab_title_1}
        />
        <FormTextInput
          id='details_tab_title_2'
          name='details_tab_title_2'
          colSize='6'
          inputLabel='Details Tab Title 2'
          placeholder='Enter tab title here...'
          value={values.details_tab_title_2}
          handleTextInput={handleChange}
          feedbackInvalid={errors.details_tab_title_2}
          invalid={errors.details_tab_title_2}
        />
      </CRow>
      <CRow className="mb-3">
        <FormTextInput
          id='details_button_text'
          name='details_button_text'
          colSize='6'
          inputLabel='Details Button Text'
          placeholder='Enter detail button text here...'
          value={values.details_button_text}
          handleTextInput={handleChange}
          feedbackInvalid={errors.details_button_text}
          invalid={errors.details_button_text}
        />
        {values.details_banner_image.length > 0 ? (
          <FormImageThumbnail
            editPage
            name='details_banner_image'
            inputLabel='Details Banner Image'
            colSize='6'
            classNameRow="ms-2"
            productImages={values.details_banner_image}
            handleChangeImage={handleDetailsBannerImageAttachment}
            handleRemoveImage={handleRemoveDetailsBannerImage}
            setOpenFilePreview={setOpenFilePreview}
          />
        ) : (
          <FormImageUpload
            name='details_banner_image'
            colSize='6'
            inputLabel='Details Banner Image'
            handleFileInput={handleDetailsBannerImageAttachment}
            handleFileOnDrop={handleDetailsBannerImagesOnDrop}
            multiple={false}
          />
        )}
      </CRow>
    </>
  )
}

export default AppForm
