import React, { useState, useEffect } from 'react'

// components
import AppCard from 'src/components/AppCard'
import AppTable from 'src/components/AppTable'
import AppModalConfirmation from 'src/components/modal/AppModalConfirmation'

// helpers
import { columnBookingList } from 'src/components/table-column/TableColumnList'
import { formUrlQuery } from 'src/helpers/format-queries'
import Axios from 'src/helpers/axios'

const BookingList = () => {

  const [bookingList, setBookingList] = useState([]);
  const [bookingListCount, setBookingListCount] = useState(0);
  const [bookingListPerPage, setBookingListPerPage] = useState(10);
  const [activeBookingListPage, setActiveBookingListPage] = useState(1);
  const [searchBookingListFilter, setSearchBookingListFilter] = useState("");
  const [columnBookingListSorter, setColumnBookingListSorter] = useState({ column: "updatedAt", state: "desc" });

  const [showConfirmCancel, setShowConfirmCancel] = useState({ show: false, item: {} })
  const [cancelReason, setCancelReason] = useState('')

  useEffect(() => {
    let queries = {
      search: searchBookingListFilter,
      // limit: bookingListPerPage,
      offset: bookingListPerPage * activeBookingListPage - bookingListPerPage,
      sort:
        columnBookingListSorter &&
          columnBookingListSorter.column &&
          columnBookingListSorter.state &&
          columnBookingListSorter.column !== "" &&
          columnBookingListSorter.state !== "" &&
          columnBookingListSorter.state != 0 ?
          `${columnBookingListSorter.column}%${columnBookingListSorter.state}` : "updatedAt%desc",
    };
    queries = formUrlQuery(queries);
    fetchAllBookings(queries);
  }, [
    searchBookingListFilter,
    bookingListPerPage,
    activeBookingListPage,
    columnBookingListSorter,
  ]);

  const fetchAllBookings = (queries) => {
    Axios({
      method: "get",
      url: `/api/booking/v1/bookings/all?${queries}`,
    })
      .then(({ data }) => {
        const tempBookingList = data.map((d) => ({
          ...d,
          customer_name: d?.guests && d?.guests?.length > 0 ? `${d?.guests[0].first_name} ${d?.guests[0].last_name}` : '-',
          service_variant: d?.package && d?.package?.title,
          booking_date: d?.booking_time && d?.booking_time?.date,
          booking_amount: d?.transaction_details && d?.transaction_details?.length > 0 ? d?.transaction_details[0]?.transaction_amount : '-',
          payment_method: d?.transaction_details && d?.transaction_details?.length > 0 ? d?.transaction_details[0]?.payment_channel?.name : '-'
        }));

        setBookingList(tempBookingList);
        if (activeBookingListPage === 1) {
          setBookingListCount(tempBookingList?.length);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleCancelConfirmation = (item, cancelReason) => {
    Axios({
      method: "post",
      url: `/api/booking/v1/bookings/update/${item.id}`,
      data: {
        status: "cancelled",
        cancel_reason: cancelReason
      }
    })
      .then(({ data }) => {
        setShowConfirmCancel({ show: false, item: {} })
        window.location.reload()
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // column filter for created on
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  // column filter for booking date
  const [startBookingDate, setStartBookingDate] = useState(null)
  const [endBookingDate, setEndBookingDate] = useState(null)

  // column filter for booking status
  const [filterBookingStatus, setFilterBookingStatus] = useState([])

  // column filter for booking amount
  const [filterBookingAmount, setFilterBookingAmount] = useState([])

  // column filter for payment method
  const [filterPaymentMethod, setFilterPaymentMethod] = useState([])

  // column filter for payment method
  const [filterServicePackage, setFilterServicePackage] = useState([])

  return (
    <>
      {showConfirmCancel.show && (
        <AppModalConfirmation
          confirmationType='cancel-item'
          itemName='booking'
          showModal={showConfirmCancel}
          itemDetails={showConfirmCancel.item}
          setShowModal={setShowConfirmCancel}
          handleConfirmation={handleCancelConfirmation}
          values={cancelReason}
          handleChange={setCancelReason}
        />
      )}
      <AppCard
        className='mb-4'
        headerTitle={<h4>Booking List</h4>}
        bodyContent={
          <AppTable
            bookingListPage
            tableFilter
            columnFilter
            setShowConfirmCancel={setShowConfirmCancel}
            tableColumns={
              columnBookingList(
                startDate,
                endDate,
                startBookingDate,
                endBookingDate,
                filterBookingStatus,
                filterBookingAmount,
                filterPaymentMethod,
                filterServicePackage,
                setStartDate,
                setEndDate,
                setStartBookingDate,
                setEndBookingDate,
                setFilterBookingStatus,
                setFilterBookingAmount,
                setFilterPaymentMethod,
                setFilterServicePackage
              )
            }
            tableContent={bookingList}
            contentPerPage={bookingListPerPage}
            activeContentPage={activeBookingListPage}
            contentCount={bookingListCount}
            searchContentFilter={searchBookingListFilter}
            setActiveContentPage={setActiveBookingListPage}
            setSearchContentFilter={setSearchBookingListFilter}
            setContentPerPage={setBookingListPerPage}
            setColumnContentSorter={setColumnBookingListSorter}
            startDateColumnFilter={startDate}
            endDateColumnFilter={endDate}
            startBookingDateColumnFilter={startBookingDate}
            endBookingDateColumnFilter={endBookingDate}
          />
        }
      />
    </>
  )
}

export default BookingList
