import React from 'react'

// components
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalTitle,
  CModalFooter
} from '@coreui/react-pro'

const AppModal = ({
  showModal,
  setShowModal,
  headerTitle,
  bodyTitleIcon,
  bodyTitle,
  bodyDescription,
  modalFooter
}) => {

  return (
    <>
      <CModal
        size='lg'
        alignment="center"
        visible={showModal}
      >
        <CModalHeader onClick={() => setShowModal((prev) => ({ ...prev, show: false }))}>
          <CModalTitle>
            {headerTitle}
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div className='mt-3 mb-3 d-grid gap-2 d-md-flex justify-content-center'>
            {bodyTitleIcon}
          </div>
          <h3 className='text-center font-weight-bold'>
            {bodyTitle}
          </h3>
          <p className='text-center'>
            {bodyDescription}
          </p>
        </CModalBody>
        {modalFooter && (
          <CModalFooter className='d-grid gap-2 d-md-flex justify-content-center'>
            {modalFooter}
          </CModalFooter>
        )}
      </CModal>
    </>
  )
}

export default AppModal
