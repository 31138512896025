import React, { useState } from 'react'

// components
import {
  CRow,
  CCol,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
  CFormInput
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import * as icon from "@coreui/icons";
import FormTextInput from '../../general-input-form/FormTextInput'
import FormPhoneNumber from '../../general-input-form/FormPhoneNumber'
import FormTextAreaInput from '../../general-input-form/FormTextAreaInput'

const AppForm = ({
  values,
  errors,
  handleChange,
  phoneCountry,
}) => {

  return (
    <>
      <CRow className="mb-3">
        <FormTextInput
          id='contact_us_display_name'
          name='contact_us_display_name'
          colSize='6'
          inputLabel='Display Name'
          placeholder='Enter display name here...'
          value={values.contact_us_display_name}
          handleTextInput={handleChange}
          feedbackInvalid={errors.contact_us_display_name}
          invalid={errors.contact_us_display_name}
        />
        <FormTextInput
          id='contact_us_headline'
          name='contact_us_headline'
          colSize='6'
          inputLabel='Headline'
          placeholder='Enter headline here...'
          value={values.contact_us_headline}
          handleTextInput={handleChange}
          feedbackInvalid={errors.contact_us_headline}
          invalid={errors.contact_us_headline}
        />
      </CRow>
      <CRow className="mb-3">
        <FormTextInput
          id='contact_us_title'
          name='contact_us_title'
          colSize='6'
          inputLabel='Title'
          placeholder='Enter title here...'
          value={values.contact_us_title}
          handleTextInput={handleChange}
          feedbackInvalid={errors.contact_us_title}
          invalid={errors.contact_us_title}
        />
        <FormTextInput
          id='contact_us_email'
          name='contact_us_email'
          colSize='6'
          inputLabel='Email Address'
          placeholder='Enter email address here...'
          value={values.contact_us_email}
          handleTextInput={handleChange}
          feedbackInvalid={errors.contact_us_email}
          invalid={errors.contact_us_email}
        />
      </CRow>
      <CRow className="mb-3">
        <CCol lg={6}>
          <CFormLabel>
            Phone Number
          </CFormLabel>
          <CInputGroup>
            {phoneCountry && phoneCountry?.id && (
              <>
                <CInputGroupText>
                  {phoneCountry?.country_code && phoneCountry?.country_code !== '' ? (
                    <CIcon icon={icon[`cif${phoneCountry?.country_code}`]} />
                  ) : (
                    phoneCountry?.name
                  )}
                </CInputGroupText>
                <CInputGroupText>
                  {phoneCountry?.phone_code}
                </CInputGroupText>
              </>
            )}
            <CFormInput
              id='contact_us_phone_number'
              name='contact_us_phone_number'
              placeholder='Enter phone number here...'
              type='text'
              onChange={handleChange}
              value={values.contact_us_phone_number}
              feedbackInvalid={errors.contact_us_phone_number}
              invalid={errors.contact_us_phone_number}
            />
          </CInputGroup>
        </CCol>
        <FormTextAreaInput
          id='contact_us_address'
          name='contact_us_address'
          className="mb-3"
          colSize='6'
          inputLabel='Address Details'
          placeholder='Enter address details here...'
          rows='5'
          value={values.contact_us_address}
          handleTextAreaInput={handleChange}
          feedbackInvalid={errors.contact_us_address}
          invalid={errors.contact_us_address}
        />
      </CRow>
    </>
  )
}

export default AppForm
