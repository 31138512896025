
import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux"
import moment from 'moment'

// components
import {
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableRow,
  CTableDataCell
} from '@coreui/react-pro'
import AppTextColumnDetails from '../general-details/AppTextColumnDetails'

// helpers
import Axios from 'src/helpers/axios'
import formatCurrency from 'src/helpers/format-currency'

const BookingDetails = ({
  bookingDetails,
  serviceDetails,
  transactionDetail
}) => {

  const transactionDetails = 
    bookingDetails && 
    bookingDetails.transaction_details && 
    bookingDetails.transaction_details[0]
  const transactionMetadata = 
    transactionDetails && 
    transactionDetails.metadata &&
    JSON.parse(transactionDetails.metadata)
  const serviceTitle = 
    transactionMetadata && 
    transactionMetadata.package &&
    transactionMetadata.package.service &&
    transactionMetadata.package.service.title
  const serviceSubtitle =
    transactionMetadata && 
    transactionMetadata.package &&
    transactionMetadata.package.title
  const servicePrice =
    transactionMetadata && 
    transactionMetadata.package &&
    transactionMetadata.package.price

  return (
    <>
      <CRow>
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title={serviceTitle}
          subtitle={serviceSubtitle}
        />
        <AppTextColumnDetails
          colSize="2"
          className="mb-3"
          title={`${bookingDetails?.guests && bookingDetails?.guests?.length} Guests`}
        />
        <AppTextColumnDetails
          colSize="2"
          className="mb-3"
          title={`${formatCurrency(servicePrice)}`}
        />
      </CRow>
      <CRow>
        <AppTextColumnDetails
          colSize="5"
          className="mb-3"
          title="Confirmed Date/Time"
          subtitle={moment(bookingDetails?.booking_time?.date).format('dddd, DD/MM/YYYY HH:mm')}
        />
        <AppTextColumnDetails
          colSize="4"
          className="mb-3"
          title="Total Amount"
          subtitle={transactionDetail?.transaction_amount && transactionDetail?.transaction_amount !== '' ? `${formatCurrency(transactionDetail?.transaction_amount)}` : '-'}
        />
      </CRow>
    </>
  )
}

export default BookingDetails
