import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from "yup"

// components
import AppCard from 'src/components/AppCard'
import AppForm from 'src/components/form/site-manager-form/update-general-settings/update-newsletter/AppForm'
import AppModalFilePreview from 'src/components/modal/AppModalFilePreview'
import ActionButtonFormSecond from 'src/components/form/general-input-form/ActionButtonFormSecond'

// helpers
import Axios from 'src/helpers/axios'

const NewsletterSection = ({
  newsLetterSettings
}) => {
  const [openFilePreview, setOpenFilePreview] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const newsletterHeadline = newsLetterSettings && newsLetterSettings[0] && newsLetterSettings[0].settings.filter(item => item?.component_name === 'headline')[0].content_text
  const inputPlaceholder = newsLetterSettings && newsLetterSettings[0] && newsLetterSettings[0].settings.filter(item => item?.component_name === 'input-placeholder-text')[0].content_text
  const accessButtonText = newsLetterSettings && newsLetterSettings[0] && newsLetterSettings[0].settings.filter(item => item?.component_name === 'action-button-text')[0].content_text

  const formik = useFormik({
    initialValues: {
      newsletter_headline: newsletterHeadline,
      newsletter_input_placeholder: inputPlaceholder,
      newsletter_action_button_text: accessButtonText
    },
    validationSchema: Yup.object().shape({
      newsletter_title: Yup.string().required("Please enter newsletter headline."),
      newsletter_input_placeholder: Yup.string().required("Please enter newsletter input placeholder."),
      newsletter_action_button_text: Yup.string().required("Please enter newsletter access button text.")
    }),
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      handleSave(values)
      setSubmitting(false)
    },
  });

  const handleEdit = async (payload) => {
    try {
      const response = await Axios.post(`/api/booking/v1/site-manager/update/4`, payload) // section_id for Newsletter is 4
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const handleSave = async (values) => {
    const formData1 = new FormData()
    const formData2 = new FormData()
    const formData3 = new FormData()
    setIsLoading(true)

    await Promise.all([
      (async () => {
        if (formik.values.newsletter_headline !== '') {
          formData1.append('component_name', 'headline')
          formData1.append('content_text', formik.values.newsletter_headline)
          await handleEdit(formData1)
        }
      })(),
      (async () => {
        if (formik.values.newsletter_input_placeholder !== '') {
          formData2.append('component_name', 'input-placeholder-text')
          formData2.append('content_text', formik.values.newsletter_input_placeholder)
          await handleEdit(formData2)
        }
      })(),
      (async () => {
        if (formik.values.newsletter_action_button_text !== '') {
          formData3.append('component_name', 'action-button-text')
          formData3.append('content_text', formik.values.newsletter_action_button_text)
          await handleEdit(formData3)
        }
      })()
    ]);

    setIsLoading(false)
    window.location.reload()
  }

  return (
    <>
      {openFilePreview && (
        <AppModalFilePreview
          setOpenFilePreview={setOpenFilePreview}
          file={openFilePreview}
        />
      )}
      <AppCard
        className='mb-2'
        // headerTitle={<h4>Main Site</h4>}
        bodyContent={
          <AppForm
            values={formik.values}
            handleChange={formik.handleChange}
            errors={formik.errors}
            setOpenFilePreview={setOpenFilePreview}
          />
        }
        footerContent={
          <ActionButtonFormSecond
            isLoading={isLoading}
            hiddenButtonCancel
            // onSave={formik.handleSubmit}
            onSave={handleSave}
            saveButtonText='Save and Publish'
          />
        }
      />
    </>
  )
}

export default NewsletterSection

