import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useSelector } from "react-redux"

// components
import {
  CCard,
  CCardBody,
  CCardFooter,
  CButtonGroup,
  CButton,
  CLink,
  CWidgetStatsF,
  CContainer,
  CProgress,
  CCol,
  CRow,
  CSmartTable,
  CMultiSelect
} from '@coreui/react-pro'
import { CChartLine } from '@coreui/react-chartjs'
import { getStyle, hexToRgba } from '@coreui/utils'

// icons
import CIcon from '@coreui/icons-react'
import {
  cilArrowRight,
  cilChartPie,
  cilChartLine,
  cilCloudDownload,
  cilUser,
  cilCash,
  cilCart
} from '@coreui/icons'

// helpers
import Axios from 'src/helpers/axios'
import { random } from 'src/helpers/text-format'
import { calculateSum } from 'src/helpers/format-number'
import { arrayChartAxisData, getMostPurchaseServiceId } from 'src/helpers/format-data'

const Dashboard = () => {
  const currency = useSelector((state) => state.currency)

  const [serviceList, setServiceList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [bookingList, setBookingList] = useState([]);
  const [bookingTransactionList, setBookingTransactionList] = useState([]);
  const [mostPurchaseService, setMostPurchaseService] = useState({});

  useEffect(() => {
    fetchAllServices();
    fetchAllCustomers();
    fetchAllBookings();
    fetchAllBookingTransactions();
  }, []);

  const fetchAllServices = () => {
    Axios({
      method: "get",
      url: `/api/booking/v1/services/all`,
    })
      .then(({ data }) => {
        setServiceList(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchAllCustomers = () => {
    Axios({
      method: "get",
      url: `/api/sso/v1/users/regular`,
    })
      .then(({ data }) => {
        setCustomerList(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchAllBookings = () => {
    Axios({
      method: "get",
      url: `/api/booking/v1/bookings/all`,
    })
      .then(({ data }) => {
        setBookingList(data);
        const mostPurchaseServiceId = getMostPurchaseServiceId(data)
        Axios({
          method: "get",
          url: `/api/booking/v1/services/package/id/${mostPurchaseServiceId}`,
        })
          .then(({ data }) => {
            setMostPurchaseService(data)
          })
          .catch((err) => {
            console.error(err);
          });
        Axios({
          method: "get",
          url: `/api/booking/v1/services/package/id/${mostPurchaseServiceId}`,
        })
          .then(({ data }) => {
            setMostPurchaseService(data)
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchAllBookingTransactions = () => {
    Axios({
      method: "post",
      url: `/api/booking/v1/transactions/all`,
      payload: {
        limit: null,
        offset: null,
        order: ['createdAt', 'ASC']
      }
    })
      .then(({ data }) => {
        setBookingTransactionList(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <CContainer>
      <CRow>
        <CCol xs={6}>
          <CWidgetStatsF
            className="mb-3"
            color="dark"
            footer={
              <CLink
                className="font-weight-bold font-xs text-medium-emphasis"
                href="/customers/list"
              >
                View More...
                <CIcon icon={cilArrowRight} className="float-end" width={16} />
              </CLink>
            }
            icon={<CIcon icon={cilUser} height={24} />}
            title="Total Customers"
            value={<h3>{customerList?.length}</h3>}
          />
        </CCol>
        <CCol xs={6}>
          <CWidgetStatsF
            className="mb-3"
            color="dark"
            footer={''}
            icon={<CIcon icon={cilCash} height={24} />}
            title="Total Bookings"
            value={<h3>{`${bookingList?.length}`}</h3>}
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={6}>
          <CWidgetStatsF
            className="mb-3"
            color="dark"
            icon={<CIcon icon={cilCart} height={24} />}
            footer={
              <CLink
                className="font-weight-bold font-xs text-medium-emphasis"
                href="/services/list"
              >
                View More...
                <CIcon icon={cilArrowRight} className="float-end" width={16} />
              </CLink>
            }
            title="Total Services"
            value={<h3>{serviceList?.length}</h3>}
          />
        </CCol>
        <CCol xs={6}>
          <CWidgetStatsF
            className="mb-3"
            color="dark"
            icon={<CIcon icon={cilChartLine} height={24} />}
            title="Most Booked Service"
            value={<h3>{mostPurchaseService?.service?.title || "-"}</h3>}
          />
        </CCol>
      </CRow>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                Sales Statistics
              </h4>
              <div className="small text-medium-emphasis">
                {`${moment(bookingTransactionList[0]?.transaction_date).format('DD MMMM YYYY')} - ${moment(bookingTransactionList[bookingTransactionList?.length - 1]?.transaction_date).format('DD MMMM YYYY')}`}
              </div>
            </CCol>
            {/* <CCol sm={7} className="d-none d-md-block">
              <CButton color="primary" className="float-end">
                <CIcon icon={cilCloudDownload} />
              </CButton>
              <CButtonGroup className="float-end me-3">
                {['Day', 'Month', 'Year'].map((value) => (
                  <CButton
                    color="outline-secondary"
                    key={value}
                    className="mx-0"
                    active={value === 'Month'}
                  >
                    {value}
                  </CButton>
                ))}
              </CButtonGroup>
            </CCol> */}
          </CRow>
          <CChartLine
            style={{ height: '300px', marginTop: '40px' }}
            data={{
              labels: arrayChartAxisData(bookingTransactionList, 'transaction_date'),
              datasets: [
                {
                  label: 'Sales Statistics',
                  backgroundColor: hexToRgba(getStyle('--cui-info'), 10),
                  borderColor: getStyle('--cui-info'),
                  pointHoverBackgroundColor: getStyle('--cui-info'),
                  borderWidth: 2,
                  data: arrayChartAxisData(bookingTransactionList, 'transaction_amount'),
                  fill: true,
                },
              ],
            }}
            options={{
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                x: {
                  grid: {
                    drawOnChartArea: false,
                  },
                },
                y: {
                  ticks: {
                    beginAtZero: true,
                    maxTicksLimit: 5,
                    stepSize: Math.ceil(250 / 5),
                    max: 250,
                  },
                },
              },
              elements: {
                line: {
                  tension: 0.4,
                },
                point: {
                  radius: 0,
                  hitRadius: 10,
                  hoverRadius: 4,
                  hoverBorderWidth: 3,
                },
              },
            }}
          />
        </CCardBody>
        {/* <CCardFooter>
          <CRow xs={{ cols: 1 }} md={{ cols: 5 }} className="text-center">
            {progressExample.map((item, index) => (
              <CCol className="mb-sm-2 mb-0" key={index}>
                <div className="text-medium-emphasis">{item.title}</div>
                <strong>
                  {item.value} ({item.percent}%)
                </strong>
                <CProgress
                  thin
                  className="mt-2"
                  color={`${item.color}-gradient`}
                  value={item.percent}
                />
              </CCol>
            ))}
          </CRow>
        </CCardFooter> */}
      </CCard>
    </CContainer>
  )
}

export default Dashboard
