import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

// components
import {
  CImage,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler
} from '@coreui/react-pro'
import { AppSidebarNav } from './AppSidebarNav'
import SimpleBar from 'simplebar-react'
import { _nav } from '../_nav'

// icons
import CloudxierLogo from '../assets/icons/CloudxierLogoInWhite.png'

// styling
import { styleSidebarVars1 } from './custom-styles/sidebarStyles'
import 'simplebar/dist/simplebar.min.css'

const AppSidebar = ({
  homeDisplayName,
  aboutUsDisplayName,
  contactUsDisplayName,
  faqDisplayName,
  ourServicesDisplayName
}) => {
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const navItems = _nav(homeDisplayName, aboutUsDisplayName, contactUsDisplayName, faqDisplayName, ourServicesDisplayName)

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
      style={styleSidebarVars1}
    >
      <CSidebarBrand className="mt-3 mb-2 d-none d-md-flex" to="/">
        <CImage fluid src={CloudxierLogo} />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav
            items={navItems}
          />
        </SimpleBar>
      </CSidebarNav>
      {/* <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
      /> */}
    </CSidebar>
  )
}

export default AppSidebar
