import React, { useState } from 'react'

// components
import {
  CCol,
  CRow,
  CFormLabel,
  CFormCheck,
  CMultiSelect,
  CFormText
} from '@coreui/react-pro'
import Select from 'react-select'
import FormTextInput from '../general-input-form/FormTextInput'
import FormTextAreaInput from '../general-input-form/FormTextAreaInput'
import FormSelectInput from '../general-input-form/FormSelectInput'
import FormProductPrice from '../general-input-form/FormProductPrice'

// helpers
import { optionsMultiSelect, singleOptionsSelect } from 'src/helpers/format-options'
import { removeDuplicatesMultiSelect } from 'src/helpers/format-data'

const AppFirstForm = ({
  editPage,
  detailsPage,
  categoryOptions,
  openFormServiceVariant,
  setOpenFormServiceVariant,
  values,
  errors,
  handleChange,
}) => {

  return (
    <>
      <CRow className="mb-4">
        <FormTextInput
          id='service_name'
          name='service_name'
          colSize='9'
          inputLabel='Service Name'
          placeholder='Please fill in service name...'
          value={values.service_name}
          handleTextInput={handleChange}
          feedbackInvalid={errors.service_name}
          invalid={errors.service_name}
          disabled={detailsPage}
        />
        <FormSelectInput
          id='category_id'
          name='category_id'
          colSize='3'
          inputLabel='Category'
          selectOptions={categoryOptions}
          value={values.category_id}
          handleSelectInput={handleChange}
          feedbackInvalid={errors.category_id}
          invalid={errors.category_id}
          disabled={detailsPage}
        />
      </CRow>
      <CRow className="mb-4">
        <FormTextAreaInput
          id='service_summary'
          name='service_summary'
          colSize='12'
          inputLabel='Summary'
          // textDescription='Maximum summary text is 100 characters'
          placeholder='Please enter summary here...'
          rows='5'
          value={values.service_summary}
          handleTextAreaInput={handleChange}
          feedbackInvalid={errors.service_summary}
          invalid={errors.service_summary}
          disabled={detailsPage}
        />
      </CRow>
      <CRow className="mb-4">
        <FormTextAreaInput
          id='service_desc'
          name='service_desc'
          colSize='12'
          inputLabel='Description'
          // textDescription='Maximum description text is 100 characters'
          placeholder='Please enter description here...'
          rows='5'
          value={values.service_desc}
          handleTextAreaInput={handleChange}
          feedbackInvalid={errors.service_desc}
          invalid={errors.service_desc}
          disabled={detailsPage}
        />
      </CRow>
    </>
  )
}

export default AppFirstForm
