import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import Axios from "../helpers/axios";

const LoginGuard = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Cookies.get("ut");

  useEffect(() => {
    if (token) {
      Axios({
        method: "get",
        url: "/api/sso/v1/users/profile",
      })
        .then(({ data }) => {
          if (data) {
            dispatch({ type: "SET_USER", user: data });
          } else {
            navigate("/login");
          }
        })
        .catch((err) => {
          console.error(err);
          navigate("/login");
        });
    } else {
      navigate("/login");
    }

    Axios({
      method: "get",
      url: `/api/booking/v1/settings`,
    })
      .then(({ data }) => {
        const dataSettings = data && data[0]
        dispatch({ type: "SET_GENERAL_SETTING", generalSettings: dataSettings });
        Axios({
          method: "get",
          url: `/api/booking/v1/currencies`,
        })
          .then(({ data }) => {
            const shopCurrency = data?.filter((currency) => { return currency?.id == dataSettings?.shop_currency_id })
            dispatch({ type: "SET_CURRENCY", currency: shopCurrency[0] });
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });

    Axios({
      method: "get",
      url: `/api/booking/v1/site-manager/`,
    })
      .then(({ data }) => {
        dispatch({ type: "SET_HOME_SETTING", homeSetting: data?.filter(item => item?.section_name === 'home')[0] });
        dispatch({ type: "SET_ABOUT_US_SETTING", aboutUsSetting: data?.filter(item => item?.section_name === 'about-us')[0] });
        dispatch({ type: "SET_CONTACT_US_SETTING", contactUsSetting: data?.filter(item => item?.section_name === 'contact-us')[0] });
        dispatch({ type: "SET_OUR_SERVICES_SETTING", ourServicesSetting: data?.filter(item => item?.section_name === 'our-services')[0] });
        dispatch({ type: "SET_FAQ_SETTING", FAQSetting: data?.filter(item => item?.section_name === 'frequently-asked-questions')[0] });
      })
      .catch((err) => {
        console.error(err);
      });
  }, [])

  if (!token) {
    navigate("/login")
  } else {
    return children
  }

  // const afterRegister = localStorage.getItem("after_register");

  // useEffect(() => {
  //   localStorage.setItem("credentials", "");
  //   if (token) {
  //     if (afterRegister && Boolean(afterRegister)) {
  //       localStorage.setItem("after_register", "");
  //       localStorage.setItem("email_storage", "");
  //       Cookies.remove("ut");
  //       dispatch({ type: "SET_USER", user: {} });
  //       navigate("/login");
  //     } else {
  //       Axios({
  //         method: "get",
  //         url: "/users",
  //       })
  //         .then(({ data }) => {
  //           if (data) {
  //             dispatch({ type: "SET_USER", user: data });
  //           } else {
  //             navigate("/login");
  //           }
  //         })
  //         .catch((err) => {
  //           navigate("/login");
  //         });
  //     }
  //   }
  // }, []);

  // if (!token) {
  //   return <Navigate to="login" replace />;
  // } else {
  //   if (Boolean(afterRegister) === false && user) {
  //     return children;
  //   }
  // }
};

export default LoginGuard;
