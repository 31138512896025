export const styleSidebarVars1 = {
  '--cui-sidebar-bg': '#000000',
  '--cui-sidebar-brand-bg': '#000000',
  '--cui-sidebar-header-bg': '#000000',
  '--cui-sidebar-footer-bg': '#000000',
  '--cui-sidebar-nav-group-bg': '#000000',
  '--cui-sidebar-nav-link-color': '#FFFFFF',
  '--cui-sidebar-nav-link-active-bg': '#525252',
  '--cui-sidebar-nav-link-active-icon-bg': '#525252',
  fontSize: '1.2rem',
}
