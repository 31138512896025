import { useEffect } from 'react'

// components
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CImage
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilX } from '@coreui/icons'

const AppModalFilePreview = ({
  file,
  setOpenFilePreview
}) => {
  let fileType;
  let fileUrl;

  if (file instanceof File) {
    fileType = file && file.type && file.type.split('/')[0]
    fileUrl = URL.createObjectURL(file)
  } else {
    fileType = 'image'
    fileUrl = file
  }

  useEffect(() => {
    window.addEventListener('keydown', handleEscKey)
    return () => window.removeEventListener('keydown', handleEscKey)
  }, [])

  const handleEscKey = (e) => {
    if (e.key == 'Escape') {
      setOpenFilePreview(null)
    }
  }

  switch (fileType) {
    case 'image':
      return (
        <CModal size='lg' alignment="center" visible={file}>
          <CModalHeader onClick={() => setOpenFilePreview(null)}>
            <CModalTitle>Image Preview</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CImage fluid rounded align='center' src={fileUrl} />
          </CModalBody>
        </CModal>
      )
    default:
      return <div />
  }

}

export default AppModalFilePreview