import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFormik } from "formik"
import * as Yup from "yup"

// components
import AppCard from 'src/components/AppCard'
import AppForm from 'src/components/form/site-manager-form/update-contact-us/AppForm'
import AppModalFilePreview from 'src/components/modal/AppModalFilePreview'
import ActionButtonFormSecond from 'src/components/form/general-input-form/ActionButtonFormSecond'

// helpers
import Axios from 'src/helpers/axios'

const ContactUsSettings = () => {
  const generalSettings = useSelector((state) => state.generalSettings)
  const contactUsSetting = useSelector((state) => state.contactUsSetting)
  const [phoneCountry, setPhoneCountry] = useState({})
  const [openFilePreview, setOpenFilePreview] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const displayName = contactUsSetting && contactUsSetting.settings && contactUsSetting.settings.filter(item => item?.component_name === 'display-name')[0].content_text
  const headlineContactus = contactUsSetting && contactUsSetting.settings && contactUsSetting.settings.filter(item => item?.component_name === 'headline')[0].content_text
  const titleContactUs = contactUsSetting && contactUsSetting.settings && contactUsSetting.settings.filter(item => item?.component_name === 'title')[0].content_text
  const contactNumber = contactUsSetting && contactUsSetting.settings && contactUsSetting.settings.filter(item => item?.component_name === 'contact-number')[0].content_text
  const contactAddress = contactUsSetting && contactUsSetting.settings && contactUsSetting.settings.filter(item => item?.component_name === 'contact-address')[0].content_text
  const contactEmail = contactUsSetting && contactUsSetting.settings && contactUsSetting.settings.filter(item => item?.component_name === 'contact-email')[0].content_text

  const formik = useFormik({
    initialValues: {
      contact_us_display_name: displayName,
      contact_us_headline: headlineContactus,
      contact_us_title: titleContactUs,
      contact_us_phone_number: contactNumber?.slice(phoneCountry?.phone_code?.length),
      contact_us_address: contactAddress,
      contact_us_email: contactEmail,
    },
    validationSchema: Yup.object().shape({
      contact_us_display_name: Yup.string().required("Please enter display name."),
      contact_us_headline: Yup.string().required("Please enter headline."),
      contact_us_title: Yup.string().required("Please enter title."),
      contact_us_phone_number: Yup.string().required("Please enter contact number."),
      contact_us_address: Yup.string().required("Please enter contact address."),
      contact_us_email: Yup.string().required("Please enter contact email.")
    }),
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      handleSave(values)
      setSubmitting(false)
    },
  });

  useEffect(() => {
    if (generalSettings?.country_id) {
      fetchCountryDetails(generalSettings?.country_id)
    }
  }, [generalSettings]);

  const fetchCountryDetails = (id) => {
    Axios({
      method: "get",
      url: `/api/booking/v1/countries`,
    })
      .then(({ data }) => {
        const selectedCountry = data?.filter((country) => { return country?.id == id })
        setPhoneCountry(selectedCountry[0])
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleEdit = async (payload) => {
    try {
      const response = await Axios.post(`/api/booking/v1/site-manager/update/7`, payload) // section_id for Contact Us is 7
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const handleSave = async (values) => {
    const formData1 = new FormData()
    const formData2 = new FormData()
    const formData3 = new FormData()
    const formData4 = new FormData()
    const formData5 = new FormData()
    const formData6 = new FormData()
    setIsLoading(true)

    await Promise.all([
      (async () => {
        if (values.contact_us_display_name !== '') {
          formData1.append('component_name', 'display-name')
          formData1.append('content_text', values.contact_us_display_name)
          await handleEdit(formData1)
        }
      })(),
      (async () => {
        if (values.contact_us_headline !== '') {
          formData2.append('component_name', 'headline')
          formData2.append('content_text', values.contact_us_headline)
          await handleEdit(formData2)
        }
      })(),
      (async () => {
        if (values.contact_us_title !== '') {
          formData3.append('component_name', 'title')
          formData3.append('content_text', values.contact_us_title)
          await handleEdit(formData3)
        }
      })(),
      (async () => {
        if (values.contact_us_phone_number !== '') {
          formData4.append('component_name', 'contact-number')
          formData4.append('content_text', `${phoneCountry?.phone_code}${values.contact_us_phone_number}`)
          await handleEdit(formData4)
        }
      })(),
      (async () => {
        if (values.contact_us_address !== '') {
          formData5.append('component_name', 'contact-address')
          formData5.append('content_text', values.contact_us_address)
          await handleEdit(formData5)
        }
      })(),
      (async () => {
        if (values.contact_us_email !== '') {
          formData6.append('component_name', 'contact-email')
          formData6.append('content_text', values.contact_us_email)
          await handleEdit(formData6)
        }
      })(),
    ]);

    setIsLoading(false)
    window.location.reload()
  }

  return (
    <>
      {openFilePreview && (
        <AppModalFilePreview
          setOpenFilePreview={setOpenFilePreview}
          file={openFilePreview}
        />
      )}
      <AppCard
        className='mb-2'
        headerTitle={<h4>{displayName && displayName !== '' ? displayName : "Contact Us"} Settings</h4>}
        bodyContent={
          <AppForm
            values={formik.values}
            handleChange={formik.handleChange}
            errors={formik.errors}
            setOpenFilePreview={setOpenFilePreview}
            phoneCountry={phoneCountry}
          />
        }
        footerContent={
          <ActionButtonFormSecond
            isLoading={isLoading}
            hiddenButtonCancel
            onSave={formik.handleSubmit}
            saveButtonText='Save and Publish'
          />
        }
      />
    </>
  )
}

export default ContactUsSettings

