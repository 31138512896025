import React, { useState } from 'react'

// components
import {
  CRow
} from '@coreui/react-pro'
import FormTextInput from '../../../general-input-form/FormTextInput'

const AppForm = ({
  values,
  errors,
  handleChange,
}) => {

  return (
    <>
      <CRow className="mb-3">
        <FormTextInput
          id='purchase_button_text'
          name='purchase_button_text'
          colSize='6'
          inputLabel='Action Button Text'
          placeholder='Enter action button text...'
          value={values.purchase_button_text}
          handleTextInput={handleChange}
          feedbackInvalid={errors.purchase_button_text}
          invalid={errors.purchase_button_text}
        />
        <FormTextInput
          id='sign_in_button_text'
          name='sign_in_button_text'
          colSize='6'
          inputLabel='Sign-In Button Text'
          placeholder='Enter sign-in button text...'
          value={values.sign_in_button_text}
          handleTextInput={handleChange}
          feedbackInvalid={errors.sign_in_button_text}
          invalid={errors.sign_in_button_text}
        />
      </CRow>
    </>
  )
}

export default AppForm
