import React, { useEffect, useState } from 'react'
import { useFormik } from "formik"
import * as Yup from "yup"

// components
import AppCard from 'src/components/AppCard'
import AppForm from 'src/components/form/site-manager-form/update-general-settings/update-footer/AppForm'
import AppModalFilePreview from 'src/components/modal/AppModalFilePreview'
import ActionButtonFormSecond from 'src/components/form/general-input-form/ActionButtonFormSecond'

// helpers
import Axios from 'src/helpers/axios'

const FooterSection = ({
  footerSettings
}) => {
  const [openFilePreview, setOpenFilePreview] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const copyrightText = footerSettings && footerSettings[0] && footerSettings[0].settings.filter(item => item?.component_name === 'copyright-text')[0].content_text
  const youtubeLink = footerSettings && footerSettings[0] && footerSettings[0].settings.filter(item => item?.component_name === 'youtube-link')[0].content_text
  const facebookLink = footerSettings && footerSettings[0] && footerSettings[0].settings.filter(item => item?.component_name === 'facebook-link')[0].content_text
  const twitterLink = footerSettings && footerSettings[0] && footerSettings[0].settings.filter(item => item?.component_name === 'twitter-link')[0].content_text
  const linkedinLink = footerSettings && footerSettings[0] && footerSettings[0].settings.filter(item => item?.component_name === 'linkedin-link')[0].content_text
  const instagramLink = footerSettings && footerSettings[0] && footerSettings[0].settings.filter(item => item?.component_name === 'instagram-link')[0].content_text

  const formik = useFormik({
    initialValues: {
      copyright_text: copyrightText,
      youtube_link: youtubeLink,
      facebook_link: facebookLink,
      twitter_link: twitterLink,
      linkedin_link: linkedinLink,
      instagram_link: instagramLink,
    },
    validationSchema: Yup.object().shape({
      copyright_text: Yup.string().required("Please enter copyright text."),
    }),
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      handleSave(values)
      setSubmitting(false)
    },
  });

  const handleEdit = async (payload) => {
    try {
      const response = await Axios.post(`/api/booking/v1/site-manager/update/3`, payload) // section_id for Footer is 3
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const handleSave = async (values) => {
    const formData1 = new FormData()
    const formData2 = new FormData()
    const formData3 = new FormData()
    const formData4 = new FormData()
    const formData5 = new FormData()
    const formData6 = new FormData()
    setIsLoading(true)

    await Promise.all([
      (async () => {
        if (values.copyright_text !== '') {
          formData1.append('component_name', 'copyright-text')
          formData1.append('content_text', values.copyright_text)
          await handleEdit(formData1)
        }
      })(),
      (async () => {
        if (values.youtube_link !== '') {
          formData2.append('component_name', 'youtube-link')
          formData2.append('content_text', values.youtube_link)
          await handleEdit(formData2)
        }
      })(),
      (async () => {
        if (values.facebook_link !== '') {
          formData3.append('component_name', 'facebook-link')
          formData3.append('content_text', values.facebook_link)
          await handleEdit(formData3)
        }
      })(),
      (async () => {
        if (values.twitter_link !== '') {
          formData4.append('component_name', 'twitter-link')
          formData4.append('content_text', values.twitter_link)
          await handleEdit(formData4)
        }
      })(),
      (async () => {
        if (values.linkedin_link !== '') {
          formData5.append('component_name', 'linkedin-link')
          formData5.append('content_text', values.linkedin_link)
          await handleEdit(formData5)
        }
      })(),
      (async () => {
        if (values.linkedin_link !== '') {
          formData5.append('component_name', 'linkedin-link')
          formData5.append('content_text', values.linkedin_link)
          await handleEdit(formData5)
        }
      })(),
      (async () => {
        if (values.instagram_link !== '') {
          formData6.append('component_name', 'instagram-link')
          formData6.append('content_text', values.instagram_link)
          await handleEdit(formData6)
        }
      })(),
    ]);

    setIsLoading(false)
    window.location.reload()
  }

  return (
    <>
      {openFilePreview && (
        <AppModalFilePreview
          setOpenFilePreview={setOpenFilePreview}
          file={openFilePreview}
        />
      )}
      <AppCard
        className='mb-2'
        // headerTitle={<h4>Main Site</h4>}
        bodyContent={
          <AppForm
            values={formik.values}
            handleChange={formik.handleChange}
            errors={formik.errors}
            setOpenFilePreview={setOpenFilePreview}
          />
        }
        footerContent={
          <ActionButtonFormSecond
            isLoading={isLoading}
            hiddenButtonCancel
            onSave={formik.handleSubmit}
            saveButtonText='Save and Publish'
          />
        }
      />
    </>
  )
}

export default FooterSection

