import moment from "moment";
import { capitalizeFirstLetter } from "./text-format";

export const getAllWeekendDates = (date) => {
  let arrayDate = date.split('/')
  let year = parseInt(arrayDate[2]);
  let month = parseInt(arrayDate[0]) - 1;

  let weekendDates = [];

  for (let x = 0; x <= new Date(year, 0, 0).getMonth(); x++) {
    for (let y = 0; y <= new Date(year, x, 0).getDate(); y++) {
      let date = new Date(year, x, y);
      if (date.getDay() === 6 || date.getDay() === 0) {
        weekendDates.push(date);
      }
    };
  };

  // for (let x = 0; x <= new Date(year, month, 0).getDate(); x++) {
  //   let date = new Date(year, month, x);
  //   if (date.getDay() === 6 || date.getDay() === 0) {
  //     weekendDates.push(date);
  //   }
  // };

  return weekendDates
};

export const getAllWeekdayDates = (date) => {
  let arrayDate = date.split('/')
  let year = parseInt(arrayDate[2]);
  let month = parseInt(arrayDate[0]) - 1;

  let weekdayDates = [];

  for (let x = 0; x <= new Date(year, 0, 0).getMonth(); x++) {
    for (let y = 0; y <= new Date(year, x, 0).getDate(); y++) {
      let date = new Date(year, x, y);
      if (
        date.getDay() === 1 ||
        date.getDay() === 2 ||
        date.getDay() === 3 ||
        date.getDay() === 4 ||
        date.getDay() === 5
      ) {
        weekdayDates.push(date);
      }
    };
  };

  // for (let x = 0; x <= new Date(year, month, 0).getDate(); x++) {
  //   let date = new Date(year, month, x);
  //   if (
  //     date.getDay() === 1 ||
  //     date.getDay() === 2 ||
  //     date.getDay() === 3 ||
  //     date.getDay() === 4 ||
  //     date.getDay() === 5
  //   ) {
  //     weekdayDates.push(date);
  //   }
  // };

  return weekdayDates
};

export const arrayScheduleBookingDates = (data) => {
  let options = [
    {
      "react-datepicker__day--highlighted-custom-1": [],
    },
    {
      "react-datepicker__day--highlighted-custom-2": [],
    }
  ]
  if (data && data.length > 0) {
    data.forEach((item) => {
      const schedule = item && item.service_date
      const isUnavailable = item && item.service_as_unavailable
      if (isUnavailable) {
        options[1]["react-datepicker__day--highlighted-custom-2"].push(schedule)
      } else {
        options[0]["react-datepicker__day--highlighted-custom-1"].push(schedule)
      }
    })
  }
  return options
}




