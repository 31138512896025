import React, { useState, useEffect } from 'react'
import moment from 'moment'

// components
import {
  CCol,
  CFormLabel,
  CDatePicker
} from '@coreui/react-pro'

// helpers
import {
  getAllWeekdayDates,
  getAllWeekendDates
} from 'src/helpers/date-helper'

const FormDatePicker = ({
  id,
  name,
  placeholder,
  colSize,
  className,
  inputLabel,
  date,
  handleDatePicker,
  feedbackInvalid,
  invalid,
  disabled,
  serviceSchedule,
  componentRef

}) => {
  const [disabledDates, setDisabledDates] = useState([])

  useEffect(() => {
    if (serviceSchedule === 'weekdays') {
      // if schedule weekday only, disable weekend
      const disabledWeekendDates = getAllWeekendDates(moment(new Date()).format('L'))
      setDisabledDates(disabledWeekendDates)
    }
    if (serviceSchedule === 'weekend') {
      // if schedule weekend only, disable weekday
      const disabledWeekdayDates = getAllWeekdayDates(moment(new Date()).format('L'))
      setDisabledDates(disabledWeekdayDates)
    }
    if (serviceSchedule === 'weekdays-weekend') {
      setDisabledDates([])
    }
  }, [serviceSchedule])

  return (
    <CCol lg={colSize} className={className}>
      <CFormLabel>
        {inputLabel}
      </CFormLabel>
      <CDatePicker
        // ref={el => { componentRef.current = el }}
        id={id}
        name={name}
        placeholder={placeholder}
        date={date ? date : new Date()}
        onDateChange={(value) => handleDatePicker(value)}
        feedbackInvalid={feedbackInvalid}
        invalid={invalid}
        disabled={disabled}
        disabledDates={disabledDates}
      />
    </CCol>
  )
}

export default FormDatePicker
