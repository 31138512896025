import React, { useState } from 'react'

// components
import {
  CRow,
} from '@coreui/react-pro'
import FormTextInput from '../../../general-input-form/FormTextInput'
import FormImageThumbnail from 'src/components/form/general-input-form/FormImageThumbnail';
import FormImageUpload from 'src/components/form/general-input-form/FormImageUpload';

const AppForm = ({
  values,
  errors,
  handleChange,
  handleRemoveMainLogoImage,
  handleMainLogoImagesOnDrop,
  handleMainLogoImageAttachment,
  handleRemoveTabIconImage,
  handleTabIconImagesOnDrop,
  handleTabIconImageAttachment,
  setOpenFilePreview
}) => {

  return (
    <>
      <CRow className="mb-3">
        <FormTextInput
          id='site_title'
          name='site_title'
          colSize='6'
          inputLabel='Site Title'
          placeholder='Enter site title...'
          value={values.site_title}
          handleTextInput={handleChange}
          feedbackInvalid={errors.site_title}
          invalid={errors.site_title}
        />
        <FormTextInput
          id='tab_title'
          name='tab_title'
          colSize='6'
          inputLabel='Tab Title'
          placeholder='Enter tab title...'
          value={values.tab_title}
          handleTextInput={handleChange}
          feedbackInvalid={errors.tab_title}
          invalid={errors.tab_title}
        />
      </CRow>
      <CRow className="mb-3">
        {values.main_site_logo.length > 0 ? (
          <FormImageThumbnail
            editPage
            name='main_site_logo'
            inputLabel='Main Site Logo'
            colSize='6'
            classNameRow="ms-2"
            productImages={values.main_site_logo}
            handleChangeImage={handleMainLogoImageAttachment}
            handleRemoveImage={handleRemoveMainLogoImage}
            setOpenFilePreview={setOpenFilePreview}
          />
        ) : (
          <FormImageUpload
            formMainSiteLogo
            name='main_site_logo'
            colSize='6'
            inputLabel='Main Site Logo'
            handleFileInput={handleMainLogoImageAttachment}
            handleFileOnDrop={handleMainLogoImagesOnDrop}
            multiple={false}
          />
        )}
        {values.main_tab_icon.length > 0 ? (
          <FormImageThumbnail
            editPage
            name='main_tab_icon'
            inputLabel='Fav Icon Image'
            colSize='6'
            classNameRow="ms-2"
            productImages={values.main_tab_icon}
            handleChangeImage={handleTabIconImageAttachment}
            handleRemoveImage={handleRemoveTabIconImage}
            setOpenFilePreview={setOpenFilePreview}
          />
        ) : (
          <FormImageUpload
            formMainTabIcon
            name='main_tab_icon'
            colSize='6'
            inputLabel='Fav Icon Image'
            handleFileInput={handleTabIconImageAttachment}
            handleFileOnDrop={handleTabIconImagesOnDrop}
            multiple={false}
          />
        )}
      </CRow>
    </>
  )
}

export default AppForm
