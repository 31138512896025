import React from 'react'

// Dashboard
import Dashboard from './views/dashboard/Dashboard'

// Coupons
// import CouponsList from './views/coupons/CouponsList'
// import AddCoupon from './views/coupons/AddCoupon'
// import EditCoupon from './views/coupons/EditCoupon'
// import CouponDetails from './views/coupons/CouponDetails'

// Schedules
import Schedules from './views/schedules/Schedules'

// Site Manager
import ContactUsSettings from './views/site-manager/contact-us/ContactUsSettings'
import FAQSettings from './views/site-manager/faq/FAQSettings'
import GeneralSettings from './views/site-manager/general-settings/GeneralSettings'
import HomeSettings from './views/site-manager/home/HomeSettings'
import AboutUsSettings from './views/site-manager/about-us/AboutUsSettings'
import OurServicesSettings from './views/site-manager/our-services/OurServicesSettings'

// Bookings
import BookingList from './views/bookings/BookingList'
import BookingDetails from './views/bookings/BookingDetails'

// Services
import ServiceList from './views/services/ServiceList'
import AddService from './views/services/AddService'
import EditService from './views/services/EditService'
import ServiceDetails from './views/services/ServiceDetails'

// Services - Category
import CategoryList from './views/services/category/CategoryList'
import AddCategory from './views/services/category/AddCategory'
import EditCategory from './views/services/category/EditCategory'
import CategoryDetails from './views/services/category/CategoryDetails'

// Customers
import CustomerList from './views/customers/CustomerList'
import CustomerDetails from './views/customers/CustomerDetails'

// Newsletter
import NewsletterSubscription from './views/newsletter/NewsletterSubscription'
// Chats
import Chats from './views/chats/Chat'

export const routes = (
  homeDisplayName,
  aboutUsDisplayName,
  contactUsDisplayName,
  faqDisplayName,
  ourServicesDisplayName
) => {

  return [
    { path: '/', name: 'Home', element: Dashboard, exact: true, },
    { path: '/dashboard', name: 'Dashboard', element: Dashboard, exact: true },
    // { path: '/coupons', name: 'Coupons', element: CouponsList, exact: true },
    // { path: '/coupons/list', name: 'Coupon List', element: CouponsList },
    // { path: '/coupons/add', name: 'Add Coupon', element: AddCoupon },
    // { path: '/coupons/edit/:id', name: 'Edit Coupon', element: EditCoupon },
    // { path: '/coupons/details/:id', name: 'Coupon Details', element: CouponDetails },
    { path: '/schedules', name: 'Schedules', element: Schedules, exact: true },
    { path: '/site-manager/general', name: 'General Settings', element: GeneralSettings, exact: true },
    { path: '/site-manager/home', name: `${homeDisplayName && homeDisplayName !== '' ? homeDisplayName : 'Home'} Settings`, element: HomeSettings },
    { path: '/site-manager/about-us', name: `${aboutUsDisplayName && aboutUsDisplayName !== '' ? aboutUsDisplayName : 'About Us'} Settings`, element: AboutUsSettings },
    { path: '/site-manager/contact-us', name: `${contactUsDisplayName && contactUsDisplayName !== '' ? contactUsDisplayName : 'Contact Us'} Settings`, element: ContactUsSettings },
    { path: '/site-manager/service-list', name: `${ourServicesDisplayName && ourServicesDisplayName !== '' ? ourServicesDisplayName : 'Our Services'} Settings`, element: OurServicesSettings },
    { path: '/site-manager/faq', name: `${faqDisplayName && faqDisplayName !== '' ? faqDisplayName : 'FAQ'} Settings`, element: FAQSettings },
    { path: '/bookings', name: 'Bookings', element: BookingList, exact: true },
    { path: '/bookings/list', name: 'Booking List', element: BookingList },
    { path: '/bookings/details/:id', name: 'Booking Details', element: BookingDetails },
    { path: '/services', name: 'Service', element: ServiceList, exact: true, },
    { path: '/services/list', name: 'Service List', element: ServiceList },
    { path: '/services/add', name: 'Add Service', element: AddService },
    { path: '/services/edit/:id', name: 'Edit Service', element: EditService },
    { path: '/services/details/:id', name: 'Service Details', element: ServiceDetails },
    { path: '/categories', name: 'Category', element: CategoryList, exact: true },
    { path: '/category/list', name: 'Category List', element: CategoryList },
    { path: '/category/add', name: 'Add Category', element: AddCategory },
    { path: '/category/edit/:id', name: 'Edit Category', element: EditCategory },
    { path: '/category/details/:id', name: 'Category Details', element: CategoryDetails },
    // { path: '/customers', name: 'Customers', element: CustomerList, exact: true },
    // { path: '/customers/list', name: 'Customer List', element: CustomerList },
    // { path: '/customers/details/:id', name: 'Customer Details', element: CustomerDetails },
    { path: '/newsletter/subscription/list', name: 'Newsletter Subscription', element: NewsletterSubscription },
    { path: '/chat', name: 'Chat', element: Chats, exact: true },
  ]
}
