import React from 'react'
import { useSelector } from 'react-redux'
import AppContent from 'src/components/AppContent'
import AppSidebar from 'src/components/AppSidebar'
import AppFooter from 'src/components/AppFooter'
import AppHeader from 'src/components/AppHeader'
import LoginGuard from './LoginGuard'

const DefaultLayout = () => {
  const homeSetting = useSelector((state) => state.homeSetting)
  const aboutUsSetting = useSelector((state) => state.aboutUsSetting)
  const contactUsSetting = useSelector((state) => state.contactUsSetting)
  const ourServicesSetting = useSelector((state) => state.ourServicesSetting)
  const FAQSetting = useSelector((state) => state.FAQSetting)

  const homeDisplayName = homeSetting && homeSetting.settings && homeSetting.settings.filter(item => item?.component_name === 'display-name')[0].content_text
  const aboutUsDisplayName = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'display-name')[0].content_text
  const contactUsDisplayName = contactUsSetting && contactUsSetting.settings && contactUsSetting.settings.filter(item => item?.component_name === 'display-name')[0].content_text
  const faqDisplayName = FAQSetting && FAQSetting.settings && FAQSetting.settings.filter(item => item?.component_name === 'display-name')[0].content_text
  const ourServicesDisplayName = ourServicesSetting && ourServicesSetting.settings && ourServicesSetting.settings.filter(item => item?.component_name === 'display-name')[0].content_text

  return (
    <LoginGuard>
      <AppSidebar
        homeDisplayName={homeDisplayName}
        aboutUsDisplayName={aboutUsDisplayName}
        contactUsDisplayName={contactUsDisplayName}
        faqDisplayName={faqDisplayName}
        ourServicesDisplayName={ourServicesDisplayName}
      />
      <div className="wrapper d-flex flex-column min-vh-100">
        <AppHeader
          homeDisplayName={homeDisplayName}
          aboutUsDisplayName={aboutUsDisplayName}
          contactUsDisplayName={contactUsDisplayName}
          faqDisplayName={faqDisplayName}
          ourServicesDisplayName={ourServicesDisplayName}
        />
        <div className="body flex-grow-1 px-3">
          <AppContent
            homeDisplayName={homeDisplayName}
            aboutUsDisplayName={aboutUsDisplayName}
            contactUsDisplayName={contactUsDisplayName}
            faqDisplayName={faqDisplayName}
            ourServicesDisplayName={ourServicesDisplayName}
          />
        </div>
        <AppFooter />
      </div>
    </LoginGuard>
  )
}

export default DefaultLayout
