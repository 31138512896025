
import React from 'react'
import moment from 'moment'

// components
import {
  CRow,
  CImage,
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem
} from '@coreui/react-pro'

import AppTextColumnDetails from '../general-details/AppTextColumnDetails'

// icons
import WhatsappIcon from '../../../assets/icons/WhatsappIcon.png'
// helpers 
import { capitalizeFirstLetter } from 'src/helpers/text-format'

const CustomerBookingDetails = ({
  guestList
}) => {

  return (
    <>
      <CAccordion activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            <b>Customer Details</b>
          </CAccordionHeader>
          <CAccordionBody>
            {guestList?.length > 0 && (
              <CAccordion activeItemKey={1}>
                {guestList?.map((guest, index) => {
                  return (
                    <CAccordionItem itemKey={index} key={index} >
                      <CAccordionHeader>
                        <b>Guest {index + 1}</b>
                      </CAccordionHeader>
                      <CAccordionBody>
                        <CRow>
                          <AppTextColumnDetails
                            colSize="3"
                            className="mb-3"
                            title='Title'
                            subtitle={
                              guest.salutation && guest.salutation !== '' && guest.salutation === "mr" ? "Mr." :
                                guest.salutation === "miss" ? "Miss" :
                                  guest.salutation === "mrs" ? "Mrs." :
                                    ''
                            }
                          />
                          <AppTextColumnDetails
                            colSize="3"
                            className="mb-3"
                            title='First Name'
                            subtitle={guest.first_name}
                          />
                          <AppTextColumnDetails
                            colSize="3"
                            className="mb-3"
                            title="Last Name"
                            subtitle={guest.last_name}
                          />
                        </CRow>
                        <CRow>
                          <AppTextColumnDetails
                            colSize="3"
                            className="mb-3"
                            title='Date of Birth'
                            subtitle={moment(guest.date_of_birth).format('DD/MM/YYYY')}
                          />
                          <AppTextColumnDetails
                            colSize="3"
                            className="mb-3"
                            title='Gender'
                            subtitle={capitalizeFirstLetter(guest.gender)}
                          />
                          <AppTextColumnDetails
                            colSize="3"
                            className="mb-3"
                            title="Phone Number"
                            subtitle={
                              guest.is_whatsapp_available ?
                                (
                                  <>
                                    {guest.phone_number}
                                    <CImage width={30} className='ms-2' fluid src={WhatsappIcon} />
                                  </>
                                ) : guest.phone_number
                            }
                          />
                        </CRow>
                        <CRow>
                          <AppTextColumnDetails
                            colSize="6"
                            className="mb-3"
                            title='Address'
                            subtitle={guest.address}
                          />
                          <AppTextColumnDetails
                            colSize="6"
                            className="mb-3"
                            title='Notes'
                            subtitle={guest.extra_note}
                          />
                        </CRow>
                      </CAccordionBody>
                    </CAccordionItem>
                  )
                })}
              </CAccordion>
            )}
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
    </>
  )
}

export default CustomerBookingDetails
