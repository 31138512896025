import React from 'react'

// icons
import SchedulesMenuIcon from './assets/icons/SchedulesMenuIcon.png'
import ChatMenuIcon from './assets/icons/ChatMenuIcon.png'
import CustomersMenuIcon from './assets/icons/CustomersMenuIcon.png'
import DashboardMenuIcon from './assets/icons/DashboardMenuIcon.png'
import SiteManagerMenuIcon from './assets/icons/SiteManagerMenuIcon.png'
import BookingsMenuIcon from './assets/icons/BookingsMenuIcon.png'
import ServicesMenuIcon from './assets/icons/ServicesMenuIcon.png'
import CategoryMenuIcon from './assets/icons/CategoryMenuIcon.png'
import { CImage, CNavGroup, CNavItem } from '@coreui/react-pro'

export const _nav = (
  homeDisplayName,
  aboutUsDisplayName,
  contactUsDisplayName,
  faqDisplayName,
  ourServicesDisplayName
) => {

  return [
    {
      component: CNavItem,
      name: 'Dashboard',
      to: '/dashboard',
      icon: <CImage fluid src={DashboardMenuIcon} className='me-2' />,
    },
    // {
    //   component: CNavGroup,
    //   name: 'Site Manager',
    //   icon: <CImage fluid src={SiteManagerMenuIcon} className='me-2' />,
    //   items: [
    //     {
    //       component: CNavItem,
    //       name: 'General',
    //       to: '/site-manager/general',
    //     },
    //     {
    //       component: CNavItem,
    //       name: homeDisplayName && homeDisplayName !== '' ? homeDisplayName : 'Home',
    //       to: '/site-manager/home',
    //     },
    //     {
    //       component: CNavItem,
    //       name: aboutUsDisplayName && aboutUsDisplayName !== '' ? aboutUsDisplayName : 'About Us',
    //       to: '/site-manager/about-us',
    //     },
    //     {
    //       component: CNavItem,
    //       name: contactUsDisplayName && contactUsDisplayName !== '' ? contactUsDisplayName : 'Contact Us',
    //       to: '/site-manager/contact-us',
    //     },
    //     {
    //       component: CNavItem,
    //       name: ourServicesDisplayName && ourServicesDisplayName !== '' ? ourServicesDisplayName : 'Our Services',
    //       to: '/site-manager/service-list',
    //     },
    //     {
    //       component: CNavItem,
    //       name: faqDisplayName && faqDisplayName !== '' ? faqDisplayName : 'FAQ',
    //       to: '/site-manager/faq',
    //     },
    //   ],
    // },
    {
      component: CNavItem,
      name: 'Schedules',
      to: '/schedules',
      icon: <CImage fluid src={SchedulesMenuIcon} className='me-2' />,
    },
    // {
    //   component: CNavItem,
    //   name: 'Customers',
    //   to: '/customers/list',
    //   icon: <CImage fluid src={CustomersMenuIcon} className='me-2' />,
    // },
    {
      component: CNavItem,
      name: 'Bookings',
      to: '/bookings/list',
      icon: <CImage fluid src={BookingsMenuIcon} className='me-2' />,
    },
    {
      component: CNavItem,
      name: 'Services',
      to: '/services/list',
      icon: <CImage fluid src={ServicesMenuIcon} className='me-2' />,
    },
    {
      component: CNavItem,
      name: 'Category',
      to: '/category/list',
      icon: <CImage fluid src={CategoryMenuIcon} className='me-2' />,
    },
    // {
    //   component: CNavItem,
    //   name: 'Newsletter',
    //   to: '/newsletter/subscription/list',
    //   icon: <CImage fluid src={CategoryMenuIcon} className='me-2' />,
    // },
    // {
    //   component: CNavItem,
    //   name: 'Chat',
    //   to: '/chat',
    //   icon: <CImage fluid src={ChatMenuIcon} className='me-2' />,
    // },

  ]
}

