import React, { useState } from 'react'

// components
import {
  CRow,
  CCol,
  CImage,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CInputGroupText
} from '@coreui/react-pro'
import FormTextInput from '../../../general-input-form/FormTextInput'

// icons
import FacebookIcon from 'src/assets/icons/FacebookIcon.png'
import TwitterIcon from 'src/assets/icons/TwitterIcon.png'
import LinkedInIcon from 'src/assets/icons/LinkedInIcon.png'
import YoutubeIcon from 'src/assets/icons/YoutubeIcon.png'
import InstagramIcon from 'src/assets/icons/InstagramIcon.png'


const AppForm = ({
  values,
  errors,
  handleChange,
}) => {

  return (
    <>
      <CRow className="mb-3">
        <CCol lg={6}>
          <CFormLabel>
            Social Media Links:
          </CFormLabel>
          <CInputGroup className="mb-2">
            <CInputGroupText >
              <CImage width={30} align="center" fluid src={FacebookIcon} />
            </CInputGroupText>
            <CFormInput
              id="facebook_link"
              name="facebook_link"
              placeholder="Enter social media link..."
              type="text"
              value={values.facebook_link}
              onChange={handleChange}
              feedbackInvalid={errors.facebook_link}
              invalid={errors.facebook_link}
            />
          </CInputGroup>
          <CInputGroup className="mb-2">
            <CInputGroupText >
              <CImage width={30} align="center" fluid src={TwitterIcon} />
            </CInputGroupText>
            <CFormInput
              id="twitter_link"
              name="twitter_link"
              placeholder="Enter social media link..."
              type="text"
              value={values.twitter_link}
              onChange={handleChange}
              feedbackInvalid={errors.twitter_link}
              invalid={errors.twitter_link}
            />
          </CInputGroup>
          <CInputGroup className="mb-2">
            <CInputGroupText >
              <CImage width={30} align="center" fluid src={InstagramIcon} />
            </CInputGroupText>
            <CFormInput
              id="instagram_link"
              name="instagram_link"
              placeholder="Enter social media link..."
              type="text"
              value={values.instagram_link}
              onChange={handleChange}
              feedbackInvalid={errors.instagram_link}
              invalid={errors.instagram_link}
            />
          </CInputGroup>
          <CInputGroup className="mb-2">
            <CInputGroupText >
              <CImage width={30} align="center" fluid src={YoutubeIcon} />
            </CInputGroupText>
            <CFormInput
              id="youtube_link"
              name="youtube_link"
              placeholder="Enter social media link..."
              type="text"
              value={values.youtube_link}
              onChange={handleChange}
              feedbackInvalid={errors.youtube_link}
              invalid={errors.youtube_link}
            />
          </CInputGroup>
          <CInputGroup className="mb-2">
            <CInputGroupText >
              <CImage width={30} align="center" fluid src={LinkedInIcon} />
            </CInputGroupText>
            <CFormInput
              id="linkedin_link"
              name="linkedin_link"
              placeholder="Enter social media link..."
              type="text"
              value={values.linkedin_link}
              onChange={handleChange}
              feedbackInvalid={errors.linkedin_link}
              invalid={errors.linkedin_link}
            />
          </CInputGroup>
        </CCol>
        <FormTextInput
          id='copyright_text'
          name='copyright_text'
          colSize='6'
          inputLabel='Copyright Text'
          placeholder='Enter copyright text...'
          value={values.copyright_text}
          handleTextInput={handleChange}
          feedbackInvalid={errors.copyright_text}
          invalid={errors.copyright_text}
        />
      </CRow>
    </>
  )
}

export default AppForm
