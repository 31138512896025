import React, { useEffect, useState } from 'react'
import { useFormik } from "formik"
import * as Yup from "yup"

// components
import AppCard from 'src/components/AppCard'
import AppForm from 'src/components/form/site-manager-form/update-general-settings/update-main-site/AppForm'
import AppModalFilePreview from 'src/components/modal/AppModalFilePreview'
import ActionButtonFormSecond from 'src/components/form/general-input-form/ActionButtonFormSecond'

// helpers
import Axios from 'src/helpers/axios'

const MainSite = ({
  mainSiteSettings
}) => {
  const [openFilePreview, setOpenFilePreview] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const siteTitle = mainSiteSettings && mainSiteSettings[0] && mainSiteSettings[0].settings.filter(item => item?.component_name === 'site-title')[0].content_text
  const tabTitle = mainSiteSettings && mainSiteSettings[0] && mainSiteSettings[0].settings.filter(item => item?.component_name === 'tab-title')[0].content_text
  const mainSiteLogo = mainSiteSettings && mainSiteSettings[0] && mainSiteSettings[0].settings.filter(item => item?.component_name === 'site-logo')[0].content_file_url
  const mainTabIcon = mainSiteSettings && mainSiteSettings[0] && mainSiteSettings[0].settings.filter(item => item?.component_name === 'tab-icon')[0].content_file_url

  const formik = useFormik({
    initialValues: {
      site_title: siteTitle,
      tab_title: tabTitle,
      main_site_logo: mainSiteLogo && mainSiteLogo !== '' ? [mainSiteLogo] : [],
      main_tab_icon: mainTabIcon && mainTabIcon !== '' ? [mainTabIcon] : []
    },
    validationSchema: Yup.object().shape({
      site_title: Yup.string().required("Please enter main site title."),
      tab_title: Yup.string().required("Please enter main tab title.")
    }),
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      handleSave(values)
      setSubmitting(false)
    },
  });

  const handleEdit = async (payload) => {
    try {
      const response = await Axios.post(`/api/booking/v1/site-manager/update/1`, payload) // section_id for Main Site is 1
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const handleSave = async (values) => {
    const formData1 = new FormData()
    const formData2 = new FormData()
    const formData3 = new FormData()
    const formData4 = new FormData()
    setIsLoading(true)

    await Promise.all([
      (async () => {
        if (values.site_title !== '') {
          formData1.append('component_name', 'site-title')
          formData1.append('content_text', values.site_title)
          await handleEdit(formData1)
        }
      })(),
      (async () => {
        if (values.tab_title !== '') {
          formData2.append('component_name', 'tab-title')
          formData2.append('content_text', values.tab_title)
          await handleEdit(formData2)
        }
      })(),
      (async () => {
        if (values.main_site_logo.length > 0 && values.main_site_logo[0] instanceof File) {
          formData3.append('component_name', 'site-logo')
          formData3.append('file', values.main_site_logo[0])
          await handleEdit(formData3)
        }
      })(),
      (async () => {
        if (values.main_tab_icon.length > 0 && values.main_tab_icon[0] instanceof File) {
          formData4.append('component_name', 'tab-icon')
          formData4.append('file', values.main_tab_icon[0])
          await handleEdit(formData4)
        }
      })()
    ]);

    setIsLoading(false)
    window.location.reload()
  }

  // handle image for main logo
  const handleRemoveMainLogoImage = (file) => {
    formik.setValues((values) => ({
      ...values,
      main_site_logo: values.main_site_logo.filter(image => image !== file)
    }), false)
  }

  const handleMainLogoImagesOnDrop = (files) => {
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        main_site_logo: attachments
      }), false)
    }
  }

  const handleMainLogoImageAttachment = (e) => {
    e.preventDefault()
    const files = e.target.files
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        main_site_logo: attachments
      }), false)
    }
  }

  // handle image for tab icon
  const handleRemoveTabIconImage = (file) => {
    formik.setValues((values) => ({
      ...values,
      main_tab_icon: values.main_tab_icon.filter(image => image !== file)
    }), false)
  }

  const handleTabIconImagesOnDrop = (files) => {
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        main_tab_icon: attachments
      }), false)
    }
  }

  const handleTabIconImageAttachment = (e) => {
    e.preventDefault()
    const files = e.target.files
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        main_tab_icon: attachments
      }), false)
    }
  }

  return (
    <>
      {openFilePreview && (
        <AppModalFilePreview
          setOpenFilePreview={setOpenFilePreview}
          file={openFilePreview}
        />
      )}
      <AppCard
        className='mb-2'
        // headerTitle={<h4>Main Site</h4>}
        bodyContent={
          <AppForm
            values={formik.values}
            handleChange={formik.handleChange}
            errors={formik.errors}
            setOpenFilePreview={setOpenFilePreview}
            handleRemoveMainLogoImage={handleRemoveMainLogoImage}
            handleMainLogoImagesOnDrop={handleMainLogoImagesOnDrop}
            handleMainLogoImageAttachment={handleMainLogoImageAttachment}
            handleRemoveTabIconImage={handleRemoveTabIconImage}
            handleTabIconImagesOnDrop={handleTabIconImagesOnDrop}
            handleTabIconImageAttachment={handleTabIconImageAttachment}
          />
        }
        footerContent={
          <ActionButtonFormSecond
            isLoading={isLoading}
            hiddenButtonCancel
            onSave={formik.handleSubmit}
            saveButtonText='Save and Publish'
          />
        }
      />
    </>
  )
}

export default MainSite

