import React, { useState, useEffect } from 'react'

// components
import AppCard from 'src/components/AppCard.js'
import AppCardHeader from 'src/components/card/AppCardHeader'
import BookingDetails from './BookingDetails'
import CustomerBookingDetails from './CustomerBookingDetails'

const AppSecondDetails = ({
  bookingDetails,
}) => {
  const [serviceDetails, setServiceDetails] = useState({})
  const [guestList, setGuestList] = useState([])
  const [transactionDetail, setTransactionDetail] = useState({})

  useEffect(() => {
    if (bookingDetails?.package && bookingDetails?.package?.id) {
      setServiceDetails(bookingDetails?.package)
    }
    if (bookingDetails?.guests && bookingDetails?.guests?.length > 0) {
      setGuestList(bookingDetails?.guests)
    }
    if (bookingDetails?.transaction_details && bookingDetails?.transaction_details?.length > 0) {
      setTransactionDetail(bookingDetails?.transaction_details[0])
    }
  }, [bookingDetails]);

  return (
    <AppCard
      className='mt-4 mb-4'
      headerTitle={
        <AppCardHeader
          headerTitle={<h5>Booking Details</h5>}
          headerDownloadButton="Download Receipt"
          downloadLink={transactionDetail?.invoice && transactionDetail?.invoice?.file_url}
          downloadName={`INVOICE ${transactionDetail && transactionDetail?.transaction_id}`}
        />
      }
      bodyContent={
        <>
          <BookingDetails
            bookingDetails={bookingDetails}
            serviceDetails={serviceDetails}
            transactionDetail={transactionDetail}
          />
          <CustomerBookingDetails
            bookingDetails={bookingDetails}
            guestList={guestList}
          />
        </>
      }
    />
  )
}

export default AppSecondDetails
