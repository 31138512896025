import React, { useState } from 'react'

// components
import {
  CRow,
  CCol,
  CAccordion,
  CAccordionHeader,
  CAccordionBody,
  CAccordionItem,
  CFormTextarea,
  CFormLabel,
  CFormInput,
  CButton,
  CImage
} from '@coreui/react-pro'
import FormTextInput from 'src/components/form/general-input-form/FormTextInput'
import FormTextAreaInput from 'src/components/form/general-input-form/FormTextAreaInput'
import FormImageThumbnail from 'src/components/form/general-input-form/FormImageThumbnail';
import FormImageUpload from 'src/components/form/general-input-form/FormImageUpload';
import AppCard from 'src/components/AppCard';
import ActionButtonFormSecond from '../../general-input-form/ActionButtonFormSecond';

// icon 
import TrashIcon from '../../../../assets/icons/TrashIcon.png'
import AddMoreIconWhite from '../../../../assets/icons/AddMoreIconWhite.png'

// styling
import { styleButtonVars2, styleButtonVars3 } from 'src/components/custom-styles/buttonStyles';

const AppForm = ({
  valuesFormik1,
  handleChangeFormik1,
  errorsFormik1,
  valuesFormik2,
  errorsFormik2,
  handleSubmitMainSettings,
  handleSubmitFAQList,
  isLoadingMainSetting,
  isLoadingFAQList,
  displayName,
  handleRemoveMainBannerImage,
  handleMainBannerImagesOnDrop,
  handleMainBannerImageAttachment,
  setShowConfirmDelete,
  inputFAQCategory,
  setInputFAQCategory,
  handleInputFAQCategory,
  handleRemoveInputFAQCategory,
  handleAddQuestionAnswer,
  handleQuestionFAQCategory,
  handleAnswerFAQCategory,
  handleRemoveQuestionAnswer,
  setOpenFilePreview
}) => {

  return (
    <>
      <CAccordion >
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            <h5>{displayName && displayName !== '' ? displayName : "FAQ"} Main Settings</h5>
          </CAccordionHeader>
          <CAccordionBody>
            <AppCard
              className='mb-2'
              bodyContent={
                <>
                  <CRow className="mb-3">
                    <FormTextInput
                      id='display_name'
                      name='display_name'
                      colSize='6'
                      inputLabel='Display Name'
                      placeholder='Enter display name...'
                      value={valuesFormik1.display_name}
                      handleTextInput={handleChangeFormik1}
                      feedbackInvalid={errorsFormik1.display_name}
                      invalid={errorsFormik1.display_name}
                    />
                    <FormTextInput
                      id='headline'
                      name='headline'
                      colSize='6'
                      inputLabel='Headline'
                      placeholder='Enter headline...'
                      value={valuesFormik1.headline}
                      handleTextInput={handleChangeFormik1}
                      feedbackInvalid={errorsFormik1.headline}
                      invalid={errorsFormik1.headline}
                    />
                  </CRow>
                  <CRow className="mb-3">
                    {valuesFormik1.faq_main_banner.length > 0 ? (
                      <FormImageThumbnail
                        name='faq_main_banner'
                        inputLabel='Main Banner Image'
                        colSize='12'
                        classNameRow="ms-2 d-flex justify-content-center align-item-center"
                        productImages={valuesFormik1.faq_main_banner}
                        handleChangeImage={handleMainBannerImageAttachment}
                        handleRemoveImage={handleRemoveMainBannerImage}
                        setOpenFilePreview={setOpenFilePreview}
                      />
                    ) : (
                      <FormImageUpload
                        name='faq_main_banner'
                        colSize='12'
                        inputLabel='Main Banner Image'
                        handleFileInput={handleMainBannerImageAttachment}
                        handleFileOnDrop={handleMainBannerImagesOnDrop}
                        multiple={false}
                      />
                    )}
                  </CRow>
                </>
              }
              footerContent={
                <ActionButtonFormSecond
                  isLoading={isLoadingMainSetting}
                  hiddenButtonCancel
                  onSave={handleSubmitMainSettings}
                  saveButtonText='Save and Publish'
                />
              }
            />
          </CAccordionBody>
        </CAccordionItem>
        <CAccordionItem itemKey={2}>
          <CAccordionHeader>
            <h5>{displayName && displayName !== '' ? displayName : "FAQ"} List Settings</h5>
          </CAccordionHeader>
          <CAccordionBody>
            <AppCard
              className='mb-2'
              bodyContent={
                <>
                  <CRow className="mb-3">
                    <CCol lg='12'>
                      <CFormLabel>
                        {displayName && displayName !== '' ? displayName : "FAQ"} Category
                      </CFormLabel>
                      <CFormInput
                        placeholder='Enter your FAQ Category such as Product, Make an Appointment, etc. here...'
                        value={inputFAQCategory}
                        onChange={({ target: { value } }) => setInputFAQCategory(value)}
                        onKeyDown={(e) => handleInputFAQCategory(e)}
                      />
                      <p className='mt-2' style={{ fontSize: '14px', color: 'AFB3BA', }}>
                        Click <b>'Enter'</b> to Add or <b>'Esc'</b> to Cancel
                      </p>
                    </CCol>
                    {/* {isProductVariantEmpty && (
                      <CFormText className='error-text-field'>
                        Please fill out all of the fields for question and answer.
                      </CFormText>
                    )}
                    {errorsFormik2 && (
                      <CFormText className='error-text-field'>
                        {errorsFormik2}
                      </CFormText>
                    )} */}
                  </CRow>
                  <CRow className="mb-3">
                    <CCol lg='12'>
                      {valuesFormik2.faq_category_list.length > 0 && (
                        <CAccordion>
                          {valuesFormik2.faq_category_list.map((category, categoryIndex) => {
                            return (
                              <CAccordionItem itemKey={categoryIndex} key={categoryIndex} >
                                <CAccordionHeader>
                                  <b>{category.name}</b>
                                  <CButton
                                    className='ms-3'
                                    style={styleButtonVars3}
                                    onClick={() => {
                                      category.id ?
                                        setShowConfirmDelete({ show: true, item: category, type: 'faq-category' })
                                        : handleRemoveInputFAQCategory(category)
                                    }}
                                  >
                                    <CImage
                                      fluid
                                      src={TrashIcon}
                                      className='me-2 mb-1'
                                    />
                                    Remove
                                  </CButton>
                                </CAccordionHeader>
                                <CAccordionBody>
                                  <CRow>
                                    <CCol lg={12} >
                                      <div className='mb-3 d-grid gap-2 d-md-flex justify-content-between align-items-center'>
                                        <CFormLabel>
                                          Question List
                                        </CFormLabel>
                                        <CButton
                                          onClick={() => handleAddQuestionAnswer(category, categoryIndex)}
                                          style={styleButtonVars2}
                                        >
                                          <CImage fluid src={AddMoreIconWhite} className='me-2' />
                                          {category?.qna_list?.length > 0 ? 'Add More' : 'Add'}
                                        </CButton>
                                      </div>
                                    </CCol>
                                  </CRow>
                                  {category?.qna_list?.length > 0 && (
                                    <CAccordion>
                                      {category?.qna_list?.map((list, listIndex) => {
                                        return (
                                          <CAccordionItem itemKey={listIndex} key={listIndex} >
                                            <CAccordionHeader>
                                              <b>Q & A {listIndex + 1}</b>
                                              <CButton
                                                className='ms-3'
                                                style={styleButtonVars3}
                                                onClick={() => {
                                                  list.id ?
                                                    setShowConfirmDelete({ show: true, item: list, type: 'faq-list' })
                                                    : handleRemoveQuestionAnswer(categoryIndex, listIndex)
                                                }}
                                              >
                                                <CImage
                                                  fluid
                                                  src={TrashIcon}
                                                  className='me-2 mb-1'
                                                />
                                                Remove
                                              </CButton>
                                            </CAccordionHeader>
                                            <CAccordionBody>
                                              <CRow className='mb-3'>
                                                <CCol lg={12}>
                                                  <CFormLabel>
                                                    Question
                                                  </CFormLabel>
                                                  <CFormTextarea
                                                    placeholder='Enter your question here...'
                                                    onChange={({ target: { value } }) => handleQuestionFAQCategory(value, categoryIndex, listIndex)}
                                                    value={list.question}
                                                    rows={3}
                                                  />
                                                </CCol>
                                              </CRow>
                                              <CRow>
                                                <CCol lg={12}>
                                                  <CFormLabel>
                                                    Answer
                                                  </CFormLabel>
                                                  <CFormTextarea
                                                    placeholder='Enter your answer here...'
                                                    onChange={({ target: { value } }) => handleAnswerFAQCategory(value, categoryIndex, listIndex)}
                                                    value={list.answer}
                                                    rows={5}
                                                  />
                                                </CCol>
                                              </CRow>
                                            </CAccordionBody>
                                          </CAccordionItem>
                                        )
                                      })}
                                    </CAccordion>
                                  )}
                                </CAccordionBody>
                              </CAccordionItem>
                            )
                          })}
                        </CAccordion>
                      )}
                    </CCol>
                  </CRow>
                </>
              }
              footerContent={
                <ActionButtonFormSecond
                  isLoading={isLoadingFAQList}
                  hiddenButtonCancel
                  onSave={handleSubmitFAQList}
                  saveButtonText='Save and Publish'
                />
              }
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>


    </>
  )
}

export default AppForm
