import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

// components
import AppCard from 'src/components/AppCard'
import AppCardHeader from 'src/components/card/AppCardHeader'
import AppDetails from 'src/components/details/category-details/AppDetails'

// helpers
import Axios from 'src/helpers/axios'

const CategoryDetails = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { id } = params

  const [categoryDetails, setCategoryDetails] = useState('')

  const handleCardHeaderButton = () => {
    navigate(`/category/edit/${id}`)
    window.location.reload();
  }

  useEffect(() => {
    fetchCategoryDetails()
  }, [id]);

  const fetchCategoryDetails = () => {
    Axios({
      method: "get",
      url: `/api/booking/v1/categories/id/${id}`,
    })
      .then(({ data }) => {
        setCategoryDetails(data)
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <AppCard
      className='mt-4 mb-4'
      headerTitle={
        <AppCardHeader
          headerTitle={<h4>Category Details</h4>}
          headerButton="Edit"
          handleCardHeaderButton={handleCardHeaderButton}
        />
      }
      bodyContent={
        <AppDetails
          categoryDetails={categoryDetails}
        />
      }
    />
  )
}

export default CategoryDetails
