import React, { useState, useEffect } from 'react'

// components
import { CButton, CImage } from '@coreui/react-pro'
import AppCard from 'src/components/AppCard.js'
import AppTable from 'src/components/AppTable.js'
import AppModalConfirmation from 'src/components/modal/AppModalConfirmation'
import AppCardHeader from 'src/components/card/AppCardHeader'

// icons
import AddMoreIconWhite from '../../../assets/icons/AddMoreIconWhite.png'

// styling
import { styleButtonVars2 } from 'src/components/custom-styles/buttonStyles'

// helpers
import { columnCategoryList } from 'src/components/table-column/TableColumnList'
import { formUrlQuery } from 'src/helpers/format-queries'
import Axios from 'src/helpers/axios'

const CategoryList = () => {

  const [categoryList, setCategoryList] = useState([]);
  const [categoryListCount, setCategoryListCount] = useState(0);
  const [categoryListPerPage, setCategoryListPerPage] = useState(10);
  const [activeCategoryListPage, setActiveCategoryListPage] = useState(1);
  const [searchCategoryListFilter, setSearchCategoryListFilter] = useState("");
  const [columnCategoryListSorter, setColumnCategoryListSorter] = useState({ column: "id", state: "desc" });
  const [selectedItem, setSelectedItem] = useState([])

  const [showConfirmDelete, setShowConfirmDelete] = useState({ show: false, item: {} })

  useEffect(() => {
    let queries = {
      search: searchCategoryListFilter,
      // limit: categoryListPerPage,
      offset: categoryListPerPage * activeCategoryListPage - categoryListPerPage,
      sort:
        columnCategoryListSorter &&
          columnCategoryListSorter.column &&
          columnCategoryListSorter.state &&
          columnCategoryListSorter.column !== "" &&
          columnCategoryListSorter.state !== "" &&
          columnCategoryListSorter.state != 0 ?
          `${columnCategoryListSorter.column}%${columnCategoryListSorter.state}` : "id%desc",
    };
    queries = formUrlQuery(queries);
    fetchAllCategories(queries);
  }, [
    searchCategoryListFilter,
    categoryListPerPage,
    activeCategoryListPage,
    columnCategoryListSorter,
  ]);

  const fetchAllCategories = (queries) => {
    Axios({
      method: "get",
      url: `/api/booking/v1/categories/all?${queries}`,
    })
      .then(({ data }) => {
        const tempCategoryList = data.map((d) => ({
          ...d,
          services_count: d?.services?.length > 0 ? `${d?.services?.length} Items` : 'Not Available'
        }));

        setCategoryList(tempCategoryList);
        if (activeCategoryListPage === 1) {
          setCategoryListCount(tempCategoryList?.length);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDeleteConfirmation = (item) => {
    Axios({
      method: "delete",
      url: `/api/booking/v1/categories/${item.id}`,
    })
      .then(({ data }) => {
        setShowConfirmDelete({ show: false, item: {} })
        window.location.reload()
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleMultipleDelete = async (items) => {
    try {
      for (let k = 0; k < items.length; k++) {
        await Axios.delete(`/api/booking/v1/categories/${items[k].id}`)
      }
      setShowConfirmDelete({ show: false, item: {} })
      setSelectedItem([])
      window.location.reload()
    } catch (error) {
      console.error(error)
    }
  }

  // column filter for created on
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  return (
    <>
      {showConfirmDelete.show && (
        <AppModalConfirmation
          confirmationType='delete-item'
          itemName='category'
          itemDetails={showConfirmDelete.item}
          showModal={showConfirmDelete}
          setShowModal={setShowConfirmDelete}
          handleConfirmation={
            showConfirmDelete.item &&
              Array.isArray(showConfirmDelete.item) &&
              showConfirmDelete.item.length > 0 ? handleMultipleDelete
              : handleDeleteConfirmation
          }
        />
      )}
      <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
        <CButton href='/category/add' style={styleButtonVars2}>
          <CImage fluid src={AddMoreIconWhite} className='me-2' />
          Create New Category
        </CButton>
      </div>
      <AppCard
        className='mt-4 mb-4'
        headerTitle={
          <AppCardHeader
            headerTitle={<h4>Category List</h4>}
            headerButton="Delete All"
            handleCardHeaderButton={() => setShowConfirmDelete({ show: true, item: selectedItem })}
            hidden={selectedItem && selectedItem.length === 0}
          />
        }
        bodyContent={
          <AppTable
            categoryListPage
            selectable
            tableFilter
            columnFilter
            setShowConfirmDelete={setShowConfirmDelete}
            tableColumns={
              columnCategoryList(
                startDate,
                endDate,
                setStartDate,
                setEndDate
              )
            }
            tableContent={categoryList}
            contentPerPage={categoryListPerPage}
            activeContentPage={activeCategoryListPage}
            contentCount={categoryListCount}
            searchContentFilter={searchCategoryListFilter}
            setActiveContentPage={setActiveCategoryListPage}
            setSearchContentFilter={setSearchCategoryListFilter}
            setContentPerPage={setCategoryListPerPage}
            setColumnContentSorter={setColumnCategoryListSorter}
            startDateColumnFilter={startDate}
            endDateColumnFilter={endDate}
            handleSelectedItemChange={setSelectedItem}
          />
        }
      />
    </>
  )
}

export default CategoryList
