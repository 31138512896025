
import React from 'react'
import moment from 'moment'
import { useSelector } from "react-redux"

// components
import {
  CRow,
  CBadge,
  CCol,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem
} from '@coreui/react-pro'
import AppTextColumnDetails from '../general-details/AppTextColumnDetails'

// helpers
import { bookingStatus, paymentStatus } from 'src/helpers/status-badge'
import { getFullName } from 'src/helpers/text-format'
import formatCurrency from 'src/helpers/format-currency'

const AppFirstDetails = ({
  bookingDetails,
  setShowConfirmOrderStatus
}) => {
  const currency = useSelector((state) => state.currency)

  return (
    <>
      <CRow>
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Booking No."
          subtitle={bookingDetails?.booking_uuid}
        />
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Purchased On"
          subtitle={moment(bookingDetails?.createdAt).format('l')}
        />
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Booking Status"
          subtitle={
            <CBadge className='table-badge-status-content' color={bookingStatus(bookingDetails?.status)} shape="rounded-pill">
              {bookingDetails?.status?.toString().toUpperCase()}
            </CBadge>
          }
        />
      </CRow>
      <CRow>
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Customer Name"
          subtitle={bookingDetails?.guests && bookingDetails?.guests?.length > 0 ? `${bookingDetails?.guests[0].first_name} ${bookingDetails?.guests[0].last_name}` : '-'}
        />
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Method of Payment"
          subtitle={bookingDetails?.transaction_details && bookingDetails?.transaction_details?.length > 0 ? bookingDetails?.transaction_details[0]?.payment_channel?.name : '-'}
        />
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Total Amount"
          subtitle={bookingDetails?.transaction_details && bookingDetails?.transaction_details?.length > 0 && bookingDetails?.transaction_details[0].transaction_amount && bookingDetails?.transaction_details[0].transaction_amount !== '' ? `${bookingDetails?.transaction_details && bookingDetails?.transaction_details?.length > 0 && formatCurrency(bookingDetails?.transaction_details[0].transaction_amount)}` : '-'}
        />
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Payment Status"
          subtitle={
            <CBadge className='table-badge-status-content' color={paymentStatus(bookingDetails?.transaction_details && bookingDetails?.transaction_details?.length > 0 && bookingDetails?.transaction_details[0]?.payment_status)} shape="rounded-pill">
              {bookingDetails?.transaction_details && bookingDetails?.transaction_details?.length > 0 && bookingDetails?.transaction_details[0]?.payment_status && bookingDetails?.transaction_details[0]?.payment_status?.toString().toUpperCase()}
            </CBadge>
          }
        />
      </CRow>
    </>
  )
}

export default AppFirstDetails
